/* tslint:disable */
/* eslint-disable */
/**
 * Front-end API
 * powered by Flasgger
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * The action taken
 * @export
 * @enum {string}
 */

export const ActionChoice = {
    Dialysis: 'dialysis',
    LabDraw: 'lab_draw',
    EsaDose: 'esa_dose',
    IvIronDose: 'iv_iron_dose',
    VascularAccessIntervention: 'vascular_access_intervention',
    Transfusion: 'transfusion',
    EmergencyRoom: 'emergency_room',
    HospitalizationAdmission: 'hospitalization_admission',
    HospitalizationDischarge: 'hospitalization_discharge',
    Other: 'other'
} as const;

export type ActionChoice = typeof ActionChoice[keyof typeof ActionChoice];


/**
 * 
 * @export
 * @interface AudioData
 */
export interface AudioData {
    /**
     * 
     * @type {Array<AudioReading>}
     * @memberof AudioData
     */
    'data'?: Array<AudioReading>;
    /**
     * 
     * @type {string}
     * @memberof AudioData
     */
    'domainunits'?: string;
}
/**
 * 
 * @export
 * @interface AudioReading
 */
export interface AudioReading {
    /**
     * Time of reading
     * @type {number}
     * @memberof AudioReading
     */
    'x'?: number;
    /**
     * Value at time x
     * @type {number}
     * @memberof AudioReading
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface BulkUploadError
 */
export interface BulkUploadError {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUploadError
     */
    'errors': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BulkUploadError
     */
    'line': number;
    /**
     * 
     * @type {object}
     * @memberof BulkUploadError
     */
    'row': object;
}
/**
 * 
 * @export
 * @interface Clinic
 */
export interface Clinic {
    /**
     * Whether or not the clinic is active; has no effect currently. TODO?
     * @type {boolean}
     * @memberof Clinic
     */
    'active'?: boolean;
    /**
     * Active patients for the clinic; not always present. Read only.
     * @type {number}
     * @memberof Clinic
     */
    'activepatients'?: number;
    /**
     * joesmith@joe.com
     * @type {string}
     * @memberof Clinic
     */
    'billingemail'?: string;
    /**
     * Billing contact first name
     * @type {string}
     * @memberof Clinic
     */
    'billingfirstname'?: string;
    /**
     * Billing contact last name
     * @type {string}
     * @memberof Clinic
     */
    'billinglastname'?: string;
    /**
     * Billing contact\'s phone number
     * @type {string}
     * @memberof Clinic
     */
    'billingphone'?: string;
    /**
     * A city, or whatever goes in the corresponding location in an address.
     * @type {string}
     * @memberof Clinic
     */
    'city'?: string;
    /**
     * Auscultation feature is active for this clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'featureauscultation'?: boolean;
    /**
     * Hct feature is active for this clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'featurehct'?: boolean;
    /**
     * Heart rate feature is active for this clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'featureheartrate'?: boolean;
    /**
     * Hgb feature is active for this clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'featurehgb'?: boolean;
    /**
     * Heart rate variability feature is active for this clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'featurehrv'?: boolean;
    /**
     * Logistics feature is active for this clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'featurelogistics'?: boolean;
    /**
     * SpO2 feature is active for this clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'featurespo2'?: boolean;
    /**
     * Potassium feature is active for this clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'featurepotassium'?: boolean;
    /**
     * Relative blood volume feature is active for this clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'feature_rbv'?: boolean;
    /**
     * Potassium treated as high/low vs dyskalemia.
     * @type {boolean}
     * @memberof Clinic
     */
    'ordinal_potassium'?: boolean;
    /**
     * Unique identifier
     * @type {number}
     * @memberof Clinic
     */
    'id': number;
    /**
     * Human readable name
     * @type {string}
     * @memberof Clinic
     */
    'name': string;
    /**
     * Should we notify when potassium is out of range.
     * @type {boolean}
     * @memberof Clinic
     */
    'notify_dyskalemia'?: boolean;
    /**
     * Number of consecutive abnormal readings before notification is triggered.
     * @type {number}
     * @memberof Clinic
     */
    'number_dyskalemia'?: number;
    /**
     * Should we notify when potassium is below range.
     * @type {boolean}
     * @memberof Clinic
     */
    'notify_low_potassium'?: boolean;
    /**
     * Number of consecutive low readings before notification is triggered.
     * @type {number}
     * @memberof Clinic
     */
    'number_low_potassium'?: number;
    /**
     * Should we notify when potassium is above range.
     * @type {boolean}
     * @memberof Clinic
     */
    'notify_high_potassium'?: boolean;
    /**
     * Number of consecutive low readings before notification is triggered.
     * @type {number}
     * @memberof Clinic
     */
    'number_high_potassium'?: number;
    /**
     * Number of consecutive low readings before notification is triggered.
     * @type {number}
     * @memberof Clinic
     */
    'number_low_hct'?: number;
    /**
     * Number of consecutive low readings before notification is triggered.
     * @type {number}
     * @memberof Clinic
     */
    'number_high_hct'?: number;
    /**
     * Number of consecutive low readings before notification is triggered.
     * @type {number}
     * @memberof Clinic
     */
    'number_low_hgb'?: number;
    /**
     * Number of consecutive low readings before notification is triggered.
     * @type {number}
     * @memberof Clinic
     */
    'number_high_hgb'?: number;
    /**
     * Should we notify when hct is beyond ranges.
     * @type {boolean}
     * @memberof Clinic
     */
    'notify_hct'?: boolean;
    /**
     * Threshold to be considered low hct
     * @type {number}
     * @memberof Clinic
     */
    'threshold_low_hct'?: number;
    /**
     * Threshold to be considered high hct
     * @type {number}
     * @memberof Clinic
     */
    'threshold_high_hct'?: number;
    /**
     * Should we notify when hgb is beyond ranges.
     * @type {boolean}
     * @memberof Clinic
     */
    'notify_hgb'?: boolean;
    /**
     * Threshold to be considered high hct
     * @type {number}
     * @memberof Clinic
     */
    'threshold_low_hgb'?: number;
    /**
     * Threshold to be considered high hgb
     * @type {number}
     * @memberof Clinic
     */
    'threshold_high_hgb'?: number;
    /**
     * id of the country for the clinic
     * @type {number}
     * @memberof Clinic
     */
    'country_id'?: number;
    /**
     * Locations patches may be placed
     * @type {Array<PatchLocation>}
     * @memberof Clinic
     */
    'patchlocations'?: Array<PatchLocation>;
    /**
     * URL for the corresponding clinic portal, won\'t be present on all responses.
     * @type {string}
     * @memberof Clinic
     */
    'portalurl'?: string;
    /**
     * A zip or postal code.
     * @type {string}
     * @memberof Clinic
     */
    'postalcode'?: string;
    /**
     * A state, province, etc.
     * @type {string}
     * @memberof Clinic
     */
    'state'?: string;
    /**
     * A street address or nearest equivalent. Accepts multiple lines.
     * @type {string}
     * @memberof Clinic
     */
    'streetaddress'?: string;
    /**
     * Technical contact email address
     * @type {string}
     * @memberof Clinic
     */
    'technicalemail'?: string;
    /**
     * Technical contact first name
     * @type {string}
     * @memberof Clinic
     */
    'technicalfirstname'?: string;
    /**
     * Technical contact last name
     * @type {string}
     * @memberof Clinic
     */
    'technicallastname'?: string;
    /**
     * Technical contact phone number
     * @type {string}
     * @memberof Clinic
     */
    'technicalphone'?: string;
    /**
     * fahrenheit or celsius
     * @type {string}
     * @memberof Clinic
     */
    'tempdisplayunits'?: string;
    /**
     * Total patients for the clinic; not always present. Read only.
     * @type {number}
     * @memberof Clinic
     */
    'totalpatients'?: number;
    /**
     * A validation code; currently not very secure. TODO?
     * @type {string}
     * @memberof Clinic
     */
    'validationcode'?: string;
    /**
     * Indicates what kind of clinic this is. clinic or sports. Empty/null == clinic
     * @type {string}
     * @memberof Clinic
     */
    'variety'?: string;
    /**
     * Indicates if the clinic is a test clinic. If true, the clinic is a test clinic.
     * @type {boolean}
     * @memberof Clinic
     */
    'is_test'?: boolean;
}
/**
 * 
 * @export
 * @interface ClinicUser
 */
export interface ClinicUser {
    /**
     * 
     * @type {Array<number>}
     * @memberof ClinicUser
     */
    'adminofclinics'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClinicUser
     */
    'clinics': Array<number>;
    /**
     * If available, the raw Cognito JSON object for the user.
     * @type {object}
     * @memberof ClinicUser
     */
    'cognitodetails'?: object;
    /**
     * 
     * @type {string}
     * @memberof ClinicUser
     */
    'cognitoemail': string;
    /**
     * True indicates the user\'s Cognito account status is \"good\", False indicates there is a problem.
     * @type {boolean}
     * @memberof ClinicUser
     */
    'cognitogoodstatus'?: boolean;
    /**
     * Probably not for front-end use. Cognito\'s unique ID for the user.
     * @type {string}
     * @memberof ClinicUser
     */
    'cognitousername'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicUser
     */
    'firstname': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicUser
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ClinicUser
     */
    'id': number;
    /**
     * Time of last login. Not necessarily accurate. Relies on the front end calling the login endpoint.
     * @type {number}
     * @memberof ClinicUser
     */
    'lastlogin'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicUser
     */
    'create_ts'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicUser
     */
    'update_ts'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicUser
     */
    'lastname': string;
    /**
     * Email address to send notifications to. May be empty/null.
     * @type {string}
     * @memberof ClinicUser
     */
    'notificationemail'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicUser
     */
    'privacypolicyacceptance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicUser
     */
    'privacypolicyacceptedversion'?: string;
    /**
     * Whether or not notification emails should be sent.
     * @type {boolean}
     * @memberof ClinicUser
     */
    'sendemail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClinicUser
     */
    'suffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicUser
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ClinicalAction
 */
export interface ClinicalAction {
    /**
     * 
     * @type {string}
     * @memberof ClinicalAction
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicalAction
     */
    'lastname'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicalAction
     */
    'created_on_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicalAction
     */
    'action_taken_ts'?: number;
    /**
     * 
     * @type {ActionChoice}
     * @memberof ClinicalAction
     */
    'action_choice'?: ActionChoice;
    /**
     * 
     * @type {string}
     * @memberof ClinicalAction
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicalAction
     */
    'patient_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicalAction
     */
    'clinic_id'?: number;
}


/**
 * 
 * @export
 * @interface ClinicianGroup
 */
export interface ClinicianGroup {
    /**
     * 
     * @type {number}
     * @memberof ClinicianGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicianGroup
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicianGroup
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ClinicianGroupCliniciansInner>}
     * @memberof ClinicianGroup
     */
    'clinicians'?: Array<ClinicianGroupCliniciansInner>;
}
/**
 * 
 * @export
 * @interface ClinicianGroupCliniciansInner
 */
export interface ClinicianGroupCliniciansInner {
    /**
     * 
     * @type {number}
     * @memberof ClinicianGroupCliniciansInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicianGroupCliniciansInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DeviceSearch
 */
export interface DeviceSearch {
    /**
     * Descending sort; defaults to true
     * @type {boolean}
     * @memberof DeviceSearch
     */
    'descending'?: boolean;
    /**
     * Type of device to search for. kits, hubs, patches, patchboxes
     * @type {string}
     * @memberof DeviceSearch
     */
    'devicetype': string;
    /**
     * set to \"generic\" for generic; any other value searches the current environment
     * @type {string}
     * @memberof DeviceSearch
     */
    'environment'?: string;
    /**
     * Query offset
     * @type {number}
     * @memberof DeviceSearch
     */
    'page'?: number;
    /**
     * Page size default 100
     * @type {number}
     * @memberof DeviceSearch
     */
    'pageSize'?: number;
    /**
     * Query object
     * @type {object}
     * @memberof DeviceSearch
     */
    'q'?: object;
    /**
     * Column to sort by, defaults to vpn_hostname
     * @type {string}
     * @memberof DeviceSearch
     */
    'sort'?: string;
}
/**
 * 
 * @export
 * @interface DialysisEvent
 */
export interface DialysisEvent {
    /**
     * 
     * @type {number}
     * @memberof DialysisEvent
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DialysisEvent
     */
    'ts'?: number;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * Unique identifier for the event
     * @type {number}
     * @memberof Event
     */
    'id'?: number;
    /**
     * Unique identifier for the patient
     * @type {number}
     * @memberof Event
     */
    'patient_id'?: number;
    /**
     * Name of the patient
     * @type {string}
     * @memberof Event
     */
    'patient_name'?: string;
    /**
     * Unique identifier for the ordering physician
     * @type {number}
     * @memberof Event
     */
    'ordering_physician_id'?: number;
    /**
     * Name of the ordering physician
     * @type {string}
     * @memberof Event
     */
    'ordering_physician_name'?: string;
    /**
     * Timestamp of the event
     * @type {number}
     * @memberof Event
     */
    'event_ts'?: number;
    /**
     * 
     * @type {TimelineCategory}
     * @memberof Event
     */
    'category'?: TimelineCategory;
    /**
     * 
     * @type {EventType}
     * @memberof Event
     */
    'type'?: EventType;
    /**
     * Additional notes for the event
     * @type {string}
     * @memberof Event
     */
    'notes'?: string | null;
    /**
     * Unique identifier for the creator of the event
     * @type {number}
     * @memberof Event
     */
    'created_by_id'?: number;
    /**
     * Name of the creator of the event
     * @type {string}
     * @memberof Event
     */
    'created_by_name'?: string;
}


/**
 * @type EventType
 * @export
 */
export type EventType = EventType | NotificationType;

/**
 * 
 * @export
 * @interface HCTData
 */
export interface HCTData {
    /**
     * 
     * @type {Array<HCTReading>}
     * @memberof HCTData
     */
    'data'?: Array<HCTReading>;
    /**
     * 
     * @type {string}
     * @memberof HCTData
     */
    'domainunits'?: string;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'goodrangeceiling'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'goodrangefloor'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'highthreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'maxrange'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'minrange'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof HCTData
     */
    'notificationlines'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof HCTData
     */
    'rangedisplayunits'?: string;
    /**
     * 
     * @type {string}
     * @memberof HCTData
     */
    'rangeunits'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof HCTData
     */
    'streaming_data'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'ticksrange'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'low_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'high_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'total_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'low_reads_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'high_reads_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'low_notifiable_events'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'high_notifiable_events'?: number;
    /**
     * 
     * @type {number}
     * @memberof HCTData
     */
    'total_notifiable_events'?: number;
}
/**
 * 
 * @export
 * @interface HCTReading
 */
export interface HCTReading {
    /**
     * Time of reading
     * @type {number}
     * @memberof HCTReading
     */
    'x'?: number;
    /**
     * Value at time x
     * @type {number}
     * @memberof HCTReading
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface HGBData
 */
export interface HGBData {
    /**
     * 
     * @type {Array<HRDataDataInner>}
     * @memberof HGBData
     */
    'data'?: Array<HRDataDataInner>;
    /**
     * 
     * @type {string}
     * @memberof HGBData
     */
    'domainunits'?: string;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'goodrangeceiling'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'goodrangefloor'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'highthreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'maxrange'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'minrange'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof HGBData
     */
    'notificationlines'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof HGBData
     */
    'rangedisplayunits'?: string;
    /**
     * 
     * @type {string}
     * @memberof HGBData
     */
    'rangeunits'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof HGBData
     */
    'streaming_data'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'ticksrange'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'low_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'high_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'total_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'low_reads_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'high_reads_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'low_notifiable_events'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'high_notifiable_events'?: number;
    /**
     * 
     * @type {number}
     * @memberof HGBData
     */
    'total_notifiable_events'?: number;
}
/**
 * 
 * @export
 * @interface HRData
 */
export interface HRData {
    /**
     * 
     * @type {Array<HRDataDataInner>}
     * @memberof HRData
     */
    'data'?: Array<HRDataDataInner>;
    /**
     * 
     * @type {string}
     * @memberof HRData
     */
    'domainunits'?: string;
    /**
     * 
     * @type {number}
     * @memberof HRData
     */
    'highthreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof HRData
     */
    'maxrange'?: number;
    /**
     * 
     * @type {number}
     * @memberof HRData
     */
    'minrange'?: number;
    /**
     * 
     * @type {string}
     * @memberof HRData
     */
    'rangedisplayunits'?: string;
    /**
     * 
     * @type {string}
     * @memberof HRData
     */
    'rangeunits'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof HRData
     */
    'streaming_data'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof HRData
     */
    'ticksrange'?: number;
}
/**
 * 
 * @export
 * @interface HRDataDataInner
 */
export interface HRDataDataInner {
    /**
     * Time of reading
     * @type {number}
     * @memberof HRDataDataInner
     */
    'x'?: number;
    /**
     * Value at time x
     * @type {number}
     * @memberof HRDataDataInner
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface Hub
 */
export interface Hub {
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'hub_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'barcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'patient_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'vpn_hostname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'vpn_ip'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'hub_firmware'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'hub_software'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'created_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'data_plan'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'device_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'hub_hardware'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'imei'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'modem_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'sim_card_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Hub
     */
    'light_on'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'light_schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'assignment_date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Hub
     */
    'clinical_autoassign_patch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Hub
     */
    'blink_on'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'environment'?: string;
    /**
     * 
     * @type {string}
     * @memberof Hub
     */
    'last_connect'?: string;
}
/**
 * 
 * @export
 * @interface Kit
 */
export interface Kit {
    /**
     * 
     * @type {string}
     * @memberof Kit
     */
    'kit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Kit
     */
    'barcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Kit
     */
    'hub_device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Kit
     */
    'created_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Kit
     */
    'patch_box_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Kit
     */
    'subject_id'?: string;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {Array<string>}
     * @memberof Notification
     */
    'acceptableroutes'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'clinicianonly'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'msgkind'?: string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'patient_id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationType = {
    Highhct: 'highhct',
    Lowhct: 'lowhct',
    Highhgb: 'highhgb',
    Lowhgb: 'lowhgb',
    Highpotassium: 'highpotassium',
    Lowpotassium: 'lowpotassium',
    Dyskalemia: 'dyskalemia'
} as const;

export type NotificationType = typeof NotificationType[keyof typeof NotificationType];


/**
 * 
 * @export
 * @interface NotifyClinician
 */
export interface NotifyClinician {
    /**
     * 
     * @type {number}
     * @memberof NotifyClinician
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotifyClinician
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface NotifyGroup
 */
export interface NotifyGroup {
    /**
     * 
     * @type {number}
     * @memberof NotifyGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotifyGroup
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'city'?: string;
    /**
     * Clinic ID number. Not required.
     * @type {number}
     * @memberof Order
     */
    'clinic'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'country'?: string;
    /**
     * Epoch time of creation. Ignored on creation and automatically filled out.
     * @type {number}
     * @memberof Order
     */
    'entered'?: number;
    /**
     * Clinic user who entered order. Ignored on creation and pulled from auth info.
     * @type {number}
     * @memberof Order
     */
    'enteredby'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'firstname'?: string;
    /**
     * Number of hubs to include
     * @type {number}
     * @memberof Order
     */
    'hubs'?: number;
    /**
     * UUID, server-side assigned
     * @type {string}
     * @memberof Order
     */
    'id'?: string;
    /**
     * Number of kits to include
     * @type {number}
     * @memberof Order
     */
    'kits'?: number;
    /**
     * Epoch time of the last time this record was pulled into main logistics database.
     * @type {number}
     * @memberof Order
     */
    'lastfetched'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'notes'?: string;
    /**
     * Number of patch boxes to include
     * @type {number}
     * @memberof Order
     */
    'patchboxes'?: number;
    /**
     * Patient ID number. Not required.
     * @type {number}
     * @memberof Order
     */
    'patient'?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'postalcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'state'?: string;
    /**
     * 
     * @type {Array<OrderStatusInner>}
     * @memberof Order
     */
    'status'?: Array<OrderStatusInner>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'telephone'?: string;
    /**
     * Shipper tracking information
     * @type {string}
     * @memberof Order
     */
    'trackingnumber'?: string;
    /**
     * Epoch time of last update. Automatically updated.
     * @type {number}
     * @memberof Order
     */
    'updated'?: number;
}
/**
 * 
 * @export
 * @interface OrderStatusInner
 */
export interface OrderStatusInner {
    /**
     * Additional status information
     * @type {object}
     * @memberof OrderStatusInner
     */
    'data'?: object;
    /**
     * Epoch time
     * @type {number}
     * @memberof OrderStatusInner
     */
    'stamp'?: number;
    /**
     * Simple status string
     * @type {string}
     * @memberof OrderStatusInner
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface Patch
 */
export interface Patch {
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'patch_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'barcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'patch_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'firmware_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'hardware_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'created_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof Patch
     */
    'status_id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Patch
     */
    'clinical_autoassign_patch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'configuration'?: PatchConfigurationEnum;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'date_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patch
     */
    'date_end'?: string;
}

export const PatchConfigurationEnum = {
    NormalMode: 'Normal Mode'
} as const;

export type PatchConfigurationEnum = typeof PatchConfigurationEnum[keyof typeof PatchConfigurationEnum];

/**
 * 
 * @export
 * @interface PatchCommand
 */
export interface PatchCommand {
    /**
     * 
     * @type {boolean}
     * @memberof PatchCommand
     */
    'ack'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchCommand
     */
    'ack_timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCommand
     */
    'arguments'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCommand
     */
    'command'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCommand
     */
    'command_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCommand
     */
    'created_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCommand
     */
    'environment'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCommand
     */
    'patch_device_id'?: string;
}
/**
 * 
 * @export
 * @interface PatchConfiguration
 */
export interface PatchConfiguration {
    /**
     * 
     * @type {boolean}
     * @memberof PatchConfiguration
     */
    'current'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchConfiguration
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PatchDetail
 */
export interface PatchDetail {
    /**
     * 
     * @type {Array<PatchCommand>}
     * @memberof PatchDetail
     */
    'commands'?: Array<PatchCommand>;
    /**
     * 
     * @type {Array<PatchConfiguration>}
     * @memberof PatchDetail
     */
    'configurations'?: Array<PatchConfiguration>;
    /**
     * 
     * @type {string}
     * @memberof PatchDetail
     */
    'created_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDetail
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDetail
     */
    'patch_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchDetail
     */
    'patch_type'?: string;
}
/**
 * 
 * @export
 * @interface PatchLocation
 */
export interface PatchLocation {
    /**
     * ID number of this placement location, for editing
     * @type {number}
     * @memberof PatchLocation
     */
    'id'?: number;
    /**
     * Whether or not this location is active
     * @type {boolean}
     * @memberof PatchLocation
     */
    'active'?: boolean;
    /**
     * Human readable name for the location
     * @type {string}
     * @memberof PatchLocation
     */
    'locationname'?: string;
    /**
     * Enumerated type for the patch placement location type.
     * @type {string}
     * @memberof PatchLocation
     */
    'location_type'?: PatchLocationLocationTypeEnum;
    /**
     * Notes about the location
     * @type {string}
     * @memberof PatchLocation
     */
    'notes'?: string;
}

export const PatchLocationLocationTypeEnum = {
    Access: 'access',
    Calf: 'calf'
} as const;

export type PatchLocationLocationTypeEnum = typeof PatchLocationLocationTypeEnum[keyof typeof PatchLocationLocationTypeEnum];

/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * UTC Epoch time at which this patient was added.
     * @type {number}
     * @memberof Patient
     */
    'added'?: number;
    /**
     * Unique identifier for the clinic user who added this patient
     * @type {number}
     * @memberof Patient
     */
    'addedby'?: number;
    /**
     * Array of zero or more patient addresses
     * @type {Array<object>}
     * @memberof Patient
     */
    'addresses'?: Array<object>;
    /**
     * A unique clinic identifier for the clinic to which this patient is attached
     * @type {number}
     * @memberof Patient
     */
    'clinic': number;
    /**
     * 
     * @type {ClinicUser}
     * @memberof Patient
     */
    'ordering_clinician'?: ClinicUser;
    /**
     * Array of zero or more group notification recipients
     * @type {Array<NotifyGroup>}
     * @memberof Patient
     */
    'notify_groups'?: Array<NotifyGroup>;
    /**
     * Array of zero or more patient clinician notification recipients
     * @type {Array<NotifyClinician>}
     * @memberof Patient
     */
    'notify_clinicians'?: Array<NotifyClinician>;
    /**
     * Clinic\'s internal identifier for the patient. Could be anything.
     * @type {string}
     * @memberof Patient
     */
    'clinicpatientidentifier'?: string;
    /**
     * Date of birth
     * @type {string}
     * @memberof Patient
     */
    'dob': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'homephone'?: string;
    /**
     * Array of zero or more hub objects.
     * @type {Array<Hub>}
     * @memberof Patient
     */
    'hubs'?: Array<Hub>;
    /**
     * Unique identifier for patients
     * @type {number}
     * @memberof Patient
     */
    'id': number;
    /**
     * Array of zero or more kit objects, with unspecified contents.
     * @type {Array<Kit>}
     * @memberof Patient
     */
    'kits'?: Array<Kit>;
    /**
     * Latest time stamp of data
     * @type {number}
     * @memberof Patient
     */
    'lastdataseen'?: number;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'lastname'?: string;
    /**
     * 
     * @type {ClinicUser}
     * @memberof Patient
     */
    'lastviewer'?: ClinicUser;
    /**
     * Time at which the patient\'s data was last viewed.
     * @type {number}
     * @memberof Patient
     */
    'lastviewtime'?: number;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'middlename'?: string;
    /**
     * UTC Epoch time when monitoring ended. When null, monitoring hasn\'t completed.
     * @type {number}
     * @memberof Patient
     */
    'monitoring_end'?: number;
    /**
     * Reason why a patient is ending their use of our monitoring services.
     * @type {string}
     * @memberof Patient
     */
    'monitoring_end_reason'?: string;
    /**
     * UTC Epoch time when monitoring started. When null, monitoring hasn\'t started.
     * @type {number}
     * @memberof Patient
     */
    'monitoring_start'?: number;
    /**
     * starting_soon, active, or completed. Based on monitoring_start and monitoring_end
     * @type {string}
     * @memberof Patient
     */
    'monitoring_status'?: string;
    /**
     * Array of zero or more patch objects, with unspecified contents.
     * @type {Array<Patch>}
     * @memberof Patient
     */
    'patches'?: Array<Patch>;
    /**
     * Numeric reference to the patch placement locations listed in the patient\'s clinic.
     * @type {number}
     * @memberof Patient
     */
    'patchplacement'?: number;
    /**
     * Send an email to the user on alerts
     * @type {boolean}
     * @memberof Patient
     */
    'sendemail'?: boolean;
    /**
     * Send an SMS to the user on alerts
     * @type {boolean}
     * @memberof Patient
     */
    'sendsms'?: boolean;
    /**
     * True if the patient is in an acute state, false if not. Null if unknown.
     * @type {boolean}
     * @memberof Patient
     */
    'acute'?: boolean;
    /**
     * The mode we are in that is acute
     * @type {string}
     * @memberof Patient
     */
    'acutemode'?: string;
    /**
     * 
     * @type {PatientStatus}
     * @memberof Patient
     */
    'status'?: PatientStatus;
    /**
     * Text describing the patient status succinctly for machines. \"ok\", \"finished\", etc. May be a comma separated list some day?
     * @type {string}
     * @memberof Patient
     */
    'statusdesc'?: string;
    /**
     * Sortable integer representing the patient\'s status. Negative numbers should normally be at the top of the display.
     * @type {number}
     * @memberof Patient
     */
    'statusrank'?: number;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'workphone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'intl_timezone'?: string;
    /**
     * Should we notify when potassium is high.
     * @type {boolean}
     * @memberof Patient
     */
    'notify_high_potassium'?: boolean;
    /**
     * Should we notify when potassium is low.
     * @type {boolean}
     * @memberof Patient
     */
    'notify_low_potassium'?: boolean;
    /**
     * Should we notify for hct.
     * @type {boolean}
     * @memberof Patient
     */
    'notify_hct'?: boolean;
    /**
     * Should we notify for hgb.
     * @type {boolean}
     * @memberof Patient
     */
    'notify_hgb'?: boolean;
    /**
     * Should we notify for dyskalemia.
     * @type {boolean}
     * @memberof Patient
     */
    'notify_dyskalemia'?: boolean;
    /**
     * Number of consecutive low potassium readings before notification is triggered.
     * @type {number}
     * @memberof Patient
     */
    'number_low_potassium'?: number;
    /**
     * Number of consecutive high potassium readings before notification is triggered.
     * @type {number}
     * @memberof Patient
     */
    'number_high_potassium'?: number;
    /**
     * Number of consecutive dyskalemia readings before notification is triggered.
     * @type {number}
     * @memberof Patient
     */
    'number_dyskalemia'?: number;
    /**
     * Number of consecutive low hct readings before notification is triggered.
     * @type {number}
     * @memberof Patient
     */
    'number_low_hct'?: number;
    /**
     * Number of consecutive high hct readings before notification is triggered.
     * @type {number}
     * @memberof Patient
     */
    'number_high_hct'?: number;
    /**
     * Number of consecutive low hgb readings before notification is triggered.
     * @type {number}
     * @memberof Patient
     */
    'number_low_hgb'?: number;
    /**
     * Number of consecutive high hgb readings before notification is triggered.
     * @type {number}
     * @memberof Patient
     */
    'number_high_hgb'?: number;
    /**
     * Threshold to be considered low hct.
     * @type {number}
     * @memberof Patient
     */
    'threshold_low_hct'?: number;
    /**
     * Threshold to be considered high hct.
     * @type {number}
     * @memberof Patient
     */
    'threshold_high_hct'?: number;
    /**
     * Threshold to be considered low hgb.
     * @type {number}
     * @memberof Patient
     */
    'threshold_low_hgb'?: number;
    /**
     * Threshold to be considered high hgb.
     * @type {number}
     * @memberof Patient
     */
    'threshold_high_hgb'?: number;
}
/**
 * 
 * @export
 * @interface PatientSession
 */
export interface PatientSession {
    /**
     * 
     * @type {boolean}
     * @memberof PatientSession
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientSession
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PatientSession
     */
    'patient': number;
    /**
     * 
     * @type {number}
     * @memberof PatientSession
     */
    'start': number;
    /**
     * 
     * @type {number}
     * @memberof PatientSession
     */
    'stop': number;
    /**
     * 
     * @type {number}
     * @memberof PatientSession
     */
    'type': number;
}
/**
 * Will be filled out on patient list as well as it can be.
 * @export
 * @interface PatientStatus
 */
export interface PatientStatus {
    /**
     * 
     * @type {PatientStatusTemperature}
     * @memberof PatientStatus
     */
    'temperature'?: PatientStatusTemperature;
}
/**
 * 
 * @export
 * @interface PatientStatusTemperature
 */
export interface PatientStatusTemperature {
    /**
     * Units of the \"x\" value
     * @type {string}
     * @memberof PatientStatusTemperature
     */
    'domainunits'?: string;
    /**
     * y value corresponding to \"red\" alarm
     * @type {number}
     * @memberof PatientStatusTemperature
     */
    'highthreshold'?: number;
    /**
     * Units of the \"y\" value
     * @type {string}
     * @memberof PatientStatusTemperature
     */
    'rangeunits'?: string;
    /**
     * Time of temperature reading
     * @type {number}
     * @memberof PatientStatusTemperature
     */
    'x'?: number;
    /**
     * Temperature at time x given in units
     * @type {number}
     * @memberof PatientStatusTemperature
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface PotassiumData
 */
export interface PotassiumData {
    /**
     * 
     * @type {Array<PotassiumReading>}
     * @memberof PotassiumData
     */
    'data'?: Array<PotassiumReading>;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'goodrangeceiling'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'goodrangefloor'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'maxrange'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'minrange'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'total_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'total_notifiable_events'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PotassiumData
     */
    'ordinal_potassium'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'abnormal_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'abnormal_reads_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'low_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'high_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'low_reads_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'high_reads_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'low_notifiable_events'?: number;
    /**
     * 
     * @type {number}
     * @memberof PotassiumData
     */
    'high_notifiable_events'?: number;
}
/**
 * 
 * @export
 * @interface PotassiumReading
 */
export interface PotassiumReading {
    /**
     * Time of reading
     * @type {number}
     * @memberof PotassiumReading
     */
    'x'?: number;
    /**
     * Value at time x
     * @type {number}
     * @memberof PotassiumReading
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface SessionType
 */
export interface SessionType {
    /**
     * Whether or not the session type is available for use
     * @type {boolean}
     * @memberof SessionType
     */
    'active'?: boolean;
    /**
     * Associated clinic, not modifiable/settable
     * @type {number}
     * @memberof SessionType
     */
    'clinic'?: number;
    /**
     * An icon
     * @type {string}
     * @memberof SessionType
     */
    'icon'?: string;
    /**
     * Unique ID for this session type
     * @type {number}
     * @memberof SessionType
     */
    'id': number;
    /**
     * Name
     * @type {string}
     * @memberof SessionType
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TemperatureData
 */
export interface TemperatureData {
    /**
     * 
     * @type {Array<TemperatureReading>}
     * @memberof TemperatureData
     */
    'data_15min'?: Array<TemperatureReading>;
    /**
     * 
     * @type {Array<TemperatureReading>}
     * @memberof TemperatureData
     */
    'data_1hour'?: Array<TemperatureReading>;
    /**
     * 
     * @type {string}
     * @memberof TemperatureData
     */
    'domainunits'?: string;
    /**
     * 
     * @type {number}
     * @memberof TemperatureData
     */
    'highthreshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof TemperatureData
     */
    'maxrange'?: number;
    /**
     * 
     * @type {number}
     * @memberof TemperatureData
     */
    'minrange'?: number;
    /**
     * 
     * @type {string}
     * @memberof TemperatureData
     */
    'rangedisplayunits'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemperatureData
     */
    'rangeunits'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof TemperatureData
     */
    'streaming_data'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof TemperatureData
     */
    'ticksrange'?: number;
}
/**
 * 
 * @export
 * @interface TemperatureReading
 */
export interface TemperatureReading {
    /**
     * Time of temperature reading
     * @type {number}
     * @memberof TemperatureReading
     */
    'x'?: number;
    /**
     * Temperature at time x given in units
     * @type {number}
     * @memberof TemperatureReading
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TimelineCategory = {
    Event: 'Event',
    Notification: 'Notification',
    Schedule: 'Schedule'
} as const;

export type TimelineCategory = typeof TimelineCategory[keyof typeof TimelineCategory];


/**
 * 
 * @export
 * @interface V1ClinicClinicIdClinicianGroupsGet200Response
 */
export interface V1ClinicClinicIdClinicianGroupsGet200Response {
    /**
     * 
     * @type {Array<ClinicianGroup>}
     * @memberof V1ClinicClinicIdClinicianGroupsGet200Response
     */
    'clinician_groups': Array<ClinicianGroup>;
}
/**
 * 
 * @export
 * @interface V1ClinicClinicidGet200Response
 */
export interface V1ClinicClinicidGet200Response {
    /**
     * 
     * @type {Clinic}
     * @memberof V1ClinicClinicidGet200Response
     */
    'clinic': Clinic;
}
/**
 * 
 * @export
 * @interface V1ClinicClinicidPatientsGet200Response
 */
export interface V1ClinicClinicidPatientsGet200Response {
    /**
     * 
     * @type {ClinicUser}
     * @memberof V1ClinicClinicidPatientsGet200Response
     */
    'lastviewer'?: ClinicUser;
    /**
     * Time at which the clicnics\' patient status list was last viewed.
     * @type {number}
     * @memberof V1ClinicClinicidPatientsGet200Response
     */
    'lastviewtime'?: number;
    /**
     * The maximum patient ID for patients in the clinic requested.
     * @type {number}
     * @memberof V1ClinicClinicidPatientsGet200Response
     */
    'maxpatientid'?: number;
    /**
     * 
     * @type {Array<Patient>}
     * @memberof V1ClinicClinicidPatientsGet200Response
     */
    'patients': Array<Patient>;
}
/**
 * 
 * @export
 * @interface V1ClinicClinicidPutRequest
 */
export interface V1ClinicClinicidPutRequest {
    /**
     * 
     * @type {Clinic}
     * @memberof V1ClinicClinicidPutRequest
     */
    'clinic'?: Clinic;
}
/**
 * 
 * @export
 * @interface V1ClinicClinicidSessionPost200Response
 */
export interface V1ClinicClinicidSessionPost200Response {
    /**
     * created session type
     * @type {object}
     * @memberof V1ClinicClinicidSessionPost200Response
     */
    'sessiontype'?: object;
}
/**
 * 
 * @export
 * @interface V1ClinicClinicidSessionPostRequest
 */
export interface V1ClinicClinicidSessionPostRequest {
    /**
     * 
     * @type {SessionType}
     * @memberof V1ClinicClinicidSessionPostRequest
     */
    'sessiontype'?: SessionType;
}
/**
 * 
 * @export
 * @interface V1ClinicClinicidSessiontypesGet200Response
 */
export interface V1ClinicClinicidSessiontypesGet200Response {
    /**
     * 
     * @type {Array<SessionType>}
     * @memberof V1ClinicClinicidSessiontypesGet200Response
     */
    'sessiontypes': Array<SessionType>;
}
/**
 * 
 * @export
 * @interface V1ClinicClinicidUsersGet200Response
 */
export interface V1ClinicClinicidUsersGet200Response {
    /**
     * 
     * @type {Array<ClinicUser>}
     * @memberof V1ClinicClinicidUsersGet200Response
     */
    'clinicusers': Array<ClinicUser>;
}
/**
 * 
 * @export
 * @interface V1ClinicPostRequest
 */
export interface V1ClinicPostRequest {
    /**
     * 
     * @type {Clinic}
     * @memberof V1ClinicPostRequest
     */
    'clinic': Clinic;
    /**
     * Name of the site to use for this clinic; appears at end of login URL. >=1 clinic per site is possible. The sitename should be valid as a substring in an AWS Cognito pool name.
     * @type {string}
     * @memberof V1ClinicPostRequest
     */
    'sitename': string;
}
/**
 * 
 * @export
 * @interface V1ClinicadminPostRequest
 */
export interface V1ClinicadminPostRequest {
    /**
     * 
     * @type {ClinicUser}
     * @memberof V1ClinicadminPostRequest
     */
    'clinicuser'?: ClinicUser;
}
/**
 * 
 * @export
 * @interface V1ClinicianGroupGroupIdGet200Response
 */
export interface V1ClinicianGroupGroupIdGet200Response {
    /**
     * 
     * @type {ClinicianGroup}
     * @memberof V1ClinicianGroupGroupIdGet200Response
     */
    'clinician_group': ClinicianGroup;
}
/**
 * 
 * @export
 * @interface V1ClinicianGroupGroupIdPutRequest
 */
export interface V1ClinicianGroupGroupIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ClinicianGroupGroupIdPutRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1ClinicianGroupGroupIdPutRequest
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1ClinicianGroupGroupIdPutRequest
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1ClinicianGroupGroupIdPutRequest
     */
    'clinician_ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface V1ClinicianGroupPostRequest
 */
export interface V1ClinicianGroupPostRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ClinicianGroupPostRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1ClinicianGroupPostRequest
     */
    'clinic_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1ClinicianGroupPostRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof V1ClinicianGroupPostRequest
     */
    'clinician_ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface V1ClinicsGet200Response
 */
export interface V1ClinicsGet200Response {
    /**
     * 
     * @type {Array<Clinic>}
     * @memberof V1ClinicsGet200Response
     */
    'clinics': Array<Clinic>;
}
/**
 * 
 * @export
 * @interface V1ClinicuserClinicuseridGet200Response
 */
export interface V1ClinicuserClinicuseridGet200Response {
    /**
     * 
     * @type {ClinicUser}
     * @memberof V1ClinicuserClinicuseridGet200Response
     */
    'clinicuser': ClinicUser;
}
/**
 * 
 * @export
 * @interface V1ClinicuserForgotPasswordPostRequest
 */
export interface V1ClinicuserForgotPasswordPostRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ClinicuserForgotPasswordPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof V1ClinicuserForgotPasswordPostRequest
     */
    'client_id': string;
}
/**
 * 
 * @export
 * @interface V1ClinicuserSelfAcceptprivacypolicyPutRequest
 */
export interface V1ClinicuserSelfAcceptprivacypolicyPutRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ClinicuserSelfAcceptprivacypolicyPutRequest
     */
    'privacypolicyacceptedversion'?: string;
}
/**
 * 
 * @export
 * @interface V1DataPatientidDatatypeTimestampGet200Response
 */
export interface V1DataPatientidDatatypeTimestampGet200Response {
    /**
     * 
     * @type {Array<object>}
     * @memberof V1DataPatientidDatatypeTimestampGet200Response
     */
    'data'?: Array<object>;
}
/**
 * @type V1DataPatientidDatatypesGet200Response
 * @export
 */
export type V1DataPatientidDatatypesGet200Response = AudioData | HCTData | HGBData | HRData | PotassiumData | TemperatureData;

/**
 * 
 * @export
 * @interface V1DataSummaryPatientidHrPost200Response
 */
export interface V1DataSummaryPatientidHrPost200Response {
    /**
     * 
     * @type {Array<V1DataSummaryPatientidHrPost200ResponseResultsInner>}
     * @memberof V1DataSummaryPatientidHrPost200Response
     */
    'results'?: Array<V1DataSummaryPatientidHrPost200ResponseResultsInner>;
}
/**
 * 
 * @export
 * @interface V1DataSummaryPatientidHrPost200ResponseResultsInner
 */
export interface V1DataSummaryPatientidHrPost200ResponseResultsInner {
    /**
     * Number of values above the midhigh cutoff
     * @type {number}
     * @memberof V1DataSummaryPatientidHrPost200ResponseResultsInner
     */
    'highi'?: number;
    /**
     * Number of values below the lowmid cutoff
     * @type {number}
     * @memberof V1DataSummaryPatientidHrPost200ResponseResultsInner
     */
    'low'?: number;
    /**
     * Number of values between lowmid and midhigh cutoffs
     * @type {number}
     * @memberof V1DataSummaryPatientidHrPost200ResponseResultsInner
     */
    'mid'?: number;
    /**
     * Epoch start time for this range
     * @type {number}
     * @memberof V1DataSummaryPatientidHrPost200ResponseResultsInner
     */
    'time'?: number;
}
/**
 * 
 * @export
 * @interface V1DataSummaryPatientidHrPostRequest
 */
export interface V1DataSummaryPatientidHrPostRequest {
    /**
     * Heart rate which divides low and mid ranges
     * @type {number}
     * @memberof V1DataSummaryPatientidHrPostRequest
     */
    'lowmid'?: number;
    /**
     * Heart rate which divides mid and high ranges
     * @type {number}
     * @memberof V1DataSummaryPatientidHrPostRequest
     */
    'midhigh'?: number;
    /**
     * Array of epoch times which deliniate time ranges. Include your min and max desired times. There must be at least 2 values, ideally more. Must be sorted.
     * @type {Array<number>}
     * @memberof V1DataSummaryPatientidHrPostRequest
     */
    'timedivisions'?: Array<number>;
}
/**
 * 
 * @export
 * @interface V1DeviceSearchPost200Response
 */
export interface V1DeviceSearchPost200Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1DeviceSearchPost200Response
     */
    'devices'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof V1DeviceSearchPost200Response
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1DeviceSearchPost200Response
     */
    'pageSize'?: number;
}
/**
 * 
 * @export
 * @interface V1EventsDelete200Response
 */
export interface V1EventsDelete200Response {
    /**
     * 
     * @type {boolean}
     * @memberof V1EventsDelete200Response
     */
    'ok'?: boolean;
    /**
     * The ID of the deleted clinical action
     * @type {number}
     * @memberof V1EventsDelete200Response
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface V1EventsDelete500Response
 */
export interface V1EventsDelete500Response {
    /**
     * 
     * @type {boolean}
     * @memberof V1EventsDelete500Response
     */
    'ok'?: boolean;
}
/**
 * 
 * @export
 * @interface V1HubsHubIdGet200Response
 */
export interface V1HubsHubIdGet200Response {
    /**
     * date hub assigned
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'assignment_date'?: string;
    /**
     * hub barcode
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'barcode'?: string;
    /**
     * whether hub is set to blink or not
     * @type {boolean}
     * @memberof V1HubsHubIdGet200Response
     */
    'blink_on'?: boolean;
    /**
     * patches connected to this hub are auto assigned to the same patient
     * @type {boolean}
     * @memberof V1HubsHubIdGet200Response
     */
    'clinical_autoassign_patch'?: boolean;
    /**
     * contact for hub
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'contact'?: string;
    /**
     * date hub created
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'created_date'?: string;
    /**
     * data plan associated with hub
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'data_plan'?: string;
    /**
     * hub mac address
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'device_id'?: string;
    /**
     * type of device
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'device_type'?: string;
    /**
     * environment
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'environment'?: string;
    /**
     * 
     * @type {V1HubsHubIdGet200ResponseHologramData}
     * @memberof V1HubsHubIdGet200Response
     */
    'hologram_data'?: V1HubsHubIdGet200ResponseHologramData;
    /**
     * hub firmware version
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'hub_firmware'?: string;
    /**
     * hub hardware version
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'hub_hardware'?: string;
    /**
     * hub gsn
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'hub_id'?: string;
    /**
     * hub software version
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'hub_software'?: string;
    /**
     * imei number
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'imei'?: string;
    /**
     * whether hub should have light on
     * @type {boolean}
     * @memberof V1HubsHubIdGet200Response
     */
    'light_on'?: boolean;
    /**
     * schedule for light
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'light_schedule'?: string;
    /**
     * type of modem used
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'modem_type'?: string;
    /**
     * organization
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'organization'?: string;
    /**
     * id of patient linked to hub. can be null if only assigned to clinic and not a specific patient
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'patient_id'?: string;
    /**
     * number of sim card used to associate with hologram account
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'sim_card_number'?: string;
    /**
     * name of site
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'site_name'?: string;
    /**
     * status of hub
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'status'?: string;
    /**
     * timezone of hub
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'timezone'?: string;
    /**
     * hostname of vpn
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'vpn_hostname'?: string;
    /**
     * ip address of vpn
     * @type {string}
     * @memberof V1HubsHubIdGet200Response
     */
    'vpn_ip'?: string;
}
/**
 * 
 * @export
 * @interface V1HubsHubIdGet200ResponseHologramData
 */
export interface V1HubsHubIdGet200ResponseHologramData {
    /**
     * hologram id
     * @type {number}
     * @memberof V1HubsHubIdGet200ResponseHologramData
     */
    'id'?: number;
    /**
     * last time hub connected to network
     * @type {string}
     * @memberof V1HubsHubIdGet200ResponseHologramData
     */
    'last_connect_time'?: string;
    /**
     * last time data transferred on network
     * @type {string}
     * @memberof V1HubsHubIdGet200ResponseHologramData
     */
    'last_network_used'?: string;
    /**
     * link to hologram page
     * @type {string}
     * @memberof V1HubsHubIdGet200ResponseHologramData
     */
    'link'?: string;
    /**
     * state of sim card
     * @type {string}
     * @memberof V1HubsHubIdGet200ResponseHologramData
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface V1HubsHubIdPatchSyncLogLatestGet200Response
 */
export interface V1HubsHubIdPatchSyncLogLatestGet200Response {
    /**
     * logs
     * @type {string}
     * @memberof V1HubsHubIdPatchSyncLogLatestGet200Response
     */
    'json'?: string;
}
/**
 * 
 * @export
 * @interface V1OrderOrderidGet200ResponseInner
 */
export interface V1OrderOrderidGet200ResponseInner {
    /**
     * 
     * @type {Order}
     * @memberof V1OrderOrderidGet200ResponseInner
     */
    'order'?: Order;
}
/**
 * 
 * @export
 * @interface V1OrdersGet200Response
 */
export interface V1OrdersGet200Response {
    /**
     * 
     * @type {Array<Order>}
     * @memberof V1OrdersGet200Response
     */
    'orders': Array<Order>;
}
/**
 * 
 * @export
 * @interface V1PatchesPatchIdsGet200Response
 */
export interface V1PatchesPatchIdsGet200Response {
    /**
     * 
     * @type {Array<PatchDetail>}
     * @memberof V1PatchesPatchIdsGet200Response
     */
    'patches'?: Array<PatchDetail>;
}
/**
 * 
 * @export
 * @interface V1PatchmodesGet200Response
 */
export interface V1PatchmodesGet200Response {
    /**
     * 
     * @type {Array<V1PatchmodesGet200ResponsePatchModesInner>}
     * @memberof V1PatchmodesGet200Response
     */
    'patch_modes'?: Array<V1PatchmodesGet200ResponsePatchModesInner>;
}
/**
 * 
 * @export
 * @interface V1PatchmodesGet200ResponsePatchModesInner
 */
export interface V1PatchmodesGet200ResponsePatchModesInner {
    /**
     * 
     * @type {Array<V1PatchmodesGet200ResponsePatchModesInnerCommandsInner>}
     * @memberof V1PatchmodesGet200ResponsePatchModesInner
     */
    'commands'?: Array<V1PatchmodesGet200ResponsePatchModesInnerCommandsInner>;
    /**
     * description of patch mode
     * @type {string}
     * @memberof V1PatchmodesGet200ResponsePatchModesInner
     */
    'description'?: string;
    /**
     * patch mode id
     * @type {number}
     * @memberof V1PatchmodesGet200ResponsePatchModesInner
     */
    'id'?: number;
    /**
     * name of patch mode
     * @type {string}
     * @memberof V1PatchmodesGet200ResponsePatchModesInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface V1PatchmodesGet200ResponsePatchModesInnerCommandsInner
 */
export interface V1PatchmodesGet200ResponsePatchModesInnerCommandsInner {
    /**
     * arguments applied to command
     * @type {string}
     * @memberof V1PatchmodesGet200ResponsePatchModesInnerCommandsInner
     */
    'arguments'?: string;
    /**
     * hub api command
     * @type {string}
     * @memberof V1PatchmodesGet200ResponsePatchModesInnerCommandsInner
     */
    'command'?: string;
}
/**
 * 
 * @export
 * @interface V1PatientPatientIdDialysisEventsGet200Response
 */
export interface V1PatientPatientIdDialysisEventsGet200Response {
    /**
     * 
     * @type {boolean}
     * @memberof V1PatientPatientIdDialysisEventsGet200Response
     */
    'ok'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1PatientPatientIdDialysisEventsGet200Response
     */
    'request_id'?: string;
    /**
     * 
     * @type {Array<DialysisEvent>}
     * @memberof V1PatientPatientIdDialysisEventsGet200Response
     */
    'events'?: Array<DialysisEvent>;
}
/**
 * 
 * @export
 * @interface V1PatientPatientIdDialysisEventsPost200Response
 */
export interface V1PatientPatientIdDialysisEventsPost200Response {
    /**
     * 
     * @type {Patient}
     * @memberof V1PatientPatientIdDialysisEventsPost200Response
     */
    'patient': Patient;
}
/**
 * 
 * @export
 * @interface V1PatientPatientIdDialysisEventsPostRequest
 */
export interface V1PatientPatientIdDialysisEventsPostRequest {
    /**
     * 
     * @type {V1PatientPatientIdDialysisEventsPostRequestClinicalActionFeedback}
     * @memberof V1PatientPatientIdDialysisEventsPostRequest
     */
    'clinical_action_feedback'?: V1PatientPatientIdDialysisEventsPostRequestClinicalActionFeedback;
}
/**
 * 
 * @export
 * @interface V1PatientPatientIdDialysisEventsPostRequestClinicalActionFeedback
 */
export interface V1PatientPatientIdDialysisEventsPostRequestClinicalActionFeedback {
    /**
     * 
     * @type {boolean}
     * @memberof V1PatientPatientIdDialysisEventsPostRequestClinicalActionFeedback
     */
    'action_taken': boolean;
    /**
     * 
     * @type {number}
     * @memberof V1PatientPatientIdDialysisEventsPostRequestClinicalActionFeedback
     */
    'clinic_id': number;
    /**
     * 
     * @type {string}
     * @memberof V1PatientPatientIdDialysisEventsPostRequestClinicalActionFeedback
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof V1PatientPatientIdDialysisEventsPostRequestClinicalActionFeedback
     */
    'seconds_on_page': number;
}
/**
 * 
 * @export
 * @interface V1PatientPatientIdEventsGet200Response
 */
export interface V1PatientPatientIdEventsGet200Response {
    /**
     * 
     * @type {Array<Event>}
     * @memberof V1PatientPatientIdEventsGet200Response
     */
    'events'?: Array<Event>;
}
/**
 * 
 * @export
 * @interface V1PatientPatientIdEventsPost200Response
 */
export interface V1PatientPatientIdEventsPost200Response {
    /**
     * 
     * @type {boolean}
     * @memberof V1PatientPatientIdEventsPost200Response
     */
    'ok'?: boolean;
    /**
     * The ID of the newly created clinical action
     * @type {number}
     * @memberof V1PatientPatientIdEventsPost200Response
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface V1PatientPatientIdEventsPostRequest
 */
export interface V1PatientPatientIdEventsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof V1PatientPatientIdEventsPostRequest
     */
    'created_by': number;
    /**
     * 
     * @type {number}
     * @memberof V1PatientPatientIdEventsPostRequest
     */
    'patient_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof V1PatientPatientIdEventsPostRequest
     */
    'action_ts': string;
    /**
     * 
     * @type {ActionChoice}
     * @memberof V1PatientPatientIdEventsPostRequest
     */
    'action_choice': ActionChoice;
    /**
     * 
     * @type {string}
     * @memberof V1PatientPatientIdEventsPostRequest
     */
    'notes'?: string | null;
}


/**
 * 
 * @export
 * @interface V1PatientPatientidHubPostRequest
 */
export interface V1PatientPatientidHubPostRequest {
    /**
     * The ID of the hub to be added.
     * @type {string}
     * @memberof V1PatientPatientidHubPostRequest
     */
    'serial_number': string;
}
/**
 * 
 * @export
 * @interface V1PatientPatientidPatchPostRequest
 */
export interface V1PatientPatientidPatchPostRequest {
    /**
     * The serial number of the patch to be added.
     * @type {string}
     * @memberof V1PatientPatientidPatchPostRequest
     */
    'serial_number': string;
}
/**
 * 
 * @export
 * @interface V1PatientPatientidPutRequest
 */
export interface V1PatientPatientidPutRequest {
    /**
     * 
     * @type {Patient}
     * @memberof V1PatientPatientidPutRequest
     */
    'patient'?: Patient;
}
/**
 * 
 * @export
 * @interface V1PatientPatientidReplacementKitPostRequest
 */
export interface V1PatientPatientidReplacementKitPostRequest {
    /**
     * The ID of the replacement-kit to be added.
     * @type {string}
     * @memberof V1PatientPatientidReplacementKitPostRequest
     */
    'serial_number': string;
}
/**
 * 
 * @export
 * @interface V1PatientPatientidSessionSessionidPut200Response
 */
export interface V1PatientPatientidSessionSessionidPut200Response {
    /**
     * 
     * @type {PatientSession}
     * @memberof V1PatientPatientidSessionSessionidPut200Response
     */
    'session': PatientSession;
}
/**
 * 
 * @export
 * @interface V1PatientPatientidSessionsGet200Response
 */
export interface V1PatientPatientidSessionsGet200Response {
    /**
     * 
     * @type {Array<object>}
     * @memberof V1PatientPatientidSessionsGet200Response
     */
    'sessions': Array<object>;
}
/**
 * 
 * @export
 * @interface V1PatientPatientidStarterKitPostRequest
 */
export interface V1PatientPatientidStarterKitPostRequest {
    /**
     * The ID of the starter-kit to be added.
     * @type {string}
     * @memberof V1PatientPatientidStarterKitPostRequest
     */
    'serial_number': string;
}
/**
 * 
 * @export
 * @interface V1PatientPatientidSubjectidPutRequest
 */
export interface V1PatientPatientidSubjectidPutRequest {
    /**
     * 
     * @type {boolean}
     * @memberof V1PatientPatientidSubjectidPutRequest
     */
    'resetsubjectid'?: boolean;
    /**
     * New subject ID to use. Set of valid values may be limited.
     * @type {string}
     * @memberof V1PatientPatientidSubjectidPutRequest
     */
    'setsubjectid'?: string;
}
/**
 * 
 * @export
 * @interface V1PatientPatientidSuppliesPostRequest
 */
export interface V1PatientPatientidSuppliesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof V1PatientPatientidSuppliesPostRequest
     */
    'hubgsn'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1PatientPatientidSuppliesPostRequest
     */
    'kitgsn'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1PatientPatientidSuppliesPostRequest
     */
    'patchboxgsn'?: string;
    /**
     * 
     * @type {string}
     * @memberof V1PatientPatientidSuppliesPostRequest
     */
    'patchgsn'?: string;
}
/**
 * 
 * @export
 * @interface V1PatientsBulkPost200Response
 */
export interface V1PatientsBulkPost200Response {
    /**
     * 
     * @type {Array<BulkUploadError>}
     * @memberof V1PatientsBulkPost200Response
     */
    'errors'?: Array<BulkUploadError>;
    /**
     * 
     * @type {number}
     * @memberof V1PatientsBulkPost200Response
     */
    'failed'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PatientsBulkPost200Response
     */
    'processed'?: number;
    /**
     * 
     * @type {number}
     * @memberof V1PatientsBulkPost200Response
     */
    'succeeded'?: number;
}
/**
 * 
 * @export
 * @interface V1PatientsGet200Response
 */
export interface V1PatientsGet200Response {
    /**
     * The maximum patient ID for all patients.
     * @type {number}
     * @memberof V1PatientsGet200Response
     */
    'maxpatientid'?: number;
    /**
     * 
     * @type {Array<Patient>}
     * @memberof V1PatientsGet200Response
     */
    'patients': Array<Patient>;
}
/**
 * 
 * @export
 * @interface V1RabbitmqUsersPost200Response
 */
export interface V1RabbitmqUsersPost200Response {
    /**
     * rabbitmq password
     * @type {string}
     * @memberof V1RabbitmqUsersPost200Response
     */
    'password'?: string;
    /**
     * rabbitmq username
     * @type {string}
     * @memberof V1RabbitmqUsersPost200Response
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface V1SessionPost200Response
 */
export interface V1SessionPost200Response {
    /**
     * 
     * @type {Array<PatientSession>}
     * @memberof V1SessionPost200Response
     */
    'sessions': Array<PatientSession>;
}
/**
 * 
 * @export
 * @interface V1SessionPostRequest
 */
export interface V1SessionPostRequest {
    /**
     * 
     * @type {Array<PatientSession>}
     * @memberof V1SessionPostRequest
     */
    'sessions'?: Array<PatientSession>;
}
/**
 * 
 * @export
 * @interface V1SessionSplitPostRequest
 */
export interface V1SessionSplitPostRequest {
    /**
     * Type of the new session which is after the split point
     * @type {number}
     * @memberof V1SessionSplitPostRequest
     */
    'newtypeaftersplitpoint'?: number;
    /**
     * Type of the new session which is before the split point
     * @type {number}
     * @memberof V1SessionSplitPostRequest
     */
    'newtypebeforesplitpoint'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1SessionSplitPostRequest
     */
    'patients': Array<string>;
    /**
     * Type of the sessions which will be split
     * @type {number}
     * @memberof V1SessionSplitPostRequest
     */
    'sessiontype': number;
    /**
     * 
     * @type {number}
     * @memberof V1SessionSplitPostRequest
     */
    'splitpoint': number;
}
/**
 * 
 * @export
 * @interface V1SupportusersGet200Response
 */
export interface V1SupportusersGet200Response {
    /**
     * 
     * @type {Array<ClinicUser>}
     * @memberof V1SupportusersGet200Response
     */
    'supportusers': Array<ClinicUser>;
}
/**
 * 
 * @export
 * @interface V1WhoamiGet200Response
 */
export interface V1WhoamiGet200Response {
    /**
     * The user ID number for the currently authenticated user.
     * @type {number}
     * @memberof V1WhoamiGet200Response
     */
    'clinicuserid'?: number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List clinician groups
         * @param {string} clinicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicIdClinicianGroupsGet: async (clinicId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicId' is not null or undefined
            assertParamExists('v1ClinicClinicIdClinicianGroupsGet', 'clinicId', clinicId)
            const localVarPath = `/v1/clinic/{clinic_id}/clinician-groups`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all events
         * @param {string} clinicId 
         * @param {number} [start] start timestamp
         * @param {number} [stop] stop timestamp
         * @param {V1ClinicClinicIdEventsGetOrderByEnum} [orderBy] The field to order the results by
         * @param {V1ClinicClinicIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
         * @param {Array<string>} [patientName] List of patient names to filter by
         * @param {Array<string>} [physicianName] List of physician names to filter by
         * @param {Array<string>} [eventType] List of event types to filter by
         * @param {Array<string>} [createdByName] List of names of the creators to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicIdEventsGet: async (clinicId: string, start?: number, stop?: number, orderBy?: V1ClinicClinicIdEventsGetOrderByEnum, orderDirection?: V1ClinicClinicIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicId' is not null or undefined
            assertParamExists('v1ClinicClinicIdEventsGet', 'clinicId', clinicId)
            const localVarPath = `/v1/clinic/{clinic_id}/events`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (patientName) {
                localVarQueryParameter['patient_name'] = patientName;
            }

            if (physicianName) {
                localVarQueryParameter['physician_name'] = physicianName;
            }

            if (eventType) {
                localVarQueryParameter['event_type'] = eventType;
            }

            if (createdByName) {
                localVarQueryParameter['created_by_name'] = createdByName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clinicId 
         * @param {string} [name] A search string to filter patients by. Currently only searches by patient name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicIdPatientsSearchGet: async (clinicId: string, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicId' is not null or undefined
            assertParamExists('v1ClinicClinicIdPatientsSearchGet', 'clinicId', clinicId)
            const localVarPath = `/v1/clinic/{clinic_id}/patients/search`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a clinic\'s record
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidGet: async (clinicid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidGet', 'clinicid', clinicid)
            const localVarPath = `/v1/clinic/{clinicid}`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enumerates the patients you have access to from a specific clinic.
         * @param {number} clinicid 
         * @param {number} [limit] Number of records to return,
         * @param {number} [skip] Number of records to skip over.
         * @param {string} [sort] Comma separated list of field names to sort by
         * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
         * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
         * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
         * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidPatientsGet: async (clinicid: number, limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidPatientsGet', 'clinicid', clinicid)
            const localVarPath = `/v1/clinic/{clinicid}/patients`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (dir !== undefined) {
                localVarQueryParameter['dir'] = dir;
            }

            if (expectedmaxpatientid !== undefined) {
                localVarQueryParameter['expectedmaxpatientid'] = expectedmaxpatientid;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * which are completely omitted are not altered.<br/>
         * @summary Updates an existing clinic. For convenience, fields
         * @param {number} clinicid 
         * @param {V1ClinicClinicidPutRequest} updateclinic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidPut: async (clinicid: number, updateclinic: V1ClinicClinicidPutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidPut', 'clinicid', clinicid)
            // verify required parameter 'updateclinic' is not null or undefined
            assertParamExists('v1ClinicClinicidPut', 'updateclinic', updateclinic)
            const localVarPath = `/v1/clinic/{clinicid}`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateclinic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new session type for a clinic.
         * @param {number} clinicid 
         * @param {V1ClinicClinicidSessionPostRequest} createsessiontype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidSessionPost: async (clinicid: number, createsessiontype: V1ClinicClinicidSessionPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidSessionPost', 'clinicid', clinicid)
            // verify required parameter 'createsessiontype' is not null or undefined
            assertParamExists('v1ClinicClinicidSessionPost', 'createsessiontype', createsessiontype)
            const localVarPath = `/v1/clinic/{clinicid}/session`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createsessiontype, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing session type
         * @param {number} clinicid 
         * @param {number} sessiontypeid 
         * @param {V1ClinicClinicidSessionPostRequest} updatesessiontype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidSessionSessiontypeidPut: async (clinicid: number, sessiontypeid: number, updatesessiontype: V1ClinicClinicidSessionPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidSessionSessiontypeidPut', 'clinicid', clinicid)
            // verify required parameter 'sessiontypeid' is not null or undefined
            assertParamExists('v1ClinicClinicidSessionSessiontypeidPut', 'sessiontypeid', sessiontypeid)
            // verify required parameter 'updatesessiontype' is not null or undefined
            assertParamExists('v1ClinicClinicidSessionSessiontypeidPut', 'updatesessiontype', updatesessiontype)
            const localVarPath = `/v1/clinic/{clinicid}/session/{sessiontypeid}`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)))
                .replace(`{${"sessiontypeid"}}`, encodeURIComponent(String(sessiontypeid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatesessiontype, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enumerates the list of session types for a clinic.
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidSessiontypesGet: async (clinicid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidSessiontypesGet', 'clinicid', clinicid)
            const localVarPath = `/v1/clinic/{clinicid}/sessiontypes`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <br/>Note, currently it will simply return empty statistics if you request them for a clinic<br/>which doesn\'t exist, or which you don\'t have permission to see. Similarly, a clinic<br/>without patients will have zeros across the board, rather than any kind of indication.<br/>
         * @summary Retrieve detailed statistics about a clinic that make take a \"long\" time to compute.
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidStatsGet: async (clinicid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidStatsGet', 'clinicid', clinicid)
            const localVarPath = `/v1/clinic/{clinicid}/stats`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a clinic user from a clinic.
         * @param {number} clinicid 
         * @param {number} clinicuserid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidUserClinicuseridDelete: async (clinicid: number, clinicuserid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidUserClinicuseridDelete', 'clinicid', clinicid)
            // verify required parameter 'clinicuserid' is not null or undefined
            assertParamExists('v1ClinicClinicidUserClinicuseridDelete', 'clinicuserid', clinicuserid)
            const localVarPath = `/v1/clinic/{clinicid}/user/{clinicuserid}`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)))
                .replace(`{${"clinicuserid"}}`, encodeURIComponent(String(clinicuserid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enumerates the clinic users you have access to from a specific clinic
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidUsersGet: async (clinicid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidUsersGet', 'clinicid', clinicid)
            const localVarPath = `/v1/clinic/{clinicid}/users`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs the current user as having seen the clinic\'s patient list.
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidViewedPost: async (clinicid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicid' is not null or undefined
            assertParamExists('v1ClinicClinicidViewedPost', 'clinicid', clinicid)
            const localVarPath = `/v1/clinic/{clinicid}/viewed`
                .replace(`{${"clinicid"}}`, encodeURIComponent(String(clinicid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/>
         * @summary Creates an entirely new clinic.
         * @param {V1ClinicPostRequest} [createclinic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicPost: async (createclinic?: V1ClinicPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/clinic/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createclinic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
         * @summary Creates an entirely new clinic admin user
         * @param {V1ClinicadminPostRequest} createclinicadmin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicadminPost: async (createclinicadmin: V1ClinicadminPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createclinicadmin' is not null or undefined
            assertParamExists('v1ClinicadminPost', 'createclinicadmin', createclinicadmin)
            const localVarPath = `/v1/clinicadmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createclinicadmin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicianGroupGroupIdDelete: async (groupId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('v1ClinicianGroupGroupIdDelete', 'groupId', groupId)
            const localVarPath = `/v1/clinician-group/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicianGroupGroupIdGet: async (groupId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('v1ClinicianGroupGroupIdGet', 'groupId', groupId)
            const localVarPath = `/v1/clinician-group/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {V1ClinicianGroupGroupIdPutRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicianGroupGroupIdPut: async (groupId: number, body: V1ClinicianGroupGroupIdPutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('v1ClinicianGroupGroupIdPut', 'groupId', groupId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('v1ClinicianGroupGroupIdPut', 'body', body)
            const localVarPath = `/v1/clinician-group/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {V1ClinicianGroupPostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicianGroupPost: async (body: V1ClinicianGroupPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('v1ClinicianGroupPost', 'body', body)
            const localVarPath = `/v1/clinician-group/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enumerates the subset of clinics you have access to.
         * @param {string} [search] A search string to filter clinics by. Currently only searches by patient name.
         * @param {string} [include] Pass in \&#39;test\&#39; to include test patients in the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicsGet: async (search?: string, include?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/clinics/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a clinic user (clinician)\'s record
         * @param {number} clinicuserid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserClinicuseridGet: async (clinicuserid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicuserid' is not null or undefined
            assertParamExists('v1ClinicuserClinicuseridGet', 'clinicuserid', clinicuserid)
            const localVarPath = `/v1/clinicuser/{clinicuserid}`
                .replace(`{${"clinicuserid"}}`, encodeURIComponent(String(clinicuserid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing clinic user
         * @param {number} clinicuserid 
         * @param {V1ClinicadminPostRequest} updateclinicuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserClinicuseridPut: async (clinicuserid: number, updateclinicuser: V1ClinicadminPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicuserid' is not null or undefined
            assertParamExists('v1ClinicuserClinicuseridPut', 'clinicuserid', clinicuserid)
            // verify required parameter 'updateclinicuser' is not null or undefined
            assertParamExists('v1ClinicuserClinicuseridPut', 'updateclinicuser', updateclinicuser)
            const localVarPath = `/v1/clinicuser/{clinicuserid}`
                .replace(`{${"clinicuserid"}}`, encodeURIComponent(String(clinicuserid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateclinicuser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the password reset flow for a user.
         * @param {number} clinicuserid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserClinicuseridResetpasswordPost: async (clinicuserid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicuserid' is not null or undefined
            assertParamExists('v1ClinicuserClinicuseridResetpasswordPost', 'clinicuserid', clinicuserid)
            const localVarPath = `/v1/clinicuser/{clinicuserid}/resetpassword`
                .replace(`{${"clinicuserid"}}`, encodeURIComponent(String(clinicuserid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts the password reset flow for the given user.
         * @param {V1ClinicuserForgotPasswordPostRequest} [v1ClinicuserForgotPasswordPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserForgotPasswordPost: async (v1ClinicuserForgotPasswordPostRequest?: V1ClinicuserForgotPasswordPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/clinicuser/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1ClinicuserForgotPasswordPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
         * @summary Creates an entirely new clinic user
         * @param {V1ClinicadminPostRequest} createclinicuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserPost: async (createclinicuser: V1ClinicadminPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createclinicuser' is not null or undefined
            assertParamExists('v1ClinicuserPost', 'createclinicuser', createclinicuser)
            const localVarPath = `/v1/clinicuser/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createclinicuser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accepts privacy policy on behalf of signed-in user
         * @param {V1ClinicuserSelfAcceptprivacypolicyPutRequest} acceptance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserSelfAcceptprivacypolicyPut: async (acceptance: V1ClinicuserSelfAcceptprivacypolicyPutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptance' is not null or undefined
            assertParamExists('v1ClinicuserSelfAcceptprivacypolicyPut', 'acceptance', acceptance)
            const localVarPath = `/v1/clinicuser/self/acceptprivacypolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptance, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Performs any actions that should occur when the user logs in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserSelfLoginPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/clinicuser/self/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enumerates the clinic users you have access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicusersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/clinicusers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountriesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches details of a single patient sensor data record
         * @param {number} patientid 
         * @param {string} datatype Single data type to look up, \&quot;potassium\&quot;, \&quot;temperature\&quot;, \&quot;hr\&quot;, \&quot;audio\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hrvraw\&quot;
         * @param {number} timestamp Time stamp of the record to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataPatientidDatatypeTimestampGet: async (patientid: number, datatype: string, timestamp: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1DataPatientidDatatypeTimestampGet', 'patientid', patientid)
            // verify required parameter 'datatype' is not null or undefined
            assertParamExists('v1DataPatientidDatatypeTimestampGet', 'datatype', datatype)
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('v1DataPatientidDatatypeTimestampGet', 'timestamp', timestamp)
            const localVarPath = `/v1/data/{patientid}/{datatype}/{timestamp}`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)))
                .replace(`{${"datatype"}}`, encodeURIComponent(String(datatype)))
                .replace(`{${"timestamp"}}`, encodeURIComponent(String(timestamp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches patient sensor data.
         * @param {number} patientid 
         * @param {string} datatypes Comma-separated list of data types to fetch. \&quot;temperature\&quot;, \&quot;potassium\&quot;, \&quot;hr\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hgb\&quot;, \&quot;hrvraw\&quot;, and \&quot;audio\&quot; are the options.
         * @param {number} [start] Epoch time of the start of the time range to limit the query to.
         * @param {number} [stop] Epoch time of the end of the time range to limit the query to.
         * @param {string} [session] A patient session to fetch data for
         * @param {boolean} [streaming] Default false, determines where to query data from
         * @param {string} [patchId] Patch we use to search in mongo when in streaming mode
         * @param {boolean} [presign] Default false, determines whether to return presigned urls or
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataPatientidDatatypesGet: async (patientid: number, datatypes: string, start?: number, stop?: number, session?: string, streaming?: boolean, patchId?: string, presign?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1DataPatientidDatatypesGet', 'patientid', patientid)
            // verify required parameter 'datatypes' is not null or undefined
            assertParamExists('v1DataPatientidDatatypesGet', 'datatypes', datatypes)
            const localVarPath = `/v1/data/{patientid}/{datatypes}`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)))
                .replace(`{${"datatypes"}}`, encodeURIComponent(String(datatypes)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (session !== undefined) {
                localVarQueryParameter['session'] = session;
            }

            if (streaming !== undefined) {
                localVarQueryParameter['streaming'] = streaming;
            }

            if (patchId !== undefined) {
                localVarQueryParameter['patch_id'] = patchId;
            }

            if (presign !== undefined) {
                localVarQueryParameter['presign'] = presign;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch the total extent of patient\'s data.
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataPatientidExtentsGet: async (patientid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1DataPatientidExtentsGet', 'patientid', patientid)
            const localVarPath = `/v1/data/{patientid}/extents`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches summarized heartrate data
         * @param {number} patientid Patient ID
         * @param {V1DataSummaryPatientidHrPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataSummaryPatientidHrPost: async (patientid: number, body?: V1DataSummaryPatientidHrPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1DataSummaryPatientidHrPost', 'patientid', patientid)
            const localVarPath = `/v1/data/summary/{patientid}/hr`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Moves kits into this environment
         * @param {string} [kit] Kit ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeviceKitsMovePost: async (kit?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/device/kits/move`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for devices.
         * @param {DeviceSearch} [deviceSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeviceSearchPost: async (deviceSearch?: DeviceSearch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/device/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceSearch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an event
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EventsDelete: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('v1EventsDelete', 'eventId', eventId)
            const localVarPath = `/v1/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get hub data including hologram data
         * @param {string} hubId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HubsHubIdGet: async (hubId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hubId' is not null or undefined
            assertParamExists('v1HubsHubIdGet', 'hubId', hubId)
            const localVarPath = `/v1/hubs/{hub_id}`
                .replace(`{${"hub_id"}}`, encodeURIComponent(String(hubId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest logs for a patch
         * @param {string} hubId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HubsHubIdPatchSyncLogLatestGet: async (hubId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hubId' is not null or undefined
            assertParamExists('v1HubsHubIdPatchSyncLogLatestGet', 'hubId', hubId)
            const localVarPath = `/v1/hubs/{hub_id}/patch-sync-log/latest`
                .replace(`{${"hub_id"}}`, encodeURIComponent(String(hubId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger a notification directly
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsPost: async (notification?: Notification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a supplies order record
         * @param {string} orderid Order UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('v1OrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/v1/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a supplies order record
         * @param {Patient} createorder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrderPost: async (createorder: Patient, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createorder' is not null or undefined
            assertParamExists('v1OrderPost', 'createorder', createorder)
            const localVarPath = `/v1/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createorder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <br/>Omitting the patient and clinic ID will search for<br/>orders which are not attached to a patient or clinic,<br/>unless the all flag is set.<br/>
         * @summary Lists orders
         * @param {number} [patientid] Patient ID to search for
         * @param {number} [clinicid] Clinic ID to search for
         * @param {string} [orderid] Order ID to fetch
         * @param {boolean} [all] Specifically pulls all orders. May cause problems.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrdersGet: async (patientid?: number, clinicid?: number, orderid?: string, all?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (patientid !== undefined) {
                localVarQueryParameter['patientid'] = patientid;
            }

            if (clinicid !== undefined) {
                localVarQueryParameter['clinicid'] = clinicid;
            }

            if (orderid !== undefined) {
                localVarQueryParameter['orderid'] = orderid;
            }

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information from patch(es)
         * @param {Array<string>} patchIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatchesPatchIdsGet: async (patchIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchIds' is not null or undefined
            assertParamExists('v1PatchesPatchIdsGet', 'patchIds', patchIds)
            const localVarPath = `/v1/patches/{patch_ids}`
                .replace(`{${"patch_ids"}}`, encodeURIComponent(String(patchIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of patch modes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatchmodesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/patchmodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Grabs all dialysis events
         * @param {number} patientId 
         * @param {number} [start] start timestamp
         * @param {number} [stop] stop timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientIdDialysisEventsGet: async (patientId: number, start?: number, stop?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('v1PatientPatientIdDialysisEventsGet', 'patientId', patientId)
            const localVarPath = `/v1/patient/{patient_id}/dialysis-events`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new clinical_action associated with the specified patient
         * @param {number} patientId 
         * @param {V1PatientPatientIdDialysisEventsPostRequest} clinicalActionFeedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientIdDialysisEventsPost: async (patientId: number, clinicalActionFeedback: V1PatientPatientIdDialysisEventsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('v1PatientPatientIdDialysisEventsPost', 'patientId', patientId)
            // verify required parameter 'clinicalActionFeedback' is not null or undefined
            assertParamExists('v1PatientPatientIdDialysisEventsPost', 'clinicalActionFeedback', clinicalActionFeedback)
            const localVarPath = `/v1/patient/{patient_id}/dialysis-events`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clinicalActionFeedback, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all events
         * @param {string} patientId 
         * @param {number} [start] start timestamp
         * @param {number} [stop] stop timestamp
         * @param {V1PatientPatientIdEventsGetOrderByEnum} [orderBy] The field to order the results by
         * @param {V1PatientPatientIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
         * @param {Array<string>} [patientName] List of patient names to filter by
         * @param {Array<string>} [physicianName] List of physician names to filter by
         * @param {Array<string>} [eventType] List of event types to filter by
         * @param {Array<string>} [createdByName] List of names of the creators to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientIdEventsGet: async (patientId: string, start?: number, stop?: number, orderBy?: V1PatientPatientIdEventsGetOrderByEnum, orderDirection?: V1PatientPatientIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('v1PatientPatientIdEventsGet', 'patientId', patientId)
            const localVarPath = `/v1/patient/{patient_id}/events`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (stop !== undefined) {
                localVarQueryParameter['stop'] = stop;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (patientName) {
                localVarQueryParameter['patient_name'] = patientName;
            }

            if (physicianName) {
                localVarQueryParameter['physician_name'] = physicianName;
            }

            if (eventType) {
                localVarQueryParameter['event_type'] = eventType;
            }

            if (createdByName) {
                localVarQueryParameter['created_by_name'] = createdByName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new for the specified patient
         * @param {number} patientId 
         * @param {V1PatientPatientIdEventsPostRequest} [v1PatientPatientIdEventsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientIdEventsPost: async (patientId: number, v1PatientPatientIdEventsPostRequest?: V1PatientPatientIdEventsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('v1PatientPatientIdEventsPost', 'patientId', patientId)
            const localVarPath = `/v1/patient/{patient_id}/events`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1PatientPatientIdEventsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ends a patient\'s monitoring period, now.
         * @param {number} patientid 
         * @param {string} [monitoringEndReason] Reason why a patient is ending their use of our monitoring services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidEndmonitoringPut: async (patientid: number, monitoringEndReason?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidEndmonitoringPut', 'patientid', patientid)
            const localVarPath = `/v1/patient/{patientid}/endmonitoring`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitoringEndReason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a patient\'s record
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidGet: async (patientid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidGet', 'patientid', patientid)
            const localVarPath = `/v1/patient/{patientid}`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a hub to the specified patient\'s record.
         * @summary Adds a hub to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the hub will be added.
         * @param {V1PatientPatientidHubPostRequest} hubBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidHubPost: async (patientid: number, hubBody: V1PatientPatientidHubPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidHubPost', 'patientid', patientid)
            // verify required parameter 'hubBody' is not null or undefined
            assertParamExists('v1PatientPatientidHubPost', 'hubBody', hubBody)
            const localVarPath = `/v1/patient/{patientid}/hub`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hubBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a patch to the specified patient\'s record.
         * @summary Adds a patch to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the patch will be added.
         * @param {V1PatientPatientidPatchPostRequest} patchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidPatchPost: async (patientid: number, patchBody: V1PatientPatientidPatchPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidPatchPost', 'patientid', patientid)
            // verify required parameter 'patchBody' is not null or undefined
            assertParamExists('v1PatientPatientidPatchPost', 'patchBody', patchBody)
            const localVarPath = `/v1/patient/{patientid}/patch`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing patient
         * @param {number} patientid 
         * @param {V1PatientPatientidPutRequest} updatepatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidPut: async (patientid: number, updatepatient: V1PatientPatientidPutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidPut', 'patientid', patientid)
            // verify required parameter 'updatepatient' is not null or undefined
            assertParamExists('v1PatientPatientidPut', 'updatepatient', updatepatient)
            const localVarPath = `/v1/patient/{patientid}`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatepatient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a replacement-kit to the specified patient\'s record.
         * @summary Adds a replacement-kit to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the replacement-kit will be added.
         * @param {V1PatientPatientidReplacementKitPostRequest} replacementKitBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidReplacementKitPost: async (patientid: number, replacementKitBody: V1PatientPatientidReplacementKitPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidReplacementKitPost', 'patientid', patientid)
            // verify required parameter 'replacementKitBody' is not null or undefined
            assertParamExists('v1PatientPatientidReplacementKitPost', 'replacementKitBody', replacementKitBody)
            const localVarPath = `/v1/patient/{patientid}/replacement-kit`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replacementKitBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of a specific patient session.
         * @param {number} patientid 
         * @param {string} sessionid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSessionSessionidGet: async (patientid: number, sessionid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidSessionSessionidGet', 'patientid', patientid)
            // verify required parameter 'sessionid' is not null or undefined
            assertParamExists('v1PatientPatientidSessionSessionidGet', 'sessionid', sessionid)
            const localVarPath = `/v1/patient/{patientid}/session/{sessionid}`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)))
                .replace(`{${"sessionid"}}`, encodeURIComponent(String(sessionid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a patient\'s session
         * @param {number} patientid 
         * @param {number} sessionid 
         * @param {object} updatesession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSessionSessionidPut: async (patientid: number, sessionid: number, updatesession: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidSessionSessionidPut', 'patientid', patientid)
            // verify required parameter 'sessionid' is not null or undefined
            assertParamExists('v1PatientPatientidSessionSessionidPut', 'sessionid', sessionid)
            // verify required parameter 'updatesession' is not null or undefined
            assertParamExists('v1PatientPatientidSessionSessionidPut', 'updatesession', updatesession)
            const localVarPath = `/v1/patient/{patientid}/session/{sessionid}`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)))
                .replace(`{${"sessionid"}}`, encodeURIComponent(String(sessionid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatesession, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists all of a patient\'s sessions.
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSessionsGet: async (patientid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidSessionsGet', 'patientid', patientid)
            const localVarPath = `/v1/patient/{patientid}/sessions`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a starter-kit to the specified patient\'s record.
         * @summary Adds a starter-kit to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the starter-kit will be added.
         * @param {V1PatientPatientidStarterKitPostRequest} starterKitBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidStarterKitPost: async (patientid: number, starterKitBody: V1PatientPatientidStarterKitPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidStarterKitPost', 'patientid', patientid)
            // verify required parameter 'starterKitBody' is not null or undefined
            assertParamExists('v1PatientPatientidStarterKitPost', 'starterKitBody', starterKitBody)
            const localVarPath = `/v1/patient/{patientid}/starter-kit`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(starterKitBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <br/>This is separate from standard patient updating, as it should be a rare operation, not<br/>performed in conjunction with other patient updates, that may require expensive/slow<br/>checks. Also, it has a \"reset\" mechanism, which doesn\'t make any sense for other fields.<br/>
         * @summary Sets/resets a patient\'s subject ID, for attaching them to a different backend source.
         * @param {number} patientid 
         * @param {V1PatientPatientidSubjectidPutRequest} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSubjectidPut: async (patientid: number, type?: V1PatientPatientidSubjectidPutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidSubjectidPut', 'patientid', patientid)
            const localVarPath = `/v1/patient/{patientid}/subjectid`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(type, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This expects that you\'re only providing one of kitgsn, patchgsn, patchboxgsn or hubgsn<br/>at any given time.<br/>
         * @summary Adds supplies to a patient\'s record.
         * @param {number} patientid 
         * @param {V1PatientPatientidSuppliesPostRequest} supplies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSuppliesPost: async (patientid: number, supplies: V1PatientPatientidSuppliesPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidSuppliesPost', 'patientid', patientid)
            // verify required parameter 'supplies' is not null or undefined
            assertParamExists('v1PatientPatientidSuppliesPost', 'supplies', supplies)
            const localVarPath = `/v1/patient/{patientid}/supplies`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supplies, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs the current user as having seen the patient\'s record.
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidViewedPost: async (patientid: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientid' is not null or undefined
            assertParamExists('v1PatientPatientidViewedPost', 'patientid', patientid)
            const localVarPath = `/v1/patient/{patientid}/viewed`
                .replace(`{${"patientid"}}`, encodeURIComponent(String(patientid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/><br/>Some other fields are ignored/replaced by the backend, like \"added\", \"addedby\"<br/>and \"status\".<br/>
         * @summary Creates an entirely new patient.
         * @param {Patient} createpatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPost: async (createpatient: Patient, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createpatient' is not null or undefined
            assertParamExists('v1PatientPost', 'createpatient', createpatient)
            const localVarPath = `/v1/patient/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createpatient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Bulk upload of patients
         * @param {File} [file] CSV file of patients to create
         * @param {number} [clinic] Name of the clinic into which the patients will be imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientsBulkPost: async (file?: File, clinic?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/patients/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (clinic !== undefined) { 
                localVarFormParams.append('clinic', clinic as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enumerates the patients you have access to
         * @param {number} [limit] Number of records to return,
         * @param {number} [skip] Number of records to skip over.
         * @param {string} [sort] Comma separated list of field names to sort by
         * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
         * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
         * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
         * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientsGet: async (limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/patients/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (dir !== undefined) {
                localVarQueryParameter['dir'] = dir;
            }

            if (expectedmaxpatientid !== undefined) {
                localVarQueryParameter['expectedmaxpatientid'] = expectedmaxpatientid;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove hub from patient
         * @param {string} patientId 
         * @param {string} hubId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientsPatientIdHubsHubIdDelete: async (patientId: string, hubId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('v1PatientsPatientIdHubsHubIdDelete', 'patientId', patientId)
            // verify required parameter 'hubId' is not null or undefined
            assertParamExists('v1PatientsPatientIdHubsHubIdDelete', 'hubId', hubId)
            const localVarPath = `/v1/patients/{patient_id}/hubs/{hub_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"hub_id"}}`, encodeURIComponent(String(hubId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove patch from patient
         * @param {string} patientId 
         * @param {string} patchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientsPatientIdPatchesPatchIdDelete: async (patientId: string, patchId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('v1PatientsPatientIdPatchesPatchIdDelete', 'patientId', patientId)
            // verify required parameter 'patchId' is not null or undefined
            assertParamExists('v1PatientsPatientIdPatchesPatchIdDelete', 'patchId', patchId)
            const localVarPath = `/v1/patients/{patient_id}/patches/{patch_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"patch_id"}}`, encodeURIComponent(String(patchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
         * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
         * @param {V1ClinicadminPostRequest} createsupportuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PhisupportuserPost: async (createsupportuser: V1ClinicadminPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createsupportuser' is not null or undefined
            assertParamExists('v1PhisupportuserPost', 'createsupportuser', createsupportuser)
            const localVarPath = `/v1/phisupportuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createsupportuser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to connect to rabbitmq and receive live data<br/>
         * @summary Creates temporary credentials in rabbitmq which expire in a week
         * @param {string} patchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RabbitmqUsersPost: async (patchId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchId' is not null or undefined
            assertParamExists('v1RabbitmqUsersPost', 'patchId', patchId)
            const localVarPath = `/v1/rabbitmq/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes rabbitmq user to cleanup after use
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RabbitmqUsersUsernameDelete: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('v1RabbitmqUsersUsernameDelete', 'username', username)
            const localVarPath = `/v1/rabbitmq/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a CSV file containing the clinic report for a specific clinic identified by clinic_id.
         * @summary Get clinic report for a specific clinic
         * @param {number} clinicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportClinicsClinicIdGet: async (clinicId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicId' is not null or undefined
            assertParamExists('v1ReportClinicsClinicIdGet', 'clinicId', clinicId)
            const localVarPath = `/v1/report/clinics/{clinic_id}`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a CSV file containing the clinic report for all clinics.
         * @summary Get clinic report for all clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportClinicsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/report/clinics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a CSV file containing the patient report for a specific clinic identified by clinic_id.
         * @summary Get patient report for a specific clinic
         * @param {number} clinicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportPatientsClinicIdGet: async (clinicId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicId' is not null or undefined
            assertParamExists('v1ReportPatientsClinicIdGet', 'clinicId', clinicId)
            const localVarPath = `/v1/report/patients/{clinic_id}`
                .replace(`{${"clinic_id"}}`, encodeURIComponent(String(clinicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a CSV file containing the patient report for all clinics.
         * @summary Get patient report for all clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportPatientsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/report/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a batch of patient sessions.
         * @param {V1SessionPostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionPost: async (body: V1SessionPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('v1SessionPost', 'body', body)
            const localVarPath = `/v1/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Takes a set of instructions on how to perform one or many splits.
         * @param {V1SessionSplitPostRequest} splits 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionSplitPost: async (splits: V1SessionSplitPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'splits' is not null or undefined
            assertParamExists('v1SessionSplitPost', 'splits', splits)
            const localVarPath = `/v1/session/split`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(splits, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides configuration information for the operation of the support portal frontend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportinfoGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/supportinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
         * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
         * @param {V1ClinicadminPostRequest} createsupportuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportuserPost: async (createsupportuser: V1ClinicadminPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createsupportuser' is not null or undefined
            assertParamExists('v1SupportuserPost', 'createsupportuser', createsupportuser)
            const localVarPath = `/v1/supportuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createsupportuser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <br/>Only available to site admins and customer support users. Will fill out the<br/>groups property on the user records.<br/>
         * @summary Enumerates all of the admin / customer support users in the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportusersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/supportusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provides a bit of user-readable information about the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SysteminfoGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/systeminfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the user\'s user ID, from the session information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WhoamiGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * List clinician groups
         * @param {string} clinicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicIdClinicianGroupsGet(clinicId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicClinicIdClinicianGroupsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicIdClinicianGroupsGet(clinicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicIdClinicianGroupsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all events
         * @param {string} clinicId 
         * @param {number} [start] start timestamp
         * @param {number} [stop] stop timestamp
         * @param {V1ClinicClinicIdEventsGetOrderByEnum} [orderBy] The field to order the results by
         * @param {V1ClinicClinicIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
         * @param {Array<string>} [patientName] List of patient names to filter by
         * @param {Array<string>} [physicianName] List of physician names to filter by
         * @param {Array<string>} [eventType] List of event types to filter by
         * @param {Array<string>} [createdByName] List of names of the creators to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicIdEventsGet(clinicId: string, start?: number, stop?: number, orderBy?: V1ClinicClinicIdEventsGetOrderByEnum, orderDirection?: V1ClinicClinicIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientPatientIdEventsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicIdEventsGet(clinicId, start, stop, orderBy, orderDirection, patientName, physicianName, eventType, createdByName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicIdEventsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clinicId 
         * @param {string} [name] A search string to filter patients by. Currently only searches by patient name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicIdPatientsSearchGet(clinicId: string, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicIdPatientsSearchGet(clinicId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicIdPatientsSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a clinic\'s record
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidGet(clinicid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicClinicidGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidGet(clinicid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enumerates the patients you have access to from a specific clinic.
         * @param {number} clinicid 
         * @param {number} [limit] Number of records to return,
         * @param {number} [skip] Number of records to skip over.
         * @param {string} [sort] Comma separated list of field names to sort by
         * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
         * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
         * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
         * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidPatientsGet(clinicid: number, limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicClinicidPatientsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidPatientsGet(clinicid, limit, skip, sort, dir, expectedmaxpatientid, search, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidPatientsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * which are completely omitted are not altered.<br/>
         * @summary Updates an existing clinic. For convenience, fields
         * @param {number} clinicid 
         * @param {V1ClinicClinicidPutRequest} updateclinic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidPut(clinicid: number, updateclinic: V1ClinicClinicidPutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidPut(clinicid, updateclinic, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a new session type for a clinic.
         * @param {number} clinicid 
         * @param {V1ClinicClinicidSessionPostRequest} createsessiontype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidSessionPost(clinicid: number, createsessiontype: V1ClinicClinicidSessionPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicClinicidSessionPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidSessionPost(clinicid, createsessiontype, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidSessionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing session type
         * @param {number} clinicid 
         * @param {number} sessiontypeid 
         * @param {V1ClinicClinicidSessionPostRequest} updatesessiontype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidSessionSessiontypeidPut(clinicid: number, sessiontypeid: number, updatesessiontype: V1ClinicClinicidSessionPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidSessionSessiontypeidPut(clinicid, sessiontypeid, updatesessiontype, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidSessionSessiontypeidPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enumerates the list of session types for a clinic.
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidSessiontypesGet(clinicid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicClinicidSessiontypesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidSessiontypesGet(clinicid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidSessiontypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <br/>Note, currently it will simply return empty statistics if you request them for a clinic<br/>which doesn\'t exist, or which you don\'t have permission to see. Similarly, a clinic<br/>without patients will have zeros across the board, rather than any kind of indication.<br/>
         * @summary Retrieve detailed statistics about a clinic that make take a \"long\" time to compute.
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidStatsGet(clinicid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidStatsGet(clinicid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidStatsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Removes a clinic user from a clinic.
         * @param {number} clinicid 
         * @param {number} clinicuserid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidUserClinicuseridDelete(clinicid: number, clinicuserid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidUserClinicuseridDelete(clinicid, clinicuserid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidUserClinicuseridDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enumerates the clinic users you have access to from a specific clinic
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidUsersGet(clinicid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicClinicidUsersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidUsersGet(clinicid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Logs the current user as having seen the clinic\'s patient list.
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicClinicidViewedPost(clinicid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicClinicidViewedPost(clinicid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicClinicidViewedPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/>
         * @summary Creates an entirely new clinic.
         * @param {V1ClinicPostRequest} [createclinic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicPost(createclinic?: V1ClinicPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicPost(createclinic, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
         * @summary Creates an entirely new clinic admin user
         * @param {V1ClinicadminPostRequest} createclinicadmin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicadminPost(createclinicadmin: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicadminPost(createclinicadmin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicadminPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicianGroupGroupIdDelete(groupId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicianGroupGroupIdDelete(groupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicianGroupGroupIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicianGroupGroupIdGet(groupId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicianGroupGroupIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicianGroupGroupIdGet(groupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicianGroupGroupIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} groupId 
         * @param {V1ClinicianGroupGroupIdPutRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicianGroupGroupIdPut(groupId: number, body: V1ClinicianGroupGroupIdPutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicianGroupGroupIdPut(groupId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicianGroupGroupIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {V1ClinicianGroupPostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicianGroupPost(body: V1ClinicianGroupPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicianGroupPost(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicianGroupPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enumerates the subset of clinics you have access to.
         * @param {string} [search] A search string to filter clinics by. Currently only searches by patient name.
         * @param {string} [include] Pass in \&#39;test\&#39; to include test patients in the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicsGet(search?: string, include?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicsGet(search, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a clinic user (clinician)\'s record
         * @param {number} clinicuserid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicuserClinicuseridGet(clinicuserid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicuserClinicuseridGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicuserClinicuseridGet(clinicuserid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicuserClinicuseridGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing clinic user
         * @param {number} clinicuserid 
         * @param {V1ClinicadminPostRequest} updateclinicuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicuserClinicuseridPut(clinicuserid: number, updateclinicuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicuserClinicuseridPut(clinicuserid, updateclinicuser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicuserClinicuseridPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Starts the password reset flow for a user.
         * @param {number} clinicuserid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicuserClinicuseridResetpasswordPost(clinicuserid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicuserClinicuseridResetpasswordPost(clinicuserid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicuserClinicuseridResetpasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Starts the password reset flow for the given user.
         * @param {V1ClinicuserForgotPasswordPostRequest} [v1ClinicuserForgotPasswordPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicuserForgotPasswordPost(v1ClinicuserForgotPasswordPostRequest?: V1ClinicuserForgotPasswordPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicuserForgotPasswordPost(v1ClinicuserForgotPasswordPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicuserForgotPasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
         * @summary Creates an entirely new clinic user
         * @param {V1ClinicadminPostRequest} createclinicuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicuserPost(createclinicuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicuserPost(createclinicuser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicuserPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Accepts privacy policy on behalf of signed-in user
         * @param {V1ClinicuserSelfAcceptprivacypolicyPutRequest} acceptance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicuserSelfAcceptprivacypolicyPut(acceptance: V1ClinicuserSelfAcceptprivacypolicyPutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicuserSelfAcceptprivacypolicyPut(acceptance, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicuserSelfAcceptprivacypolicyPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Performs any actions that should occur when the user logs in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicuserSelfLoginPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicuserSelfLoginPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicuserSelfLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enumerates the clinic users you have access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ClinicusersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1ClinicClinicidUsersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ClinicusersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ClinicusersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a list of countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CountriesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Country>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CountriesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1CountriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetches details of a single patient sensor data record
         * @param {number} patientid 
         * @param {string} datatype Single data type to look up, \&quot;potassium\&quot;, \&quot;temperature\&quot;, \&quot;hr\&quot;, \&quot;audio\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hrvraw\&quot;
         * @param {number} timestamp Time stamp of the record to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DataPatientidDatatypeTimestampGet(patientid: number, datatype: string, timestamp: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DataPatientidDatatypeTimestampGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DataPatientidDatatypeTimestampGet(patientid, datatype, timestamp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1DataPatientidDatatypeTimestampGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetches patient sensor data.
         * @param {number} patientid 
         * @param {string} datatypes Comma-separated list of data types to fetch. \&quot;temperature\&quot;, \&quot;potassium\&quot;, \&quot;hr\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hgb\&quot;, \&quot;hrvraw\&quot;, and \&quot;audio\&quot; are the options.
         * @param {number} [start] Epoch time of the start of the time range to limit the query to.
         * @param {number} [stop] Epoch time of the end of the time range to limit the query to.
         * @param {string} [session] A patient session to fetch data for
         * @param {boolean} [streaming] Default false, determines where to query data from
         * @param {string} [patchId] Patch we use to search in mongo when in streaming mode
         * @param {boolean} [presign] Default false, determines whether to return presigned urls or
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DataPatientidDatatypesGet(patientid: number, datatypes: string, start?: number, stop?: number, session?: string, streaming?: boolean, patchId?: string, presign?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DataPatientidDatatypesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DataPatientidDatatypesGet(patientid, datatypes, start, stop, session, streaming, patchId, presign, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1DataPatientidDatatypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch the total extent of patient\'s data.
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DataPatientidExtentsGet(patientid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DataPatientidExtentsGet(patientid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1DataPatientidExtentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetches summarized heartrate data
         * @param {number} patientid Patient ID
         * @param {V1DataSummaryPatientidHrPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DataSummaryPatientidHrPost(patientid: number, body?: V1DataSummaryPatientidHrPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DataSummaryPatientidHrPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DataSummaryPatientidHrPost(patientid, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1DataSummaryPatientidHrPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Moves kits into this environment
         * @param {string} [kit] Kit ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeviceKitsMovePost(kit?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeviceKitsMovePost(kit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1DeviceKitsMovePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Searches for devices.
         * @param {DeviceSearch} [deviceSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeviceSearchPost(deviceSearch?: DeviceSearch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1DeviceSearchPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeviceSearchPost(deviceSearch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1DeviceSearchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes an event
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1EventsDelete(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1EventsDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1EventsDelete(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1EventsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get hub data including hologram data
         * @param {string} hubId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1HubsHubIdGet(hubId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1HubsHubIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1HubsHubIdGet(hubId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1HubsHubIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get latest logs for a patch
         * @param {string} hubId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1HubsHubIdPatchSyncLogLatestGet(hubId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1HubsHubIdPatchSyncLogLatestGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1HubsHubIdPatchSyncLogLatestGet(hubId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1HubsHubIdPatchSyncLogLatestGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Trigger a notification directly
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NotificationsPost(notification?: Notification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1NotificationsPost(notification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1NotificationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a supplies order record
         * @param {string} orderid Order UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<V1OrderOrderidGet200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1OrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a supplies order record
         * @param {Patient} createorder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrderPost(createorder: Patient, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrderPost(createorder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1OrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <br/>Omitting the patient and clinic ID will search for<br/>orders which are not attached to a patient or clinic,<br/>unless the all flag is set.<br/>
         * @summary Lists orders
         * @param {number} [patientid] Patient ID to search for
         * @param {number} [clinicid] Clinic ID to search for
         * @param {string} [orderid] Order ID to fetch
         * @param {boolean} [all] Specifically pulls all orders. May cause problems.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrdersGet(patientid?: number, clinicid?: number, orderid?: string, all?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1OrdersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrdersGet(patientid, clinicid, orderid, all, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1OrdersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get information from patch(es)
         * @param {Array<string>} patchIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatchesPatchIdsGet(patchIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatchesPatchIdsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatchesPatchIdsGet(patchIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatchesPatchIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a list of patch modes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatchmodesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatchmodesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatchmodesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatchmodesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Grabs all dialysis events
         * @param {number} patientId 
         * @param {number} [start] start timestamp
         * @param {number} [stop] stop timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientIdDialysisEventsGet(patientId: number, start?: number, stop?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientPatientIdDialysisEventsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientIdDialysisEventsGet(patientId, start, stop, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientIdDialysisEventsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a new clinical_action associated with the specified patient
         * @param {number} patientId 
         * @param {V1PatientPatientIdDialysisEventsPostRequest} clinicalActionFeedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientIdDialysisEventsPost(patientId: number, clinicalActionFeedback: V1PatientPatientIdDialysisEventsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientPatientIdDialysisEventsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientIdDialysisEventsPost(patientId, clinicalActionFeedback, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientIdDialysisEventsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all events
         * @param {string} patientId 
         * @param {number} [start] start timestamp
         * @param {number} [stop] stop timestamp
         * @param {V1PatientPatientIdEventsGetOrderByEnum} [orderBy] The field to order the results by
         * @param {V1PatientPatientIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
         * @param {Array<string>} [patientName] List of patient names to filter by
         * @param {Array<string>} [physicianName] List of physician names to filter by
         * @param {Array<string>} [eventType] List of event types to filter by
         * @param {Array<string>} [createdByName] List of names of the creators to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientIdEventsGet(patientId: string, start?: number, stop?: number, orderBy?: V1PatientPatientIdEventsGetOrderByEnum, orderDirection?: V1PatientPatientIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientPatientIdEventsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientIdEventsGet(patientId, start, stop, orderBy, orderDirection, patientName, physicianName, eventType, createdByName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientIdEventsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a new for the specified patient
         * @param {number} patientId 
         * @param {V1PatientPatientIdEventsPostRequest} [v1PatientPatientIdEventsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientIdEventsPost(patientId: number, v1PatientPatientIdEventsPostRequest?: V1PatientPatientIdEventsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientPatientIdEventsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientIdEventsPost(patientId, v1PatientPatientIdEventsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientIdEventsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Ends a patient\'s monitoring period, now.
         * @param {number} patientid 
         * @param {string} [monitoringEndReason] Reason why a patient is ending their use of our monitoring services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidEndmonitoringPut(patientid: number, monitoringEndReason?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidEndmonitoringPut(patientid, monitoringEndReason, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidEndmonitoringPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a patient\'s record
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidGet(patientid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientPatientIdDialysisEventsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidGet(patientid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add a hub to the specified patient\'s record.
         * @summary Adds a hub to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the hub will be added.
         * @param {V1PatientPatientidHubPostRequest} hubBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidHubPost(patientid: number, hubBody: V1PatientPatientidHubPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidHubPost(patientid, hubBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidHubPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add a patch to the specified patient\'s record.
         * @summary Adds a patch to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the patch will be added.
         * @param {V1PatientPatientidPatchPostRequest} patchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidPatchPost(patientid: number, patchBody: V1PatientPatientidPatchPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidPatchPost(patientid, patchBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidPatchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing patient
         * @param {number} patientid 
         * @param {V1PatientPatientidPutRequest} updatepatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidPut(patientid: number, updatepatient: V1PatientPatientidPutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidPut(patientid, updatepatient, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add a replacement-kit to the specified patient\'s record.
         * @summary Adds a replacement-kit to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the replacement-kit will be added.
         * @param {V1PatientPatientidReplacementKitPostRequest} replacementKitBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidReplacementKitPost(patientid: number, replacementKitBody: V1PatientPatientidReplacementKitPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidReplacementKitPost(patientid, replacementKitBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidReplacementKitPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get details of a specific patient session.
         * @param {number} patientid 
         * @param {string} sessionid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidSessionSessionidGet(patientid: number, sessionid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidSessionSessionidGet(patientid, sessionid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidSessionSessionidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a patient\'s session
         * @param {number} patientid 
         * @param {number} sessionid 
         * @param {object} updatesession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidSessionSessionidPut(patientid: number, sessionid: number, updatesession: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientPatientidSessionSessionidPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidSessionSessionidPut(patientid, sessionid, updatesession, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidSessionSessionidPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Lists all of a patient\'s sessions.
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidSessionsGet(patientid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientPatientidSessionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidSessionsGet(patientid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidSessionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add a starter-kit to the specified patient\'s record.
         * @summary Adds a starter-kit to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the starter-kit will be added.
         * @param {V1PatientPatientidStarterKitPostRequest} starterKitBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidStarterKitPost(patientid: number, starterKitBody: V1PatientPatientidStarterKitPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidStarterKitPost(patientid, starterKitBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidStarterKitPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <br/>This is separate from standard patient updating, as it should be a rare operation, not<br/>performed in conjunction with other patient updates, that may require expensive/slow<br/>checks. Also, it has a \"reset\" mechanism, which doesn\'t make any sense for other fields.<br/>
         * @summary Sets/resets a patient\'s subject ID, for attaching them to a different backend source.
         * @param {number} patientid 
         * @param {V1PatientPatientidSubjectidPutRequest} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidSubjectidPut(patientid: number, type?: V1PatientPatientidSubjectidPutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidSubjectidPut(patientid, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidSubjectidPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This expects that you\'re only providing one of kitgsn, patchgsn, patchboxgsn or hubgsn<br/>at any given time.<br/>
         * @summary Adds supplies to a patient\'s record.
         * @param {number} patientid 
         * @param {V1PatientPatientidSuppliesPostRequest} supplies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidSuppliesPost(patientid: number, supplies: V1PatientPatientidSuppliesPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidSuppliesPost(patientid, supplies, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidSuppliesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Logs the current user as having seen the patient\'s record.
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPatientidViewedPost(patientid: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPatientidViewedPost(patientid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPatientidViewedPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/><br/>Some other fields are ignored/replaced by the backend, like \"added\", \"addedby\"<br/>and \"status\".<br/>
         * @summary Creates an entirely new patient.
         * @param {Patient} createpatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientPost(createpatient: Patient, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientPost(createpatient, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Bulk upload of patients
         * @param {File} [file] CSV file of patients to create
         * @param {number} [clinic] Name of the clinic into which the patients will be imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientsBulkPost(file?: File, clinic?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientsBulkPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientsBulkPost(file, clinic, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientsBulkPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enumerates the patients you have access to
         * @param {number} [limit] Number of records to return,
         * @param {number} [skip] Number of records to skip over.
         * @param {string} [sort] Comma separated list of field names to sort by
         * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
         * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
         * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
         * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientsGet(limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1PatientsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientsGet(limit, skip, sort, dir, expectedmaxpatientid, search, include, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove hub from patient
         * @param {string} patientId 
         * @param {string} hubId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientsPatientIdHubsHubIdDelete(patientId: string, hubId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientsPatientIdHubsHubIdDelete(patientId, hubId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientsPatientIdHubsHubIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove patch from patient
         * @param {string} patientId 
         * @param {string} patchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PatientsPatientIdPatchesPatchIdDelete(patientId: string, patchId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PatientsPatientIdPatchesPatchIdDelete(patientId, patchId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PatientsPatientIdPatchesPatchIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
         * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
         * @param {V1ClinicadminPostRequest} createsupportuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PhisupportuserPost(createsupportuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PhisupportuserPost(createsupportuser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1PhisupportuserPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Used to connect to rabbitmq and receive live data<br/>
         * @summary Creates temporary credentials in rabbitmq which expire in a week
         * @param {string} patchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RabbitmqUsersPost(patchId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1RabbitmqUsersPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RabbitmqUsersPost(patchId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1RabbitmqUsersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes rabbitmq user to cleanup after use
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1RabbitmqUsersUsernameDelete(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1RabbitmqUsersUsernameDelete(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1RabbitmqUsersUsernameDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a CSV file containing the clinic report for a specific clinic identified by clinic_id.
         * @summary Get clinic report for a specific clinic
         * @param {number} clinicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReportClinicsClinicIdGet(clinicId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReportClinicsClinicIdGet(clinicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ReportClinicsClinicIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a CSV file containing the clinic report for all clinics.
         * @summary Get clinic report for all clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReportClinicsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReportClinicsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ReportClinicsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a CSV file containing the patient report for a specific clinic identified by clinic_id.
         * @summary Get patient report for a specific clinic
         * @param {number} clinicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReportPatientsClinicIdGet(clinicId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReportPatientsClinicIdGet(clinicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ReportPatientsClinicIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a CSV file containing the patient report for all clinics.
         * @summary Get patient report for all clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1ReportPatientsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1ReportPatientsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1ReportPatientsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a batch of patient sessions.
         * @param {V1SessionPostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SessionPost(body: V1SessionPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SessionPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SessionPost(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1SessionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Takes a set of instructions on how to perform one or many splits.
         * @param {V1SessionSplitPostRequest} splits 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SessionSplitPost(splits: V1SessionSplitPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SessionSplitPost(splits, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1SessionSplitPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Provides configuration information for the operation of the support portal frontend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportinfoGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SupportinfoGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1SupportinfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
         * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
         * @param {V1ClinicadminPostRequest} createsupportuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportuserPost(createsupportuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SupportuserPost(createsupportuser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1SupportuserPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <br/>Only available to site admins and customer support users. Will fill out the<br/>groups property on the user records.<br/>
         * @summary Enumerates all of the admin / customer support users in the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportusersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1SupportusersGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SupportusersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1SupportusersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Provides a bit of user-readable information about the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SysteminfoGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SysteminfoGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1SysteminfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns the user\'s user ID, from the session information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1WhoamiGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1WhoamiGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1WhoamiGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.v1WhoamiGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * List clinician groups
         * @param {string} clinicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicIdClinicianGroupsGet(clinicId: string, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicIdClinicianGroupsGet200Response> {
            return localVarFp.v1ClinicClinicIdClinicianGroupsGet(clinicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all events
         * @param {string} clinicId 
         * @param {number} [start] start timestamp
         * @param {number} [stop] stop timestamp
         * @param {V1ClinicClinicIdEventsGetOrderByEnum} [orderBy] The field to order the results by
         * @param {V1ClinicClinicIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
         * @param {Array<string>} [patientName] List of patient names to filter by
         * @param {Array<string>} [physicianName] List of physician names to filter by
         * @param {Array<string>} [eventType] List of event types to filter by
         * @param {Array<string>} [createdByName] List of names of the creators to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicIdEventsGet(clinicId: string, start?: number, stop?: number, orderBy?: V1ClinicClinicIdEventsGetOrderByEnum, orderDirection?: V1ClinicClinicIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdEventsGet200Response> {
            return localVarFp.v1ClinicClinicIdEventsGet(clinicId, start, stop, orderBy, orderDirection, patientName, physicianName, eventType, createdByName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clinicId 
         * @param {string} [name] A search string to filter patients by. Currently only searches by patient name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicIdPatientsSearchGet(clinicId: string, name?: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicClinicIdPatientsSearchGet(clinicId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a clinic\'s record
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidGet(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidGet200Response> {
            return localVarFp.v1ClinicClinicidGet(clinicid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enumerates the patients you have access to from a specific clinic.
         * @param {number} clinicid 
         * @param {number} [limit] Number of records to return,
         * @param {number} [skip] Number of records to skip over.
         * @param {string} [sort] Comma separated list of field names to sort by
         * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
         * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
         * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
         * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidPatientsGet(clinicid: number, limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidPatientsGet200Response> {
            return localVarFp.v1ClinicClinicidPatientsGet(clinicid, limit, skip, sort, dir, expectedmaxpatientid, search, include, options).then((request) => request(axios, basePath));
        },
        /**
         * which are completely omitted are not altered.<br/>
         * @summary Updates an existing clinic. For convenience, fields
         * @param {number} clinicid 
         * @param {V1ClinicClinicidPutRequest} updateclinic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidPut(clinicid: number, updateclinic: V1ClinicClinicidPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicClinicidPut(clinicid, updateclinic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new session type for a clinic.
         * @param {number} clinicid 
         * @param {V1ClinicClinicidSessionPostRequest} createsessiontype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidSessionPost(clinicid: number, createsessiontype: V1ClinicClinicidSessionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidSessionPost200Response> {
            return localVarFp.v1ClinicClinicidSessionPost(clinicid, createsessiontype, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing session type
         * @param {number} clinicid 
         * @param {number} sessiontypeid 
         * @param {V1ClinicClinicidSessionPostRequest} updatesessiontype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidSessionSessiontypeidPut(clinicid: number, sessiontypeid: number, updatesessiontype: V1ClinicClinicidSessionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicClinicidSessionSessiontypeidPut(clinicid, sessiontypeid, updatesessiontype, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enumerates the list of session types for a clinic.
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidSessiontypesGet(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidSessiontypesGet200Response> {
            return localVarFp.v1ClinicClinicidSessiontypesGet(clinicid, options).then((request) => request(axios, basePath));
        },
        /**
         * <br/>Note, currently it will simply return empty statistics if you request them for a clinic<br/>which doesn\'t exist, or which you don\'t have permission to see. Similarly, a clinic<br/>without patients will have zeros across the board, rather than any kind of indication.<br/>
         * @summary Retrieve detailed statistics about a clinic that make take a \"long\" time to compute.
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidStatsGet(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.v1ClinicClinicidStatsGet(clinicid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a clinic user from a clinic.
         * @param {number} clinicid 
         * @param {number} clinicuserid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidUserClinicuseridDelete(clinicid: number, clinicuserid: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicClinicidUserClinicuseridDelete(clinicid, clinicuserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enumerates the clinic users you have access to from a specific clinic
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidUsersGet(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidUsersGet200Response> {
            return localVarFp.v1ClinicClinicidUsersGet(clinicid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs the current user as having seen the clinic\'s patient list.
         * @param {number} clinicid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicClinicidViewedPost(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicClinicidViewedPost(clinicid, options).then((request) => request(axios, basePath));
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/>
         * @summary Creates an entirely new clinic.
         * @param {V1ClinicPostRequest} [createclinic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicPost(createclinic?: V1ClinicPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicPost(createclinic, options).then((request) => request(axios, basePath));
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
         * @summary Creates an entirely new clinic admin user
         * @param {V1ClinicadminPostRequest} createclinicadmin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicadminPost(createclinicadmin: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicadminPost(createclinicadmin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicianGroupGroupIdDelete(groupId: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicianGroupGroupIdDelete(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicianGroupGroupIdGet(groupId: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicianGroupGroupIdGet200Response> {
            return localVarFp.v1ClinicianGroupGroupIdGet(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupId 
         * @param {V1ClinicianGroupGroupIdPutRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicianGroupGroupIdPut(groupId: number, body: V1ClinicianGroupGroupIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicianGroupGroupIdPut(groupId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {V1ClinicianGroupPostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicianGroupPost(body: V1ClinicianGroupPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicianGroupPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enumerates the subset of clinics you have access to.
         * @param {string} [search] A search string to filter clinics by. Currently only searches by patient name.
         * @param {string} [include] Pass in \&#39;test\&#39; to include test patients in the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicsGet(search?: string, include?: string, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicsGet200Response> {
            return localVarFp.v1ClinicsGet(search, include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a clinic user (clinician)\'s record
         * @param {number} clinicuserid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserClinicuseridGet(clinicuserid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicuserClinicuseridGet200Response> {
            return localVarFp.v1ClinicuserClinicuseridGet(clinicuserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing clinic user
         * @param {number} clinicuserid 
         * @param {V1ClinicadminPostRequest} updateclinicuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserClinicuseridPut(clinicuserid: number, updateclinicuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicuserClinicuseridPut(clinicuserid, updateclinicuser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the password reset flow for a user.
         * @param {number} clinicuserid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserClinicuseridResetpasswordPost(clinicuserid: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicuserClinicuseridResetpasswordPost(clinicuserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts the password reset flow for the given user.
         * @param {V1ClinicuserForgotPasswordPostRequest} [v1ClinicuserForgotPasswordPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserForgotPasswordPost(v1ClinicuserForgotPasswordPostRequest?: V1ClinicuserForgotPasswordPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicuserForgotPasswordPost(v1ClinicuserForgotPasswordPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
         * @summary Creates an entirely new clinic user
         * @param {V1ClinicadminPostRequest} createclinicuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserPost(createclinicuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicuserPost(createclinicuser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accepts privacy policy on behalf of signed-in user
         * @param {V1ClinicuserSelfAcceptprivacypolicyPutRequest} acceptance 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserSelfAcceptprivacypolicyPut(acceptance: V1ClinicuserSelfAcceptprivacypolicyPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicuserSelfAcceptprivacypolicyPut(acceptance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Performs any actions that should occur when the user logs in.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicuserSelfLoginPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1ClinicuserSelfLoginPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enumerates the clinic users you have access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ClinicusersGet(options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidUsersGet200Response> {
            return localVarFp.v1ClinicusersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a list of countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountriesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<Country>> {
            return localVarFp.v1CountriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches details of a single patient sensor data record
         * @param {number} patientid 
         * @param {string} datatype Single data type to look up, \&quot;potassium\&quot;, \&quot;temperature\&quot;, \&quot;hr\&quot;, \&quot;audio\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hrvraw\&quot;
         * @param {number} timestamp Time stamp of the record to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataPatientidDatatypeTimestampGet(patientid: number, datatype: string, timestamp: number, options?: RawAxiosRequestConfig): AxiosPromise<V1DataPatientidDatatypeTimestampGet200Response> {
            return localVarFp.v1DataPatientidDatatypeTimestampGet(patientid, datatype, timestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches patient sensor data.
         * @param {number} patientid 
         * @param {string} datatypes Comma-separated list of data types to fetch. \&quot;temperature\&quot;, \&quot;potassium\&quot;, \&quot;hr\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hgb\&quot;, \&quot;hrvraw\&quot;, and \&quot;audio\&quot; are the options.
         * @param {number} [start] Epoch time of the start of the time range to limit the query to.
         * @param {number} [stop] Epoch time of the end of the time range to limit the query to.
         * @param {string} [session] A patient session to fetch data for
         * @param {boolean} [streaming] Default false, determines where to query data from
         * @param {string} [patchId] Patch we use to search in mongo when in streaming mode
         * @param {boolean} [presign] Default false, determines whether to return presigned urls or
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataPatientidDatatypesGet(patientid: number, datatypes: string, start?: number, stop?: number, session?: string, streaming?: boolean, patchId?: string, presign?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<V1DataPatientidDatatypesGet200Response> {
            return localVarFp.v1DataPatientidDatatypesGet(patientid, datatypes, start, stop, session, streaming, patchId, presign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch the total extent of patient\'s data.
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataPatientidExtentsGet(patientid: number, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.v1DataPatientidExtentsGet(patientid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches summarized heartrate data
         * @param {number} patientid Patient ID
         * @param {V1DataSummaryPatientidHrPostRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DataSummaryPatientidHrPost(patientid: number, body?: V1DataSummaryPatientidHrPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1DataSummaryPatientidHrPost200Response> {
            return localVarFp.v1DataSummaryPatientidHrPost(patientid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Moves kits into this environment
         * @param {string} [kit] Kit ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeviceKitsMovePost(kit?: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1DeviceKitsMovePost(kit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for devices.
         * @param {DeviceSearch} [deviceSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeviceSearchPost(deviceSearch?: DeviceSearch, options?: RawAxiosRequestConfig): AxiosPromise<V1DeviceSearchPost200Response> {
            return localVarFp.v1DeviceSearchPost(deviceSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an event
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1EventsDelete(eventId: number, options?: RawAxiosRequestConfig): AxiosPromise<V1EventsDelete200Response> {
            return localVarFp.v1EventsDelete(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get hub data including hologram data
         * @param {string} hubId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HubsHubIdGet(hubId: string, options?: RawAxiosRequestConfig): AxiosPromise<V1HubsHubIdGet200Response> {
            return localVarFp.v1HubsHubIdGet(hubId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest logs for a patch
         * @param {string} hubId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1HubsHubIdPatchSyncLogLatestGet(hubId: string, options?: RawAxiosRequestConfig): AxiosPromise<V1HubsHubIdPatchSyncLogLatestGet200Response> {
            return localVarFp.v1HubsHubIdPatchSyncLogLatestGet(hubId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger a notification directly
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NotificationsPost(notification?: Notification, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1NotificationsPost(notification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a supplies order record
         * @param {string} orderid Order UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<V1OrderOrderidGet200ResponseInner>> {
            return localVarFp.v1OrderOrderidGet(orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a supplies order record
         * @param {Patient} createorder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrderPost(createorder: Patient, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1OrderPost(createorder, options).then((request) => request(axios, basePath));
        },
        /**
         * <br/>Omitting the patient and clinic ID will search for<br/>orders which are not attached to a patient or clinic,<br/>unless the all flag is set.<br/>
         * @summary Lists orders
         * @param {number} [patientid] Patient ID to search for
         * @param {number} [clinicid] Clinic ID to search for
         * @param {string} [orderid] Order ID to fetch
         * @param {boolean} [all] Specifically pulls all orders. May cause problems.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrdersGet(patientid?: number, clinicid?: number, orderid?: string, all?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<V1OrdersGet200Response> {
            return localVarFp.v1OrdersGet(patientid, clinicid, orderid, all, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information from patch(es)
         * @param {Array<string>} patchIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatchesPatchIdsGet(patchIds: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<V1PatchesPatchIdsGet200Response> {
            return localVarFp.v1PatchesPatchIdsGet(patchIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a list of patch modes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatchmodesGet(options?: RawAxiosRequestConfig): AxiosPromise<V1PatchmodesGet200Response> {
            return localVarFp.v1PatchmodesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Grabs all dialysis events
         * @param {number} patientId 
         * @param {number} [start] start timestamp
         * @param {number} [stop] stop timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientIdDialysisEventsGet(patientId: number, start?: number, stop?: number, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdDialysisEventsGet200Response> {
            return localVarFp.v1PatientPatientIdDialysisEventsGet(patientId, start, stop, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new clinical_action associated with the specified patient
         * @param {number} patientId 
         * @param {V1PatientPatientIdDialysisEventsPostRequest} clinicalActionFeedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientIdDialysisEventsPost(patientId: number, clinicalActionFeedback: V1PatientPatientIdDialysisEventsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdDialysisEventsPost200Response> {
            return localVarFp.v1PatientPatientIdDialysisEventsPost(patientId, clinicalActionFeedback, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all events
         * @param {string} patientId 
         * @param {number} [start] start timestamp
         * @param {number} [stop] stop timestamp
         * @param {V1PatientPatientIdEventsGetOrderByEnum} [orderBy] The field to order the results by
         * @param {V1PatientPatientIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
         * @param {Array<string>} [patientName] List of patient names to filter by
         * @param {Array<string>} [physicianName] List of physician names to filter by
         * @param {Array<string>} [eventType] List of event types to filter by
         * @param {Array<string>} [createdByName] List of names of the creators to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientIdEventsGet(patientId: string, start?: number, stop?: number, orderBy?: V1PatientPatientIdEventsGetOrderByEnum, orderDirection?: V1PatientPatientIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdEventsGet200Response> {
            return localVarFp.v1PatientPatientIdEventsGet(patientId, start, stop, orderBy, orderDirection, patientName, physicianName, eventType, createdByName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new for the specified patient
         * @param {number} patientId 
         * @param {V1PatientPatientIdEventsPostRequest} [v1PatientPatientIdEventsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientIdEventsPost(patientId: number, v1PatientPatientIdEventsPostRequest?: V1PatientPatientIdEventsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdEventsPost200Response> {
            return localVarFp.v1PatientPatientIdEventsPost(patientId, v1PatientPatientIdEventsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ends a patient\'s monitoring period, now.
         * @param {number} patientid 
         * @param {string} [monitoringEndReason] Reason why a patient is ending their use of our monitoring services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidEndmonitoringPut(patientid: number, monitoringEndReason?: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPatientidEndmonitoringPut(patientid, monitoringEndReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a patient\'s record
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidGet(patientid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdDialysisEventsPost200Response> {
            return localVarFp.v1PatientPatientidGet(patientid, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a hub to the specified patient\'s record.
         * @summary Adds a hub to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the hub will be added.
         * @param {V1PatientPatientidHubPostRequest} hubBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidHubPost(patientid: number, hubBody: V1PatientPatientidHubPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPatientidHubPost(patientid, hubBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a patch to the specified patient\'s record.
         * @summary Adds a patch to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the patch will be added.
         * @param {V1PatientPatientidPatchPostRequest} patchBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidPatchPost(patientid: number, patchBody: V1PatientPatientidPatchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPatientidPatchPost(patientid, patchBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing patient
         * @param {number} patientid 
         * @param {V1PatientPatientidPutRequest} updatepatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidPut(patientid: number, updatepatient: V1PatientPatientidPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPatientidPut(patientid, updatepatient, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a replacement-kit to the specified patient\'s record.
         * @summary Adds a replacement-kit to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the replacement-kit will be added.
         * @param {V1PatientPatientidReplacementKitPostRequest} replacementKitBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidReplacementKitPost(patientid: number, replacementKitBody: V1PatientPatientidReplacementKitPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPatientidReplacementKitPost(patientid, replacementKitBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of a specific patient session.
         * @param {number} patientid 
         * @param {string} sessionid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSessionSessionidGet(patientid: number, sessionid: string, options?: RawAxiosRequestConfig): AxiosPromise<PatientSession> {
            return localVarFp.v1PatientPatientidSessionSessionidGet(patientid, sessionid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a patient\'s session
         * @param {number} patientid 
         * @param {number} sessionid 
         * @param {object} updatesession 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSessionSessionidPut(patientid: number, sessionid: number, updatesession: object, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientidSessionSessionidPut200Response> {
            return localVarFp.v1PatientPatientidSessionSessionidPut(patientid, sessionid, updatesession, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists all of a patient\'s sessions.
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSessionsGet(patientid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientidSessionsGet200Response> {
            return localVarFp.v1PatientPatientidSessionsGet(patientid, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a starter-kit to the specified patient\'s record.
         * @summary Adds a starter-kit to a patient\'s record.
         * @param {number} patientid The ID of the patient to whom the starter-kit will be added.
         * @param {V1PatientPatientidStarterKitPostRequest} starterKitBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidStarterKitPost(patientid: number, starterKitBody: V1PatientPatientidStarterKitPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPatientidStarterKitPost(patientid, starterKitBody, options).then((request) => request(axios, basePath));
        },
        /**
         * <br/>This is separate from standard patient updating, as it should be a rare operation, not<br/>performed in conjunction with other patient updates, that may require expensive/slow<br/>checks. Also, it has a \"reset\" mechanism, which doesn\'t make any sense for other fields.<br/>
         * @summary Sets/resets a patient\'s subject ID, for attaching them to a different backend source.
         * @param {number} patientid 
         * @param {V1PatientPatientidSubjectidPutRequest} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSubjectidPut(patientid: number, type?: V1PatientPatientidSubjectidPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPatientidSubjectidPut(patientid, type, options).then((request) => request(axios, basePath));
        },
        /**
         * This expects that you\'re only providing one of kitgsn, patchgsn, patchboxgsn or hubgsn<br/>at any given time.<br/>
         * @summary Adds supplies to a patient\'s record.
         * @param {number} patientid 
         * @param {V1PatientPatientidSuppliesPostRequest} supplies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidSuppliesPost(patientid: number, supplies: V1PatientPatientidSuppliesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPatientidSuppliesPost(patientid, supplies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs the current user as having seen the patient\'s record.
         * @param {number} patientid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPatientidViewedPost(patientid: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPatientidViewedPost(patientid, options).then((request) => request(axios, basePath));
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/><br/>Some other fields are ignored/replaced by the backend, like \"added\", \"addedby\"<br/>and \"status\".<br/>
         * @summary Creates an entirely new patient.
         * @param {Patient} createpatient 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientPost(createpatient: Patient, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientPost(createpatient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Bulk upload of patients
         * @param {File} [file] CSV file of patients to create
         * @param {number} [clinic] Name of the clinic into which the patients will be imported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientsBulkPost(file?: File, clinic?: number, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientsBulkPost200Response> {
            return localVarFp.v1PatientsBulkPost(file, clinic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enumerates the patients you have access to
         * @param {number} [limit] Number of records to return,
         * @param {number} [skip] Number of records to skip over.
         * @param {string} [sort] Comma separated list of field names to sort by
         * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
         * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
         * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
         * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientsGet(limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientsGet200Response> {
            return localVarFp.v1PatientsGet(limit, skip, sort, dir, expectedmaxpatientid, search, include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove hub from patient
         * @param {string} patientId 
         * @param {string} hubId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientsPatientIdHubsHubIdDelete(patientId: string, hubId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientsPatientIdHubsHubIdDelete(patientId, hubId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove patch from patient
         * @param {string} patientId 
         * @param {string} patchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PatientsPatientIdPatchesPatchIdDelete(patientId: string, patchId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PatientsPatientIdPatchesPatchIdDelete(patientId, patchId, options).then((request) => request(axios, basePath));
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
         * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
         * @param {V1ClinicadminPostRequest} createsupportuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PhisupportuserPost(createsupportuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1PhisupportuserPost(createsupportuser, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to connect to rabbitmq and receive live data<br/>
         * @summary Creates temporary credentials in rabbitmq which expire in a week
         * @param {string} patchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RabbitmqUsersPost(patchId: string, options?: RawAxiosRequestConfig): AxiosPromise<V1RabbitmqUsersPost200Response> {
            return localVarFp.v1RabbitmqUsersPost(patchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes rabbitmq user to cleanup after use
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1RabbitmqUsersUsernameDelete(username: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1RabbitmqUsersUsernameDelete(username, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a CSV file containing the clinic report for a specific clinic identified by clinic_id.
         * @summary Get clinic report for a specific clinic
         * @param {number} clinicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportClinicsClinicIdGet(clinicId: number, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.v1ReportClinicsClinicIdGet(clinicId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a CSV file containing the clinic report for all clinics.
         * @summary Get clinic report for all clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportClinicsGet(options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.v1ReportClinicsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a CSV file containing the patient report for a specific clinic identified by clinic_id.
         * @summary Get patient report for a specific clinic
         * @param {number} clinicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportPatientsClinicIdGet(clinicId: number, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.v1ReportPatientsClinicIdGet(clinicId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a CSV file containing the patient report for all clinics.
         * @summary Get patient report for all clinics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ReportPatientsGet(options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.v1ReportPatientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a batch of patient sessions.
         * @param {V1SessionPostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionPost(body: V1SessionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1SessionPost200Response> {
            return localVarFp.v1SessionPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Takes a set of instructions on how to perform one or many splits.
         * @param {V1SessionSplitPostRequest} splits 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SessionSplitPost(splits: V1SessionSplitPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1SessionSplitPost(splits, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides configuration information for the operation of the support portal frontend.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportinfoGet(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.v1SupportinfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
         * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
         * @param {V1ClinicadminPostRequest} createsupportuser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportuserPost(createsupportuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v1SupportuserPost(createsupportuser, options).then((request) => request(axios, basePath));
        },
        /**
         * <br/>Only available to site admins and customer support users. Will fill out the<br/>groups property on the user records.<br/>
         * @summary Enumerates all of the admin / customer support users in the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportusersGet(options?: RawAxiosRequestConfig): AxiosPromise<V1SupportusersGet200Response> {
            return localVarFp.v1SupportusersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provides a bit of user-readable information about the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SysteminfoGet(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.v1SysteminfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the user\'s user ID, from the session information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1WhoamiGet(options?: RawAxiosRequestConfig): AxiosPromise<V1WhoamiGet200Response> {
            return localVarFp.v1WhoamiGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * List clinician groups
     * @param {string} clinicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicIdClinicianGroupsGet(clinicId: string, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicIdClinicianGroupsGet200Response>;

    /**
     * 
     * @summary Get all events
     * @param {string} clinicId 
     * @param {number} [start] start timestamp
     * @param {number} [stop] stop timestamp
     * @param {V1ClinicClinicIdEventsGetOrderByEnum} [orderBy] The field to order the results by
     * @param {V1ClinicClinicIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
     * @param {Array<string>} [patientName] List of patient names to filter by
     * @param {Array<string>} [physicianName] List of physician names to filter by
     * @param {Array<string>} [eventType] List of event types to filter by
     * @param {Array<string>} [createdByName] List of names of the creators to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicIdEventsGet(clinicId: string, start?: number, stop?: number, orderBy?: V1ClinicClinicIdEventsGetOrderByEnum, orderDirection?: V1ClinicClinicIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdEventsGet200Response>;

    /**
     * 
     * @param {string} clinicId 
     * @param {string} [name] A search string to filter patients by. Currently only searches by patient name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicIdPatientsSearchGet(clinicId: string, name?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Retrieves a clinic\'s record
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidGet(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidGet200Response>;

    /**
     * 
     * @summary Enumerates the patients you have access to from a specific clinic.
     * @param {number} clinicid 
     * @param {number} [limit] Number of records to return,
     * @param {number} [skip] Number of records to skip over.
     * @param {string} [sort] Comma separated list of field names to sort by
     * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
     * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
     * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
     * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidPatientsGet(clinicid: number, limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidPatientsGet200Response>;

    /**
     * which are completely omitted are not altered.<br/>
     * @summary Updates an existing clinic. For convenience, fields
     * @param {number} clinicid 
     * @param {V1ClinicClinicidPutRequest} updateclinic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidPut(clinicid: number, updateclinic: V1ClinicClinicidPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a new session type for a clinic.
     * @param {number} clinicid 
     * @param {V1ClinicClinicidSessionPostRequest} createsessiontype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidSessionPost(clinicid: number, createsessiontype: V1ClinicClinicidSessionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidSessionPost200Response>;

    /**
     * 
     * @summary Updates an existing session type
     * @param {number} clinicid 
     * @param {number} sessiontypeid 
     * @param {V1ClinicClinicidSessionPostRequest} updatesessiontype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidSessionSessiontypeidPut(clinicid: number, sessiontypeid: number, updatesessiontype: V1ClinicClinicidSessionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Enumerates the list of session types for a clinic.
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidSessiontypesGet(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidSessiontypesGet200Response>;

    /**
     * <br/>Note, currently it will simply return empty statistics if you request them for a clinic<br/>which doesn\'t exist, or which you don\'t have permission to see. Similarly, a clinic<br/>without patients will have zeros across the board, rather than any kind of indication.<br/>
     * @summary Retrieve detailed statistics about a clinic that make take a \"long\" time to compute.
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidStatsGet(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @summary Removes a clinic user from a clinic.
     * @param {number} clinicid 
     * @param {number} clinicuserid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidUserClinicuseridDelete(clinicid: number, clinicuserid: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Enumerates the clinic users you have access to from a specific clinic
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidUsersGet(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidUsersGet200Response>;

    /**
     * 
     * @summary Logs the current user as having seen the clinic\'s patient list.
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicClinicidViewedPost(clinicid: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/>
     * @summary Creates an entirely new clinic.
     * @param {V1ClinicPostRequest} [createclinic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicPost(createclinic?: V1ClinicPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
     * @summary Creates an entirely new clinic admin user
     * @param {V1ClinicadminPostRequest} createclinicadmin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicadminPost(createclinicadmin: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicianGroupGroupIdDelete(groupId: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicianGroupGroupIdGet(groupId: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicianGroupGroupIdGet200Response>;

    /**
     * 
     * @param {number} groupId 
     * @param {V1ClinicianGroupGroupIdPutRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicianGroupGroupIdPut(groupId: number, body: V1ClinicianGroupGroupIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {V1ClinicianGroupPostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicianGroupPost(body: V1ClinicianGroupPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Enumerates the subset of clinics you have access to.
     * @param {string} [search] A search string to filter clinics by. Currently only searches by patient name.
     * @param {string} [include] Pass in \&#39;test\&#39; to include test patients in the list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicsGet(search?: string, include?: string, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicsGet200Response>;

    /**
     * 
     * @summary Retrieves a clinic user (clinician)\'s record
     * @param {number} clinicuserid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicuserClinicuseridGet(clinicuserid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicuserClinicuseridGet200Response>;

    /**
     * 
     * @summary Updates an existing clinic user
     * @param {number} clinicuserid 
     * @param {V1ClinicadminPostRequest} updateclinicuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicuserClinicuseridPut(clinicuserid: number, updateclinicuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Starts the password reset flow for a user.
     * @param {number} clinicuserid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicuserClinicuseridResetpasswordPost(clinicuserid: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Starts the password reset flow for the given user.
     * @param {V1ClinicuserForgotPasswordPostRequest} [v1ClinicuserForgotPasswordPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicuserForgotPasswordPost(v1ClinicuserForgotPasswordPostRequest?: V1ClinicuserForgotPasswordPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
     * @summary Creates an entirely new clinic user
     * @param {V1ClinicadminPostRequest} createclinicuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicuserPost(createclinicuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Accepts privacy policy on behalf of signed-in user
     * @param {V1ClinicuserSelfAcceptprivacypolicyPutRequest} acceptance 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicuserSelfAcceptprivacypolicyPut(acceptance: V1ClinicuserSelfAcceptprivacypolicyPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Performs any actions that should occur when the user logs in.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicuserSelfLoginPost(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Enumerates the clinic users you have access to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ClinicusersGet(options?: RawAxiosRequestConfig): AxiosPromise<V1ClinicClinicidUsersGet200Response>;

    /**
     * 
     * @summary Retrieves a list of countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1CountriesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<Country>>;

    /**
     * 
     * @summary Fetches details of a single patient sensor data record
     * @param {number} patientid 
     * @param {string} datatype Single data type to look up, \&quot;potassium\&quot;, \&quot;temperature\&quot;, \&quot;hr\&quot;, \&quot;audio\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hrvraw\&quot;
     * @param {number} timestamp Time stamp of the record to find
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1DataPatientidDatatypeTimestampGet(patientid: number, datatype: string, timestamp: number, options?: RawAxiosRequestConfig): AxiosPromise<V1DataPatientidDatatypeTimestampGet200Response>;

    /**
     * 
     * @summary Fetches patient sensor data.
     * @param {number} patientid 
     * @param {string} datatypes Comma-separated list of data types to fetch. \&quot;temperature\&quot;, \&quot;potassium\&quot;, \&quot;hr\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hgb\&quot;, \&quot;hrvraw\&quot;, and \&quot;audio\&quot; are the options.
     * @param {number} [start] Epoch time of the start of the time range to limit the query to.
     * @param {number} [stop] Epoch time of the end of the time range to limit the query to.
     * @param {string} [session] A patient session to fetch data for
     * @param {boolean} [streaming] Default false, determines where to query data from
     * @param {string} [patchId] Patch we use to search in mongo when in streaming mode
     * @param {boolean} [presign] Default false, determines whether to return presigned urls or
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1DataPatientidDatatypesGet(patientid: number, datatypes: string, start?: number, stop?: number, session?: string, streaming?: boolean, patchId?: string, presign?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<V1DataPatientidDatatypesGet200Response>;

    /**
     * 
     * @summary Fetch the total extent of patient\'s data.
     * @param {number} patientid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1DataPatientidExtentsGet(patientid: number, options?: RawAxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @summary Fetches summarized heartrate data
     * @param {number} patientid Patient ID
     * @param {V1DataSummaryPatientidHrPostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1DataSummaryPatientidHrPost(patientid: number, body?: V1DataSummaryPatientidHrPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1DataSummaryPatientidHrPost200Response>;

    /**
     * 
     * @summary Moves kits into this environment
     * @param {string} [kit] Kit ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1DeviceKitsMovePost(kit?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Searches for devices.
     * @param {DeviceSearch} [deviceSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1DeviceSearchPost(deviceSearch?: DeviceSearch, options?: RawAxiosRequestConfig): AxiosPromise<V1DeviceSearchPost200Response>;

    /**
     * 
     * @summary Deletes an event
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1EventsDelete(eventId: number, options?: RawAxiosRequestConfig): AxiosPromise<V1EventsDelete200Response>;

    /**
     * 
     * @summary Get hub data including hologram data
     * @param {string} hubId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1HubsHubIdGet(hubId: string, options?: RawAxiosRequestConfig): AxiosPromise<V1HubsHubIdGet200Response>;

    /**
     * 
     * @summary Get latest logs for a patch
     * @param {string} hubId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1HubsHubIdPatchSyncLogLatestGet(hubId: string, options?: RawAxiosRequestConfig): AxiosPromise<V1HubsHubIdPatchSyncLogLatestGet200Response>;

    /**
     * 
     * @summary Trigger a notification directly
     * @param {Notification} [notification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1NotificationsPost(notification?: Notification, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Retrieves a supplies order record
     * @param {string} orderid Order UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1OrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<V1OrderOrderidGet200ResponseInner>>;

    /**
     * 
     * @summary Creates a supplies order record
     * @param {Patient} createorder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1OrderPost(createorder: Patient, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * <br/>Omitting the patient and clinic ID will search for<br/>orders which are not attached to a patient or clinic,<br/>unless the all flag is set.<br/>
     * @summary Lists orders
     * @param {number} [patientid] Patient ID to search for
     * @param {number} [clinicid] Clinic ID to search for
     * @param {string} [orderid] Order ID to fetch
     * @param {boolean} [all] Specifically pulls all orders. May cause problems.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1OrdersGet(patientid?: number, clinicid?: number, orderid?: string, all?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<V1OrdersGet200Response>;

    /**
     * 
     * @summary Get information from patch(es)
     * @param {Array<string>} patchIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatchesPatchIdsGet(patchIds: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<V1PatchesPatchIdsGet200Response>;

    /**
     * 
     * @summary Retrieves a list of patch modes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatchmodesGet(options?: RawAxiosRequestConfig): AxiosPromise<V1PatchmodesGet200Response>;

    /**
     * 
     * @summary Grabs all dialysis events
     * @param {number} patientId 
     * @param {number} [start] start timestamp
     * @param {number} [stop] stop timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientIdDialysisEventsGet(patientId: number, start?: number, stop?: number, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdDialysisEventsGet200Response>;

    /**
     * 
     * @summary Creates a new clinical_action associated with the specified patient
     * @param {number} patientId 
     * @param {V1PatientPatientIdDialysisEventsPostRequest} clinicalActionFeedback 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientIdDialysisEventsPost(patientId: number, clinicalActionFeedback: V1PatientPatientIdDialysisEventsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdDialysisEventsPost200Response>;

    /**
     * 
     * @summary Get all events
     * @param {string} patientId 
     * @param {number} [start] start timestamp
     * @param {number} [stop] stop timestamp
     * @param {V1PatientPatientIdEventsGetOrderByEnum} [orderBy] The field to order the results by
     * @param {V1PatientPatientIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
     * @param {Array<string>} [patientName] List of patient names to filter by
     * @param {Array<string>} [physicianName] List of physician names to filter by
     * @param {Array<string>} [eventType] List of event types to filter by
     * @param {Array<string>} [createdByName] List of names of the creators to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientIdEventsGet(patientId: string, start?: number, stop?: number, orderBy?: V1PatientPatientIdEventsGetOrderByEnum, orderDirection?: V1PatientPatientIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdEventsGet200Response>;

    /**
     * 
     * @summary Creates a new for the specified patient
     * @param {number} patientId 
     * @param {V1PatientPatientIdEventsPostRequest} [v1PatientPatientIdEventsPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientIdEventsPost(patientId: number, v1PatientPatientIdEventsPostRequest?: V1PatientPatientIdEventsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdEventsPost200Response>;

    /**
     * 
     * @summary Ends a patient\'s monitoring period, now.
     * @param {number} patientid 
     * @param {string} [monitoringEndReason] Reason why a patient is ending their use of our monitoring services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidEndmonitoringPut(patientid: number, monitoringEndReason?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Retrieves a patient\'s record
     * @param {number} patientid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidGet(patientid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientIdDialysisEventsPost200Response>;

    /**
     * Add a hub to the specified patient\'s record.
     * @summary Adds a hub to a patient\'s record.
     * @param {number} patientid The ID of the patient to whom the hub will be added.
     * @param {V1PatientPatientidHubPostRequest} hubBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidHubPost(patientid: number, hubBody: V1PatientPatientidHubPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Add a patch to the specified patient\'s record.
     * @summary Adds a patch to a patient\'s record.
     * @param {number} patientid The ID of the patient to whom the patch will be added.
     * @param {V1PatientPatientidPatchPostRequest} patchBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidPatchPost(patientid: number, patchBody: V1PatientPatientidPatchPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates an existing patient
     * @param {number} patientid 
     * @param {V1PatientPatientidPutRequest} updatepatient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidPut(patientid: number, updatepatient: V1PatientPatientidPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Add a replacement-kit to the specified patient\'s record.
     * @summary Adds a replacement-kit to a patient\'s record.
     * @param {number} patientid The ID of the patient to whom the replacement-kit will be added.
     * @param {V1PatientPatientidReplacementKitPostRequest} replacementKitBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidReplacementKitPost(patientid: number, replacementKitBody: V1PatientPatientidReplacementKitPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get details of a specific patient session.
     * @param {number} patientid 
     * @param {string} sessionid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidSessionSessionidGet(patientid: number, sessionid: string, options?: RawAxiosRequestConfig): AxiosPromise<PatientSession>;

    /**
     * 
     * @summary Updates a patient\'s session
     * @param {number} patientid 
     * @param {number} sessionid 
     * @param {object} updatesession 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidSessionSessionidPut(patientid: number, sessionid: number, updatesession: object, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientidSessionSessionidPut200Response>;

    /**
     * 
     * @summary Lists all of a patient\'s sessions.
     * @param {number} patientid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidSessionsGet(patientid: number, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientPatientidSessionsGet200Response>;

    /**
     * Add a starter-kit to the specified patient\'s record.
     * @summary Adds a starter-kit to a patient\'s record.
     * @param {number} patientid The ID of the patient to whom the starter-kit will be added.
     * @param {V1PatientPatientidStarterKitPostRequest} starterKitBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidStarterKitPost(patientid: number, starterKitBody: V1PatientPatientidStarterKitPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * <br/>This is separate from standard patient updating, as it should be a rare operation, not<br/>performed in conjunction with other patient updates, that may require expensive/slow<br/>checks. Also, it has a \"reset\" mechanism, which doesn\'t make any sense for other fields.<br/>
     * @summary Sets/resets a patient\'s subject ID, for attaching them to a different backend source.
     * @param {number} patientid 
     * @param {V1PatientPatientidSubjectidPutRequest} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidSubjectidPut(patientid: number, type?: V1PatientPatientidSubjectidPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * This expects that you\'re only providing one of kitgsn, patchgsn, patchboxgsn or hubgsn<br/>at any given time.<br/>
     * @summary Adds supplies to a patient\'s record.
     * @param {number} patientid 
     * @param {V1PatientPatientidSuppliesPostRequest} supplies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidSuppliesPost(patientid: number, supplies: V1PatientPatientidSuppliesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Logs the current user as having seen the patient\'s record.
     * @param {number} patientid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPatientidViewedPost(patientid: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/><br/>Some other fields are ignored/replaced by the backend, like \"added\", \"addedby\"<br/>and \"status\".<br/>
     * @summary Creates an entirely new patient.
     * @param {Patient} createpatient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientPost(createpatient: Patient, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Bulk upload of patients
     * @param {File} [file] CSV file of patients to create
     * @param {number} [clinic] Name of the clinic into which the patients will be imported
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientsBulkPost(file?: File, clinic?: number, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientsBulkPost200Response>;

    /**
     * 
     * @summary Enumerates the patients you have access to
     * @param {number} [limit] Number of records to return,
     * @param {number} [skip] Number of records to skip over.
     * @param {string} [sort] Comma separated list of field names to sort by
     * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
     * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
     * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
     * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientsGet(limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options?: RawAxiosRequestConfig): AxiosPromise<V1PatientsGet200Response>;

    /**
     * 
     * @summary Remove hub from patient
     * @param {string} patientId 
     * @param {string} hubId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientsPatientIdHubsHubIdDelete(patientId: string, hubId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Remove patch from patient
     * @param {string} patientId 
     * @param {string} patchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PatientsPatientIdPatchesPatchIdDelete(patientId: string, patchId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
     * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
     * @param {V1ClinicadminPostRequest} createsupportuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1PhisupportuserPost(createsupportuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Used to connect to rabbitmq and receive live data<br/>
     * @summary Creates temporary credentials in rabbitmq which expire in a week
     * @param {string} patchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1RabbitmqUsersPost(patchId: string, options?: RawAxiosRequestConfig): AxiosPromise<V1RabbitmqUsersPost200Response>;

    /**
     * 
     * @summary Deletes rabbitmq user to cleanup after use
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1RabbitmqUsersUsernameDelete(username: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns a CSV file containing the clinic report for a specific clinic identified by clinic_id.
     * @summary Get clinic report for a specific clinic
     * @param {number} clinicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ReportClinicsClinicIdGet(clinicId: number, options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * Returns a CSV file containing the clinic report for all clinics.
     * @summary Get clinic report for all clinics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ReportClinicsGet(options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * Returns a CSV file containing the patient report for a specific clinic identified by clinic_id.
     * @summary Get patient report for a specific clinic
     * @param {number} clinicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ReportPatientsClinicIdGet(clinicId: number, options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * Returns a CSV file containing the patient report for all clinics.
     * @summary Get patient report for all clinics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1ReportPatientsGet(options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @summary Creates a batch of patient sessions.
     * @param {V1SessionPostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1SessionPost(body: V1SessionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V1SessionPost200Response>;

    /**
     * 
     * @summary Takes a set of instructions on how to perform one or many splits.
     * @param {V1SessionSplitPostRequest} splits 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1SessionSplitPost(splits: V1SessionSplitPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Provides configuration information for the operation of the support portal frontend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1SupportinfoGet(options?: RawAxiosRequestConfig): AxiosPromise<object>;

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
     * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
     * @param {V1ClinicadminPostRequest} createsupportuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1SupportuserPost(createsupportuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * <br/>Only available to site admins and customer support users. Will fill out the<br/>groups property on the user records.<br/>
     * @summary Enumerates all of the admin / customer support users in the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1SupportusersGet(options?: RawAxiosRequestConfig): AxiosPromise<V1SupportusersGet200Response>;

    /**
     * 
     * @summary Provides a bit of user-readable information about the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1SysteminfoGet(options?: RawAxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @summary Returns the user\'s user ID, from the session information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    v1WhoamiGet(options?: RawAxiosRequestConfig): AxiosPromise<V1WhoamiGet200Response>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * List clinician groups
     * @param {string} clinicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicIdClinicianGroupsGet(clinicId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicIdClinicianGroupsGet(clinicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all events
     * @param {string} clinicId 
     * @param {number} [start] start timestamp
     * @param {number} [stop] stop timestamp
     * @param {V1ClinicClinicIdEventsGetOrderByEnum} [orderBy] The field to order the results by
     * @param {V1ClinicClinicIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
     * @param {Array<string>} [patientName] List of patient names to filter by
     * @param {Array<string>} [physicianName] List of physician names to filter by
     * @param {Array<string>} [eventType] List of event types to filter by
     * @param {Array<string>} [createdByName] List of names of the creators to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicIdEventsGet(clinicId: string, start?: number, stop?: number, orderBy?: V1ClinicClinicIdEventsGetOrderByEnum, orderDirection?: V1ClinicClinicIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicIdEventsGet(clinicId, start, stop, orderBy, orderDirection, patientName, physicianName, eventType, createdByName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clinicId 
     * @param {string} [name] A search string to filter patients by. Currently only searches by patient name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicIdPatientsSearchGet(clinicId: string, name?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicIdPatientsSearchGet(clinicId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a clinic\'s record
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidGet(clinicid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidGet(clinicid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enumerates the patients you have access to from a specific clinic.
     * @param {number} clinicid 
     * @param {number} [limit] Number of records to return,
     * @param {number} [skip] Number of records to skip over.
     * @param {string} [sort] Comma separated list of field names to sort by
     * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
     * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
     * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
     * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidPatientsGet(clinicid: number, limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidPatientsGet(clinicid, limit, skip, sort, dir, expectedmaxpatientid, search, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * which are completely omitted are not altered.<br/>
     * @summary Updates an existing clinic. For convenience, fields
     * @param {number} clinicid 
     * @param {V1ClinicClinicidPutRequest} updateclinic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidPut(clinicid: number, updateclinic: V1ClinicClinicidPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidPut(clinicid, updateclinic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new session type for a clinic.
     * @param {number} clinicid 
     * @param {V1ClinicClinicidSessionPostRequest} createsessiontype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidSessionPost(clinicid: number, createsessiontype: V1ClinicClinicidSessionPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidSessionPost(clinicid, createsessiontype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing session type
     * @param {number} clinicid 
     * @param {number} sessiontypeid 
     * @param {V1ClinicClinicidSessionPostRequest} updatesessiontype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidSessionSessiontypeidPut(clinicid: number, sessiontypeid: number, updatesessiontype: V1ClinicClinicidSessionPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidSessionSessiontypeidPut(clinicid, sessiontypeid, updatesessiontype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enumerates the list of session types for a clinic.
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidSessiontypesGet(clinicid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidSessiontypesGet(clinicid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <br/>Note, currently it will simply return empty statistics if you request them for a clinic<br/>which doesn\'t exist, or which you don\'t have permission to see. Similarly, a clinic<br/>without patients will have zeros across the board, rather than any kind of indication.<br/>
     * @summary Retrieve detailed statistics about a clinic that make take a \"long\" time to compute.
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidStatsGet(clinicid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidStatsGet(clinicid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a clinic user from a clinic.
     * @param {number} clinicid 
     * @param {number} clinicuserid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidUserClinicuseridDelete(clinicid: number, clinicuserid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidUserClinicuseridDelete(clinicid, clinicuserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enumerates the clinic users you have access to from a specific clinic
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidUsersGet(clinicid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidUsersGet(clinicid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs the current user as having seen the clinic\'s patient list.
     * @param {number} clinicid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicClinicidViewedPost(clinicid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicClinicidViewedPost(clinicid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/>
     * @summary Creates an entirely new clinic.
     * @param {V1ClinicPostRequest} [createclinic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicPost(createclinic?: V1ClinicPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicPost(createclinic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
     * @summary Creates an entirely new clinic admin user
     * @param {V1ClinicadminPostRequest} createclinicadmin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicadminPost(createclinicadmin: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicadminPost(createclinicadmin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicianGroupGroupIdDelete(groupId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicianGroupGroupIdDelete(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicianGroupGroupIdGet(groupId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicianGroupGroupIdGet(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupId 
     * @param {V1ClinicianGroupGroupIdPutRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicianGroupGroupIdPut(groupId: number, body: V1ClinicianGroupGroupIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicianGroupGroupIdPut(groupId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {V1ClinicianGroupPostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicianGroupPost(body: V1ClinicianGroupPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicianGroupPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enumerates the subset of clinics you have access to.
     * @param {string} [search] A search string to filter clinics by. Currently only searches by patient name.
     * @param {string} [include] Pass in \&#39;test\&#39; to include test patients in the list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicsGet(search?: string, include?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicsGet(search, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a clinic user (clinician)\'s record
     * @param {number} clinicuserid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicuserClinicuseridGet(clinicuserid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicuserClinicuseridGet(clinicuserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing clinic user
     * @param {number} clinicuserid 
     * @param {V1ClinicadminPostRequest} updateclinicuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicuserClinicuseridPut(clinicuserid: number, updateclinicuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicuserClinicuseridPut(clinicuserid, updateclinicuser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the password reset flow for a user.
     * @param {number} clinicuserid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicuserClinicuseridResetpasswordPost(clinicuserid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicuserClinicuseridResetpasswordPost(clinicuserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts the password reset flow for the given user.
     * @param {V1ClinicuserForgotPasswordPostRequest} [v1ClinicuserForgotPasswordPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicuserForgotPasswordPost(v1ClinicuserForgotPasswordPostRequest?: V1ClinicuserForgotPasswordPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicuserForgotPasswordPost(v1ClinicuserForgotPasswordPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/>
     * @summary Creates an entirely new clinic user
     * @param {V1ClinicadminPostRequest} createclinicuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicuserPost(createclinicuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicuserPost(createclinicuser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accepts privacy policy on behalf of signed-in user
     * @param {V1ClinicuserSelfAcceptprivacypolicyPutRequest} acceptance 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicuserSelfAcceptprivacypolicyPut(acceptance: V1ClinicuserSelfAcceptprivacypolicyPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicuserSelfAcceptprivacypolicyPut(acceptance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Performs any actions that should occur when the user logs in.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicuserSelfLoginPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicuserSelfLoginPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enumerates the clinic users you have access to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ClinicusersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ClinicusersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a list of countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1CountriesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1CountriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches details of a single patient sensor data record
     * @param {number} patientid 
     * @param {string} datatype Single data type to look up, \&quot;potassium\&quot;, \&quot;temperature\&quot;, \&quot;hr\&quot;, \&quot;audio\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hrvraw\&quot;
     * @param {number} timestamp Time stamp of the record to find
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1DataPatientidDatatypeTimestampGet(patientid: number, datatype: string, timestamp: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1DataPatientidDatatypeTimestampGet(patientid, datatype, timestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches patient sensor data.
     * @param {number} patientid 
     * @param {string} datatypes Comma-separated list of data types to fetch. \&quot;temperature\&quot;, \&quot;potassium\&quot;, \&quot;hr\&quot;, \&quot;spo2\&quot;, \&quot;hct\&quot;, \&quot;hgb\&quot;, \&quot;hrvraw\&quot;, and \&quot;audio\&quot; are the options.
     * @param {number} [start] Epoch time of the start of the time range to limit the query to.
     * @param {number} [stop] Epoch time of the end of the time range to limit the query to.
     * @param {string} [session] A patient session to fetch data for
     * @param {boolean} [streaming] Default false, determines where to query data from
     * @param {string} [patchId] Patch we use to search in mongo when in streaming mode
     * @param {boolean} [presign] Default false, determines whether to return presigned urls or
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1DataPatientidDatatypesGet(patientid: number, datatypes: string, start?: number, stop?: number, session?: string, streaming?: boolean, patchId?: string, presign?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1DataPatientidDatatypesGet(patientid, datatypes, start, stop, session, streaming, patchId, presign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch the total extent of patient\'s data.
     * @param {number} patientid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1DataPatientidExtentsGet(patientid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1DataPatientidExtentsGet(patientid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches summarized heartrate data
     * @param {number} patientid Patient ID
     * @param {V1DataSummaryPatientidHrPostRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1DataSummaryPatientidHrPost(patientid: number, body?: V1DataSummaryPatientidHrPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1DataSummaryPatientidHrPost(patientid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Moves kits into this environment
     * @param {string} [kit] Kit ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1DeviceKitsMovePost(kit?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1DeviceKitsMovePost(kit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for devices.
     * @param {DeviceSearch} [deviceSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1DeviceSearchPost(deviceSearch?: DeviceSearch, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1DeviceSearchPost(deviceSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an event
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1EventsDelete(eventId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1EventsDelete(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get hub data including hologram data
     * @param {string} hubId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1HubsHubIdGet(hubId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1HubsHubIdGet(hubId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest logs for a patch
     * @param {string} hubId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1HubsHubIdPatchSyncLogLatestGet(hubId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1HubsHubIdPatchSyncLogLatestGet(hubId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger a notification directly
     * @param {Notification} [notification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1NotificationsPost(notification?: Notification, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1NotificationsPost(notification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a supplies order record
     * @param {string} orderid Order UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1OrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1OrderOrderidGet(orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a supplies order record
     * @param {Patient} createorder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1OrderPost(createorder: Patient, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1OrderPost(createorder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <br/>Omitting the patient and clinic ID will search for<br/>orders which are not attached to a patient or clinic,<br/>unless the all flag is set.<br/>
     * @summary Lists orders
     * @param {number} [patientid] Patient ID to search for
     * @param {number} [clinicid] Clinic ID to search for
     * @param {string} [orderid] Order ID to fetch
     * @param {boolean} [all] Specifically pulls all orders. May cause problems.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1OrdersGet(patientid?: number, clinicid?: number, orderid?: string, all?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1OrdersGet(patientid, clinicid, orderid, all, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information from patch(es)
     * @param {Array<string>} patchIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatchesPatchIdsGet(patchIds: Array<string>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatchesPatchIdsGet(patchIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a list of patch modes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatchmodesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatchmodesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Grabs all dialysis events
     * @param {number} patientId 
     * @param {number} [start] start timestamp
     * @param {number} [stop] stop timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientIdDialysisEventsGet(patientId: number, start?: number, stop?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientIdDialysisEventsGet(patientId, start, stop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new clinical_action associated with the specified patient
     * @param {number} patientId 
     * @param {V1PatientPatientIdDialysisEventsPostRequest} clinicalActionFeedback 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientIdDialysisEventsPost(patientId: number, clinicalActionFeedback: V1PatientPatientIdDialysisEventsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientIdDialysisEventsPost(patientId, clinicalActionFeedback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all events
     * @param {string} patientId 
     * @param {number} [start] start timestamp
     * @param {number} [stop] stop timestamp
     * @param {V1PatientPatientIdEventsGetOrderByEnum} [orderBy] The field to order the results by
     * @param {V1PatientPatientIdEventsGetOrderDirectionEnum} [orderDirection] The direction to order the results
     * @param {Array<string>} [patientName] List of patient names to filter by
     * @param {Array<string>} [physicianName] List of physician names to filter by
     * @param {Array<string>} [eventType] List of event types to filter by
     * @param {Array<string>} [createdByName] List of names of the creators to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientIdEventsGet(patientId: string, start?: number, stop?: number, orderBy?: V1PatientPatientIdEventsGetOrderByEnum, orderDirection?: V1PatientPatientIdEventsGetOrderDirectionEnum, patientName?: Array<string>, physicianName?: Array<string>, eventType?: Array<string>, createdByName?: Array<string>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientIdEventsGet(patientId, start, stop, orderBy, orderDirection, patientName, physicianName, eventType, createdByName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new for the specified patient
     * @param {number} patientId 
     * @param {V1PatientPatientIdEventsPostRequest} [v1PatientPatientIdEventsPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientIdEventsPost(patientId: number, v1PatientPatientIdEventsPostRequest?: V1PatientPatientIdEventsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientIdEventsPost(patientId, v1PatientPatientIdEventsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ends a patient\'s monitoring period, now.
     * @param {number} patientid 
     * @param {string} [monitoringEndReason] Reason why a patient is ending their use of our monitoring services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidEndmonitoringPut(patientid: number, monitoringEndReason?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidEndmonitoringPut(patientid, monitoringEndReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a patient\'s record
     * @param {number} patientid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidGet(patientid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidGet(patientid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a hub to the specified patient\'s record.
     * @summary Adds a hub to a patient\'s record.
     * @param {number} patientid The ID of the patient to whom the hub will be added.
     * @param {V1PatientPatientidHubPostRequest} hubBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidHubPost(patientid: number, hubBody: V1PatientPatientidHubPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidHubPost(patientid, hubBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a patch to the specified patient\'s record.
     * @summary Adds a patch to a patient\'s record.
     * @param {number} patientid The ID of the patient to whom the patch will be added.
     * @param {V1PatientPatientidPatchPostRequest} patchBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidPatchPost(patientid: number, patchBody: V1PatientPatientidPatchPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidPatchPost(patientid, patchBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing patient
     * @param {number} patientid 
     * @param {V1PatientPatientidPutRequest} updatepatient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidPut(patientid: number, updatepatient: V1PatientPatientidPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidPut(patientid, updatepatient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a replacement-kit to the specified patient\'s record.
     * @summary Adds a replacement-kit to a patient\'s record.
     * @param {number} patientid The ID of the patient to whom the replacement-kit will be added.
     * @param {V1PatientPatientidReplacementKitPostRequest} replacementKitBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidReplacementKitPost(patientid: number, replacementKitBody: V1PatientPatientidReplacementKitPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidReplacementKitPost(patientid, replacementKitBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details of a specific patient session.
     * @param {number} patientid 
     * @param {string} sessionid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidSessionSessionidGet(patientid: number, sessionid: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidSessionSessionidGet(patientid, sessionid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a patient\'s session
     * @param {number} patientid 
     * @param {number} sessionid 
     * @param {object} updatesession 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidSessionSessionidPut(patientid: number, sessionid: number, updatesession: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidSessionSessionidPut(patientid, sessionid, updatesession, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists all of a patient\'s sessions.
     * @param {number} patientid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidSessionsGet(patientid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidSessionsGet(patientid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a starter-kit to the specified patient\'s record.
     * @summary Adds a starter-kit to a patient\'s record.
     * @param {number} patientid The ID of the patient to whom the starter-kit will be added.
     * @param {V1PatientPatientidStarterKitPostRequest} starterKitBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidStarterKitPost(patientid: number, starterKitBody: V1PatientPatientidStarterKitPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidStarterKitPost(patientid, starterKitBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <br/>This is separate from standard patient updating, as it should be a rare operation, not<br/>performed in conjunction with other patient updates, that may require expensive/slow<br/>checks. Also, it has a \"reset\" mechanism, which doesn\'t make any sense for other fields.<br/>
     * @summary Sets/resets a patient\'s subject ID, for attaching them to a different backend source.
     * @param {number} patientid 
     * @param {V1PatientPatientidSubjectidPutRequest} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidSubjectidPut(patientid: number, type?: V1PatientPatientidSubjectidPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidSubjectidPut(patientid, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This expects that you\'re only providing one of kitgsn, patchgsn, patchboxgsn or hubgsn<br/>at any given time.<br/>
     * @summary Adds supplies to a patient\'s record.
     * @param {number} patientid 
     * @param {V1PatientPatientidSuppliesPostRequest} supplies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidSuppliesPost(patientid: number, supplies: V1PatientPatientidSuppliesPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidSuppliesPost(patientid, supplies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs the current user as having seen the patient\'s record.
     * @param {number} patientid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPatientidViewedPost(patientid: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPatientidViewedPost(patientid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the clinic is successfully created, but the client never receives<br/>the response. In the event of an error, check for existance before continuing.<br/><br/>Some other fields are ignored/replaced by the backend, like \"added\", \"addedby\"<br/>and \"status\".<br/>
     * @summary Creates an entirely new patient.
     * @param {Patient} createpatient 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientPost(createpatient: Patient, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientPost(createpatient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Bulk upload of patients
     * @param {File} [file] CSV file of patients to create
     * @param {number} [clinic] Name of the clinic into which the patients will be imported
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientsBulkPost(file?: File, clinic?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientsBulkPost(file, clinic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enumerates the patients you have access to
     * @param {number} [limit] Number of records to return,
     * @param {number} [skip] Number of records to skip over.
     * @param {string} [sort] Comma separated list of field names to sort by
     * @param {string} [dir] Comma separated list of ASC or DESC, corresponding to the fields in sort. If dir is shorter than sort, the last direction will be used for all remaining fields.
     * @param {number} [expectedmaxpatientid] The largest expected patient ID, so that we can abort some of the expensive work if a patient has been added mid-listing.
     * @param {string} [search] A search string to filter patients by. Currently only searches by patient name.
     * @param {string} [include] Pass in \&#39;completed\&#39; to include completed patients in the list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientsGet(limit?: number, skip?: number, sort?: string, dir?: string, expectedmaxpatientid?: number, search?: string, include?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientsGet(limit, skip, sort, dir, expectedmaxpatientid, search, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove hub from patient
     * @param {string} patientId 
     * @param {string} hubId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientsPatientIdHubsHubIdDelete(patientId: string, hubId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientsPatientIdHubsHubIdDelete(patientId, hubId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove patch from patient
     * @param {string} patientId 
     * @param {string} patchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PatientsPatientIdPatchesPatchIdDelete(patientId: string, patchId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PatientsPatientIdPatchesPatchIdDelete(patientId, patchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
     * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
     * @param {V1ClinicadminPostRequest} createsupportuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1PhisupportuserPost(createsupportuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1PhisupportuserPost(createsupportuser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to connect to rabbitmq and receive live data<br/>
     * @summary Creates temporary credentials in rabbitmq which expire in a week
     * @param {string} patchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1RabbitmqUsersPost(patchId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1RabbitmqUsersPost(patchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes rabbitmq user to cleanup after use
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1RabbitmqUsersUsernameDelete(username: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1RabbitmqUsersUsernameDelete(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a CSV file containing the clinic report for a specific clinic identified by clinic_id.
     * @summary Get clinic report for a specific clinic
     * @param {number} clinicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ReportClinicsClinicIdGet(clinicId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ReportClinicsClinicIdGet(clinicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a CSV file containing the clinic report for all clinics.
     * @summary Get clinic report for all clinics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ReportClinicsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ReportClinicsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a CSV file containing the patient report for a specific clinic identified by clinic_id.
     * @summary Get patient report for a specific clinic
     * @param {number} clinicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ReportPatientsClinicIdGet(clinicId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ReportPatientsClinicIdGet(clinicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a CSV file containing the patient report for all clinics.
     * @summary Get patient report for all clinics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1ReportPatientsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1ReportPatientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a batch of patient sessions.
     * @param {V1SessionPostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1SessionPost(body: V1SessionPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1SessionPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Takes a set of instructions on how to perform one or many splits.
     * @param {V1SessionSplitPostRequest} splits 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1SessionSplitPost(splits: V1SessionSplitPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1SessionSplitPost(splits, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provides configuration information for the operation of the support portal frontend.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1SupportinfoGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1SupportinfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The id in the input is ignored, the assigned id will be returned in the output.<br/>It is possible the support user is successfully created, but the client never receives<br/>the response. In the event of an error, check for existence before continuing.<br/><br/>You can only create support users in clinics attached to the \"default\" site / user pool.<br/>
     * @summary Creates an entirely new (PHI-capable, depending on endpoint) support user
     * @param {V1ClinicadminPostRequest} createsupportuser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1SupportuserPost(createsupportuser: V1ClinicadminPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1SupportuserPost(createsupportuser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <br/>Only available to site admins and customer support users. Will fill out the<br/>groups property on the user records.<br/>
     * @summary Enumerates all of the admin / customer support users in the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1SupportusersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1SupportusersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provides a bit of user-readable information about the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1SysteminfoGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1SysteminfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the user\'s user ID, from the session information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public v1WhoamiGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).v1WhoamiGet(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const V1ClinicClinicIdEventsGetOrderByEnum = {
    Timestamp: 'timestamp',
    Name: 'name',
    Physician: 'physician',
    Type: 'type',
    CreatedBy: 'created_by'
} as const;
export type V1ClinicClinicIdEventsGetOrderByEnum = typeof V1ClinicClinicIdEventsGetOrderByEnum[keyof typeof V1ClinicClinicIdEventsGetOrderByEnum];
/**
 * @export
 */
export const V1ClinicClinicIdEventsGetOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type V1ClinicClinicIdEventsGetOrderDirectionEnum = typeof V1ClinicClinicIdEventsGetOrderDirectionEnum[keyof typeof V1ClinicClinicIdEventsGetOrderDirectionEnum];
/**
 * @export
 */
export const V1PatientPatientIdEventsGetOrderByEnum = {
    Timestamp: 'timestamp',
    Name: 'name',
    Physician: 'physician',
    Type: 'type',
    CreatedBy: 'created_by'
} as const;
export type V1PatientPatientIdEventsGetOrderByEnum = typeof V1PatientPatientIdEventsGetOrderByEnum[keyof typeof V1PatientPatientIdEventsGetOrderByEnum];
/**
 * @export
 */
export const V1PatientPatientIdEventsGetOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type V1PatientPatientIdEventsGetOrderDirectionEnum = typeof V1PatientPatientIdEventsGetOrderDirectionEnum[keyof typeof V1PatientPatientIdEventsGetOrderDirectionEnum];


