<script lang="ts">
import { between, required, requiredIf } from "@vuelidate/validators";
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "MetricHeightWeightSelect",
  props: {
    height: {
      type: Number,
      default: null,
    },
    weight: {
      type: Number,
      default: null,
    },
    requireFields: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      height: {
        requiredIf: requiredIf(function () {
          return this.requireFields;
        }),
        between: between(1.2192, 2.44),
      },
      weight: {
        requiredIf: requiredIf(function () {
          return this.requireFields;
        }),
        between: between(27.2, 272.2),
      },
    };
  },
  computed: {
    validationMessage() {
      if (this.v$.height.$invalid && this.v$.weight.$invalid) {
        return this.$t("validation.metricBothInvalid");
      } else if (this.v$.height.$invalid) {
        return this.$t("validation.metricHeightRange");
      } else {
        return this.$t("validation.metricWeightRange");
      }
    },
  },
  mounted() {
    this.v$.height.$touch();
    this.v$.weight.$touch();
  },
  methods: {
    updateWeight(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.v$.weight.$touch();
        if (newVal) {
          this.$emit("updateWeight", Number.parseFloat(newVal));
        } else {
          this.$emit("updateWeight", null);
        }
      }
    },
    updateHeight(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.v$.height.$touch();
        if (newVal) {
          this.$emit("updateHeight", Number.parseFloat(newVal));
        } else {
          this.$emit("updateHeight", null);
        }
      }
    },
  },
});
</script>

<template>
  <div>
    <b-form-row no-gutters>
      <b-col sm="7">
        <label>{{ $t("patient.height") }}</label>
      </b-col>
      <b-col sm="5">
        <label>{{ $t("patient.weight") }}</label>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="6">
        <b-row class="height-row" no-gutters>
          <b-col>
            <b-input-group>
              <b-form-input
                type="number"
                class="form-input"
                :state="
                  v$.height.$anyDirty && v$.height.$invalid ? false : null
                "
                :value="height"
                step="any"
                :placeholder="$t('height.meters')"
                tabindex="0"
                @update="updateHeight"
              />
              <b-input-group-append>
                <span class="input-group-text">{{ $t("height.m") }}</span>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="6">
        <b-row class="weight-row">
          <b-col>
            <b-input-group>
              <b-form-input
                type="number"
                class="form-input"
                step="any"
                :state="
                  v$.weight.$anyDirty && v$.weight.$invalid ? false : null
                "
                :value="weight"
                :placeholder="$t('weight.kg')"
                tabindex="0"
                @update="updateWeight"
              />
              <b-input-group-append>
                <span class="input-group-text">{{ $t("weight.kg") }}</span>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
    <b-form-row>
      <div
        v-if="v$.$anyDirty && v$.$invalid"
        style="display: block !important"
        class="invalid-feedback"
      >
        {{ validationMessage }}
      </div>
    </b-form-row>
  </div>
</template>

<style scoped lang="scss">
.weight-row {
  padding-right: 15px;
}
.height-row {
  gap: 8px;
}
.form-select {
  padding-left: 0px !important;
}
.form-input {
  padding-left: 0px !important;
}
</style>
