<script lang="ts">
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { email, required, requiredIf } from "@vuelidate/validators";
import SkinToneSelect from "@/shared/components/Inputs/SkinToneSelect.vue";
import FormInput from "@/shared/components/Inputs/FormInput.vue";
import PatientAddresses from "@/shared/components/PatientAddresses.vue";
import { getChangedProperties } from "@/shared/utils";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2.js";
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";
import DialysisSchedule from "@/shared/components/DialysisSchedule.vue";
import TimeZonePicker from "@/shared/components/Inputs/TimeZonePicker.vue";
import ImperialHeightWeightSelect from "@/shared/components/Inputs/ImperialHeightWeightSelect.vue";
import MetricHeightWeightSelect from "@/shared/components/Inputs/MetricHeightWeightSelect.vue";
import DobPicker from "@/shared/components/DobPicker.vue";

const IMPERIAL = Symbol("imperial");
const METRIC = Symbol("metric");

export default defineComponent({
  name: "PatientForm",
  components: {
    DobPicker,
    TimeZonePicker,
    DialysisSchedule,
    SVGIconWrapper,
    PatientAddresses,
    FormInput,
    ImperialHeightWeightSelect,
    MetricHeightWeightSelect,
    SkinToneSelect,
    Multiselect,
  },
  props: {
    clinicId: {
      type: Number,
      default: null,
    },
    patientToEdit: {
      // If a patient exists it is a edit dialog
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data: function () {
    return {
      clinic: null,
      buttonsEnabled: true,
      limits: {
        dob: {
          startDate: new Date(1960, 0, 1),
          minDate: new Date(1800, 0, 1),
          maxDate: new Date(),
        },
      },
      selects: {
        clinics: [],
        patchPlacements: [],
        clinicians: [],
      },
      IMPERIAL: IMPERIAL,
      METRIC: METRIC,
      selectedUnit: IMPERIAL,
      availableUnits: [
        { text: "Imperial (ft/lbs)", value: IMPERIAL },
        { text: "Metric (m/kg)", value: METRIC },
      ],
      inputFields: {
        firstname: null,
        lastname: null,
        dob: null,
        email: null,
        clinicpatientidentifier: null,
        ordering_clinician: null,
        patchplacement: null,
        homephone: null,
        mobilephone: null,
        intl_timezone: null,
        skin: null,
        height: null,
        weight: null,
        addresses: [],
        dialysis_schedule: [
          { text: "Sunday", day_of_week: 0, time_of_day: null, active: false },
          { text: "Monday", day_of_week: 1, time_of_day: null, active: false },
          { text: "Tuesday", day_of_week: 2, time_of_day: null, active: false },
          {
            text: "Wednesday",
            day_of_week: 3,
            time_of_day: null,
            active: false,
          },
          {
            text: "Thursday",
            day_of_week: 4,
            time_of_day: null,
            active: false,
          },
          { text: "Friday", day_of_week: 5, time_of_day: null, active: false },
          {
            text: "Saturday",
            day_of_week: 6,
            time_of_day: null,
            active: false,
          },
        ],
      },
      notificationSelect: {
        mode: "tags",
        value: [],
        initialValue: [],
        closeOnSelect: false,
        options: [],
        searchable: true,
      },
    };
  },
  validations() {
    return {
      inputFields: {
        firstname: {
          required,
        },
        lastname: {
          required,
        },
        dob: {
          required,
          validDate: this.validateInputDate,
        },
        email: {
          email,
        },
        clinicpatientidentifier: {
          required,
        },
        ordering_clinician: {},
        patchplacement: {
          required,
        },
        mobilephone: {
          required,
        },
        homephone: {},
        height: {
          requiredIf: requiredIf(function () {
            return this.requireCalfFields;
          }),
        },
        weight: {
          requiredIf: requiredIf(function () {
            return this.requireCalfFields;
          }),
        },
        skin: {
          requiredIf: requiredIf(function () {
            return this.requireCalfFields;
          }),
        },
      },
    };
  },
  computed: {
    calfSupported() {
      return this.$store.getters?.features.SUPPORT_CALF;
    },
    requireCalfFields() {
      if (!this.inputFields.patchplacement) return false;
      return (
        this.clinic?.patchlocations.find(
          (p) => p.id === this.inputFields.patchplacement
        )?.location_type === "calf"
      );
    },
    isEditForm: function () {
      return this.patientToEdit ? true : false;
    },
    isNotifiedUsersDirty: function () {
      return (
        JSON.stringify(this.notificationSelect.value.sort()) !==
        JSON.stringify(this.notificationSelect.initialValue.sort())
      );
    },
    isAddEditEnabled() {
      const formInvalid = this.v$.$invalid;
      if (formInvalid) return false;

      if (this.patientToEdit) {
        let changedKeys = Object.keys(
          getChangedProperties(this.patientToEdit, this.inputFields)
        );

        return changedKeys.length > 0 || this.isNotifiedUsersDirty;
      }
      return true;
    },
  },
  mounted() {
    this.setDefaultUnitBasedOnLocale();
    this.getAllClinicInfo().then(() => {
      if (this.isEditForm) {
        this.inputFields.firstname = this.patientToEdit.firstname;
        this.inputFields.lastname = this.patientToEdit.lastname;
        this.inputFields.dob = this.patientToEdit.dob;
        this.inputFields.email = this.patientToEdit.email;
        this.inputFields.clinicpatientidentifier =
          this.patientToEdit.clinicpatientidentifier;
        this.inputFields.ordering_clinician =
          this.patientToEdit.ordering_clinician;
        this.inputFields.patchplacement = this.patientToEdit.patchplacement;
        this.inputFields.homephone = this.patientToEdit.homephone;
        this.inputFields.mobilephone = this.patientToEdit.mobilephone;
        this.inputFields.intl_timezone = this.patientToEdit.intl_timezone;
        this.inputFields.dialysis_schedule =
          this.patientToEdit.dialysis_schedule ||
          this.inputFields.dialysis_schedule;
        this.inputFields.addresses = this.patientToEdit.addresses
          ? [...this.patientToEdit.addresses]
          : [];
        this.inputFields.ordering_clinician =
          this.patientToEdit.ordering_clinician;

        this.inputFields.skin = this.patientToEdit.skin;
        this.inputFields.weight = this.patientToEdit.weight;
        this.inputFields.height = this.patientToEdit.height;

        this.notificationSelect.value = [].concat(
          this.patientToEdit.notify_clinicians.map((c) => `c${c.id}`),
          this.patientToEdit.notify_groups.map((c) => `g${c.id}`)
        );
        this.notificationSelect.initialValue = this.notificationSelect.value;

        this.v$.$touch();
      } else {
        this.inputFields.intl_timezone = this.getBrowserTimezone();
      }
    });
  },
  methods: {
    setDefaultUnitBasedOnLocale() {
      const browserLocale = navigator.language;
      this.selectedUnit = this.getUnitSystemForLocale(browserLocale);
    },
    getUnitSystemForLocale(locale) {
      // Default to metric for most cases
      const imperialCountries = ["US", "LR", "MM"]; // Corrected variable name
      const countryCode = locale.split("-")[1]; // Get country code from locale

      // If the country code is in our list of imperial countries, return 'imperial'
      if (imperialCountries.includes(countryCode)) {
        return IMPERIAL;
      }

      // Otherwise, return 'metric'
      return METRIC;
    },
    getBrowserTimezone() {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (err) {
        return null;
      }
    },
    updateSchedule(ns) {
      this.inputFields.dialysis_schedule = ns;
    },
    getFormattedTZ(tz) {
      if (!tz) return "";
      return `(GMT${this.getUTCOffset(tz)}) ${tz}`;
    },
    addAddress() {
      this.inputFields.addresses.push({
        description: null,
        street_city_state_address: null,
        postal_code: null,
        country_id: this.clinic?.country_id ? this.clinic?.country_id : null,
        active: !this.inputFields.addresses?.length,
      });
    },
    removeAddress(index) {
      this.inputFields.addresses.splice(index, 1);
    },
    updateAddress({ index, updatedAddress }) {
      this.$set(this.inputFields.addresses, index, updatedAddress);
    },
    updateActive({ index, active }) {
      // First, if the new state is active, set all addresses to inactive
      if (active) {
        this.inputFields.addresses.forEach((address, idx) => {
          this.$set(this.inputFields.addresses, idx, {
            ...address,
            active: false,
          });
        });
      }
      // Then, set the selected address to its new state (which may also be inactive)
      this.$set(this.inputFields.addresses, index, {
        ...this.inputFields.addresses[index],
        active: active,
      });
    },
    updateWeight(newWeight) {
      this.inputFields.weight = parseFloat(newWeight);
    },
    updateSkin(newSkin) {
      this.inputFields.skin = parseInt(newSkin);
    },
    updateHeight(newHeight) {
      this.inputFields.height = parseFloat(newHeight);
    },
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      const notify_clinicians = this.notificationSelect.value
        .filter((k) => k.startsWith("c"))
        .map((k) => Number(k.substring(1)));
      const notify_groups = this.notificationSelect.value
        .filter((k) => k.startsWith("g"))
        .map((k) => Number(k.substring(1)));

      let formPatient = {
        clinic: this.clinicId,
        firstname: this.inputFields.firstname,
        lastname: this.inputFields.lastname,
        dob: this.inputFields.dob,
        email: this.inputFields.email,
        clinicpatientidentifier: this.inputFields.clinicpatientidentifier,
        homephone: this.inputFields.homephone,
        mobilephone: this.inputFields.mobilephone,
        intl_timezone: this.inputFields.intl_timezone,
        dialysis_schedule: this.inputFields.dialysis_schedule.filter(
          (ds) => ds.active
        ),
        patchplacement: this.inputFields.patchplacement,
        ordering_clinician: parseInt(this.inputFields.ordering_clinician, 10),
        addresses: this.inputFields.addresses,
        notify_clinicians: notify_clinicians,
        notify_groups: notify_groups,
        skin: this.inputFields.skin,
        height: this.inputFields.height,
        weight: this.inputFields.weight,
      } as any;

      if (this.isAddEditEnabled) {
        this.buttonsEnabled = false;
        this.$emit("submit", {
          formPatient,
        });
      }
    },
    getUTCOffset(timezone) {
      const date = new Date();
      const utcDate = new Date(
        date.toLocaleString("en-US", { timeZone: "UTC" })
      );
      const tzDate = new Date(
        date.toLocaleString("en-US", { timeZone: timezone })
      );
      const offset = (tzDate - utcDate) / 60000;
      const hours = Math.abs(Math.floor(offset / 60));
      const minutes = Math.abs(offset % 60);
      return `${offset >= 0 ? "+" : "-"}${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    },
    getAllClinicInfo: function () {
      return Promise.all([this.getClinic(), this.getClinicians()]);
    },
    getClinic: async function () {
      this.selects.patchPlacements = [
        {
          value: null,
          text: this.$t("patient.selectPatchLocationsLoading"),
          disabled: true,
        },
      ];

      try {
        const response = await this.api.v1ClinicClinicidGet(this.clinicId);
        this.clinic = response.data.clinic;
        this.createPatchLocationsSelect(this.clinic.patchlocations);
        const notifOptions = [].concat(
          response.data.clinic?.notify_groups?.map((g) => ({
            value: `g${g.id}`,
            text: g.name,
            type: "group",
          })),
          response.data.clinic?.notify_clinicians.map((c) => ({
            value: `c${c.id}`,
            text: c.name,
            type: "clinician",
          }))
        );

        notifOptions.sort((a, b) => (a.text < b.text ? -1 : 1));
        this.notificationSelect.options = notifOptions;

        this.$store.commit("updateClinic", response.data.clinic);
      } catch (error) {
        // Create the patch locations with an empty array
        this.createPatchLocationsSelect([]);
        // Get and show the error
        this.showApiResponseError(error, this.$t("clinicalUser.errorLoadList"));
      }
    },
    getClinicians: async function () {
      this.selects.clinicians = [
        {
          value: null,
          text: this.$t("patient.selectCliniciansLoading"),
          disabled: true,
        },
      ];

      try {
        const response = await this.api.v1ClinicClinicidUsersGet(this.clinicId);
        this.createCliniciansSelect(response.data.clinicusers);
      } catch (error) {
        this.showApiResponseError(error, this.$t("clinicalUser.errorLoadList"));
      }
    },
    createPatchLocationsSelect: function (patchLocations) {
      let select = [];
      if (!patchLocations) {
        select.push({
          value: null,
          text: this.$t("patient.selectPatchLocationsNone"),
          disabled: true,
        });
      } else {
        select.push({
          value: null,
          text: this.$t("patient.selectPatchLocationsPlaceholder"),
          disabled: true,
        });
        patchLocations.forEach((element) => {
          if (element.active) {
            select.push({ value: element.id, text: element.locationname });
          } else if (
            this.patient &&
            this.patient.patchlocation === element.id
          ) {
            // Make sure to include the current element if is not active
            select.push({ value: element.id, text: element.locationname });
          }
        });
      }

      this.selects.patchPlacements = select;
    },
    createCliniciansSelect: function (clinicians) {
      const activeClinicians = clinicians.filter((clinician) => {
        return clinician.active;
      });
      // sort
      let select = [];
      if (!activeClinicians) {
        select.push({
          value: null,
          text: this.$t("patient.selectCliniciansNone"),
          disabled: true,
        });
      } else {
        // Sort the clinicians LastName then FirstName in ASC
        activeClinicians.sort((a, b) => {
          let compareValue = this.toString(a.lastname).localeCompare(
            this.toString(b.lastname)
          );
          if (compareValue === 0) {
            compareValue = this.toString(a.firstname).localeCompare(
              this.toString(b.firstname)
            );
          }

          return compareValue;
        });

        select.push({
          value: null,
          text: this.$t("patient.selectCliniciansPlaceholder"),
          disabled: true,
        });
        activeClinicians.forEach((clinician) => {
          select.push({
            value: clinician.id,
            text: this.formatClinicUserName(clinician),
          });
        });
      }

      this.selects.clinicians = select;
      // TODO
      // this.notificationSelect.options = select.map((c) => ({ type: 'clinician', ...c})).filter((c) => !c.disabled);
    },
    createClinicsSelect: function (clinics) {
      let select = [];
      if (!clinics) {
        select.push({
          value: null,
          text: this.$t("patient.selectClinicsNone"),
          disabled: true,
        });
      } else {
        select.push({
          value: null,
          text: this.$t("patient.selectClinicsPlaceholder"),
          disabled: true,
        });
        clinics.forEach((element) => {
          if (element.active) {
            select.push({ value: element.id, text: element.name });
          }
        });
      }

      this.selects.clinics = select;
    },
  },
});
</script>

<template>
  <b-form @submit.prevent="submit">
    <b-container class="form-container">
      <b-row class="form-section-header-row">
        <h3>{{ $t("patient.personalInformation") }}</h3>
      </b-row>
      <b-row>
        <b-col sm="6">
          <FormInput
            :model-value.sync="inputFields.firstname"
            :label="$t('patient.placeholderFirstName')"
            :validation-state="validateState('firstname')"
            :placeholder="$t('patient.placeholderFirstName')"
            input-id="patient-first-name"
            :maxlength="getInputMaxChar('first-name')"
            :invalid-feedback="$t('validations.requiredField')"
            @blur="v$.inputFields.firstname.$touch"
          />
        </b-col>
        <b-col sm="6">
          <FormInput
            :model-value.sync="inputFields.lastname"
            :label="$t('patient.placeholderLastName')"
            :validation-state="validateState('lastname')"
            :placeholder="$t('patient.placeholderLastName')"
            input-id="patient-last-name"
            :maxlength="getInputMaxChar('last-name')"
            :invalid-feedback="$t('validations.requiredField')"
            @blur="v$.inputFields.lastname.$touch"
          />
        </b-col>

        <b-col sm="6">
          <b-row class="dob-container">
            {{ $t("patient.placeholderDateOfBirth") }}
          </b-row>
          <b-row class="dob-container">
            <DobPicker
              :label="$t('patient.placeholderDateOfBirth')"
              :date="inputFields.dob"
              :placeholder="$t('patient.placeholderDateOfBirth')"
              @date-change="
                (dob) => {
                  inputFields.dob = dob;
                }
              "
            />
          </b-row>
        </b-col>
      </b-row>

      <b-row class="form-section-header-row">
        <h3>{{ $t("patient.addresses") }}</h3>
      </b-row>
      <PatientAddresses
        :addresses="inputFields.addresses"
        @remove-address="removeAddress"
        @update-address="updateAddress"
        @update-active="updateActive"
        @add-address="addAddress"
      />

      <b-row class="form-section-header-row">
        <h3>{{ $t("patient.contactInformation") }}</h3>
      </b-row>
      <b-row>
        <b-col sm="6">
          <FormInput
            :model-value.sync="inputFields.email"
            :label="$t('patient.placeholderEmail')"
            :validation-state="validateState('email')"
            :placeholder="$t('patient.placeholderEmail')"
            input-id="patient-email"
            :maxlength="getInputMaxChar('email')"
            :invalid-feedback="$t('validations.email')"
            @blur="v$.inputFields.email.$touch"
          />
        </b-col>
        <b-col sm="6">
          <FormInput
            :model-value.sync="inputFields.homephone"
            :label="$t('patient.placeholderHomePhone')"
            :placeholder="$t('patient.placeholderHomePhone')"
            input-id="patient-home-phone"
            :maxlength="getInputMaxChar('phone')"
          />
        </b-col>
        <b-col sm="6" class="tw-pt-1.5">
          <FormInput
            :model-value.sync="inputFields.mobilephone"
            :label="$t('patient.placeholderMobilePhone')"
            :validation-state="validateState('mobilephone')"
            :placeholder="$t('patient.placeholderMobilePhone')"
            input-id="patient-mobile-phone"
            :maxlength="getInputMaxChar('phone')"
            :invalid-feedback="$t('validations.requiredField')"
            @blur="v$.inputFields.mobilephone.$touch"
          />
        </b-col>
        <b-col sm="6">
          <TimeZonePicker v-model="inputFields.intl_timezone" />
        </b-col>
      </b-row>
      <b-row class="form-section-header-row">
        <h3>{{ $t("patient.medicalInformation") }}</h3>
      </b-row>
      <b-row>
        <b-col sm="6">
          <FormInput
            :model-value.sync="inputFields.clinicpatientidentifier"
            :validation-state="validateState('clinicpatientidentifier')"
            :placeholder="$t('patient.placeholderPatientId')"
            input-id="patient-clinic-patient-id"
            :maxlength="getInputMaxChar('clinic-patient-id')"
            :invalid-feedback="$t('validations.required')"
            @blur="v$.inputFields.clinicpatientidentifier.$touch"
          >
            <template #label>
              {{ $t("patient.placeholderPatientId") }}
              <span v-b-tooltip.hover :title="$t('patient.patientIdHelp')">
                <SVGIconWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="size-6 tw-mt-1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </SVGIconWrapper>
              </span>
            </template>
          </FormInput>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <div class="patient-notification-container">
              <span class="patient-notification-label"
                >Select Notification Recipients (Optional)</span
              >
              <Multiselect
                id="patient-notifications-select"
                v-model="notificationSelect.value"
                v-bind="notificationSelect"
              >
                <template #tag="{ option, disabled, handleTagRemove }">
                  <div
                    class="multiselect-tag"
                    :class="option.type == 'group' ? 'group' : 'clinician'"
                  >
                    {{ option.text }}
                    <span
                      v-if="!disabled"
                      class="multiselect-tag-remove"
                      @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>

                <template #option="{ option }">
                  <div
                    :data-testid="`notify:${option.text}`"
                    class="multiselect-option"
                  >
                    <div>{{ option.text }}</div>
                    <div v-if="option.type == 'group'" class="type-label group">
                      Group
                    </div>
                    <div v-else class="type-label clinician">Clinician</div>
                  </div>
                </template>
              </Multiselect>
              <br />
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <label for="patient-ordering-clinician">{{
            $t("patient.selectCliniciansPlaceholder")
          }}</label>
          <b-form-select
            id="patient-ordering-clinician"
            v-model="inputFields.ordering_clinician"
            class="form-select"
            :options="selects.clinicians"
            :class="[inputFields.ordering_clinician ? '' : 'select-text-muted']"
          >
          </b-form-select>
        </b-col>
        <b-col sm="6" :class="calfSupported ? 'calf-enabled-location' : ''">
          <b-form-group>
            <label for="patient-patch-placement">{{
              $t("patient.selectPatchLocationsPlaceholder")
            }}</label>
            <b-form-select
              id="patient-patch-placement"
              v-model="inputFields.patchplacement"
              class="form-select"
              :state="validateState('patchplacement')"
              :options="selects.patchPlacements"
              :class="[inputFields.patchplacement ? '' : 'select-text-muted']"
              aria-describedby="patient-patch-placement-feedback"
            />
            <b-form-invalid-feedback id="patient-patch-placement-feedback">
              {{ $t("validations.requiredField") }}
            </b-form-invalid-feedback>
            <b-form-text v-if="calfSupported" class="calf-patch-warning">
              {{ $t("patchLocation.warningMessage") }}
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <div class="height-weight-container">
            <b-form-row>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="selectedUnit"
                  :options="availableUnits"
                  :aria-describedby="ariaDescribedby"
                  name="radio-inline"
                ></b-form-radio-group>
              </b-form-group>
            </b-form-row>
            <ImperialHeightWeightSelect
              v-if="selectedUnit === IMPERIAL"
              :weight="inputFields.weight"
              :height="inputFields.height"
              :require-validation="requireCalfFields"
              @updateHeight="updateHeight"
              @updateWeight="updateWeight"
            />
            <MetricHeightWeightSelect
              v-else
              :weight="inputFields.weight"
              :height="inputFields.height"
              :require-fields="requireCalfFields"
              @updateHeight="updateHeight"
              @updateWeight="updateWeight"
            />
          </div>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <label>{{ $t("patient.skinTone") }}</label>
            <SkinToneSelect :skin="inputFields.skin" @input="updateSkin" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="form-section-header-row">
        <h3>{{ $t("patient.dialysisScheduleHeader") }}</h3>
      </b-row>
      <b-row>
        <b-col>
          <span>
            {{ $t("patient.dialysisScheduleSubheader") }}
            <i>{{ inputFields.intl_timezone }}</i>
          </span>
        </b-col>
      </b-row>
      <b-row class="tw-mt-2">
        <b-col>
          <DialysisSchedule
            :disabled="inputFields.intl_timezone === null"
            :schedule="inputFields.dialysis_schedule"
            @update:schedule="updateSchedule"
          />
        </b-col>
      </b-row>

      <b-row class="tw-mt-6 tw-mb-[-16px]">
        <b-col>
          <strong>{{ $t("patient.submitWarning") }}</strong>
        </b-col>
      </b-row>
      <div id="add-patient-buttons-container" class="buttons-container">
        <button
          id="add-patient-add-button"
          type="submit"
          :disabled="!isAddEditEnabled || !buttonsEnabled"
        >
          {{ isEditForm ? $t("common.buttonSave") : $t("common.buttonAdd") }}
        </button>
        <button
          id="add-patient-cancel-button"
          type="button"
          class="button-negative"
          :disabled="!buttonsEnabled"
          @click.prevent="cancel"
        >
          {{ $t("common.buttonCancel") }}
        </button>
      </div>
    </b-container>
  </b-form>
</template>

<style scoped lang="scss">
.form-section-header-row {
  padding-top: 24px;
  padding-left: 15px;
}

.form-container {
  min-width: 100%;
  /* Takes up full width on smaller screens */
  max-width: 800px;
  /* Limits width on larger screens */
  margin: 0 auto;
  /* Centers the form in the page */
}

.dob-container {
  width: 100%;
  padding-left: 15px;
  padding-top: 4px;
}

label {
  float: left;
}

.form-row {
  width: 100%;
}

#clinic-select-container {
  max-width: 363px;
  margin: auto;
}

.form-select {
  padding-left: 0px;
}
</style>
<style lang="scss">
.patient-notification-container {
  input {
    border: 0px;
  }

  .multiselect-option {
    display: flex;

    > div {
      flex-grow: 1;
    }

    > .type-label {
      flex-grow: 0;
      border-radius: 3px;
      padding: 2px 5px;
    }
  }

  .group {
    background: #826cab;
    color: white;
  }

  .clinician {
    background: #7f7f7f;
    color: white;
  }
}
.calf-enabled-location {
  background-color: $purple-lighter;
}
.calf-patch-warning {
  color: $purple-dark !important;
  font-size: 0.75rem;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
