<script lang="ts">
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { between, required, requiredIf } from "@vuelidate/validators";

export default defineComponent({
  name: "ImperialHeightWeightSelect",
  props: {
    height: {
      type: Number,
      default: null,
    },
    weight: {
      type: Number,
      default: null,
    },
    requireFields: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data: function () {
    return {
      isWeightFocused: false,
      isInchesFocused: false,
      isFeetFocused: false,
      feet: null,
      inches: null,
      pounds: null,
      feetOptions: [
        { text: this.$t("height.feet"), value: null, disabled: true },
        { text: "4'", value: 4 },
        { text: "5'", value: 5 },
        { text: "6'", value: 6 },
        { text: "7'", value: 7 },
        { text: "8'", value: 8 },
      ],
      inchOptions: [
        { text: this.$t("height.inches"), value: null, disabled: true },
        { text: '0"', value: 0 },
        { text: '1"', value: 1 },
        { text: '2"', value: 2 },
        { text: '3"', value: 3 },
        { text: '4"', value: 4 },
        { text: '5"', value: 5 },
        { text: '6"', value: 6 },
        { text: '7"', value: 7 },
        { text: '8"', value: 8 },
        { text: '9"', value: 9 },
        { text: '10"', value: 10 },
        { text: '11"', value: 11 },
      ],
    };
  },
  computed: {
    totalInches: {
      // height should be between 4 feet and 8 feet
      get() {
        if (this.feet !== null && this.inches !== null) {
          return this.feet * 12 + this.inches;
        } else if (this.feet === null && this.inches !== null) {
          return this.inches;
        } else if (this.feet !== null && this.inches === null) {
          return this.feet * 12;
        } else {
          return null;
        }
      },
    },
    validationMessage() {
      if (this.v$.totalInches.$invalid && this.v$.pounds.$invalid) {
        return this.$t("validation.imperialBothInvalid");
      } else if (this.v$.totalInches.$invalid) {
        return this.$t("validation.imperialHeightRange");
      } else {
        return this.$t("validation.imperialWeightRange");
      }
    },
  },
  validations() {
    return {
      pounds: {
        requiredIf: requiredIf(function () {
          return this.requireFields;
        }),
        between: between(60, 600),
      },
      totalInches: {
        requiredIf: requiredIf(function () {
          return this.requireFields;
        }),
        between: between(48, 96),
      },
      feet: {
        requiredIf: requiredIf(function () {
          return this.requireFields;
        }),
      },
      inches: {
        requiredIf: requiredIf(function () {
          return this.requireFields;
        }),
      },
    };
  },
  watch: {
    height() {
      this.convertHeightToFeetAndInches();
    },
    weight(newVal) {
      if (!this.isWeightFocused) {
        this.convertKGToPounds(newVal);
      }
    },
  },
  mounted() {
    this.convertKGToPounds(this.weight);
    this.convertHeightToFeetAndInches();
  },
  methods: {
    convertKGToPounds(kg) {
      if (kg !== null) {
        let pounds = kg * 2.20462262185;
        // Check if the difference between the rounded number and its integer part is less than 0.01
        if (Math.abs(pounds - Math.round(pounds)) < 0.01) {
          pounds = Math.round(pounds); // Round to nearest integer
        } else {
          pounds = parseFloat(pounds.toFixed(2)); // Round to two decimal places
        }
        this.pounds = pounds;
        this.$nextTick(() => {
          this.v$.pounds.$touch();
        });
      }
    },
    updateWeight(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          this.$emit("updateWeight", Number.parseFloat(newVal));
        } else {
          this.$emit("updateWeight", null);
        }
      }
    },
    updateHeight(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          this.$emit("updateHeight", Number.parseFloat(newVal));
        } else {
          this.$emit("updateHeight", null);
        }
      }
    },
    updatePounds(newVal, oldVal) {
      this.v$.pounds.$touch();
      if (newVal !== oldVal) {
        this.convertPoundsToMeters(newVal);
      }
    },
    updateFeet(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.feet = newVal;
        this.convertFeetAndInchesToMeters();
      }
    },
    updateInches(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.inches = newVal;
        this.convertFeetAndInchesToMeters();
      }
    },
    convertPoundsToMeters(pounds) {
      this.pounds = pounds;
      if (pounds !== null) {
        const kg = pounds * 0.45359237;
        this.$emit("updateWeight", kg.toFixed(5));
      }
    },
    convertHeightToFeetAndInches() {
      if (this.height !== null) {
        const heightInFeet = this.height * 3.28084; // Convert meters to feet
        this.feet = Math.floor(heightInFeet);
        this.inches = Math.round((heightInFeet - this.feet) * 12);
      }
    },
    convertFeetAndInchesToMeters() {
      const totalFeet = this.feet + this.inches / 12;
      const meters = totalFeet * 0.3048;
      this.$emit("updateHeight", meters.toFixed(4));
    },
  },
});
</script>

<template>
  <div>
    <b-form-row no-gutters>
      <b-col sm="7">
        <label>{{ $t("patient.height") }}</label>
      </b-col>
      <b-col sm="5">
        <label>{{ $t("patient.weight") }}</label>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="6">
        <b-row class="height-row" no-gutters>
          <b-col>
            <b-form-select
              :value="feet"
              :placeholder="$t('height.feet')"
              class="form-select"
              tabindex="0"
              :options="feetOptions"
              @change="updateFeet"
              @focus="() => (isFeetFocused = true)"
              @blur="() => (isFeetFocused = false)"
            />
          </b-col>
          <b-col>
            <b-form-select
              :value="inches"
              :placeholder="$t('height.inches')"
              class="form-select"
              tabindex="0"
              :options="inchOptions"
              @change="updateInches"
              @focus="() => (isInchesFocused = true)"
              @blur="() => (isInchesFocused = false)"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="6">
        <b-row class="weight-row">
          <b-col>
            <b-input-group>
              <b-form-input
                type="number"
                class="form-input"
                :value="pounds"
                :placeholder="$t('weight.pounds')"
                :state="
                  v$.pounds.$anyDirty && v$.pounds.$invalid ? false : null
                "
                tabindex="0"
                step="any"
                aria-describedby="pounds-feedback"
                @update="updatePounds"
                @focus="() => (isWeightFocused = true)"
                @blur="() => (isWeightFocused = false)"
              />
              <b-input-group-append>
                <span class="input-group-text">{{ $t("weight.lbs") }}</span>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
    <b-form-row>
      <div
        v-if="v$.$anyDirty && v$.$invalid"
        style="display: block !important"
        class="invalid-feedback"
      >
        {{ validationMessage }}
      </div>
    </b-form-row>
  </div>
</template>

<style scoped lang="scss">
.weight-row {
  padding-right: 15px;
}
.height-row {
  gap: 8px;
}
.form-select {
  padding-left: 0px !important;
}
.form-input {
  padding-left: 0px !important;
}
</style>
