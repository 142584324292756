<template>
  <div>
    <div class="date-picker">
      <DateSelect
        v-for="(dateSelect, i) in dateSelects"
        :key="i"
        :index="i"
        :tabindex="tabStart + i"
        :type="dateSelect.type"
        :selected="dateSelect.selected"
        :options="dateSelect.options"
        :state="v$.$anyDirty && v$.chosenDate.$invalid ? false : null"
        :disabled="disabled"
        @blur="handleBlur"
        @update="handleChange"
      />
    </div>
    <div
      v-if="v$.$anyDirty && v$.$invalid"
      style="display: block !important"
      class="invalid-feedback"
    >
      {{ validationMessage }}
    </div>
  </div>
</template>
<script>
import DateSelect from "@/shared/components/DateSelect.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { DateTime } from "luxon";

const DEFAULT_SELECT_VALUE = { value: null, text: "select", disabled: true };

export default {
  components: { DateSelect },
  props: {
    date: {
      type: String,
    },
    tabStart: {
      type: Number,
    },
    // how many years from current year to show in the year select
    yearsRange: {
      type: Number,
      default: 130,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data: function () {
    return {
      day: null,
      month: null,
      year: null,
    };
  },
  validations() {
    return {
      day: {
        required,
      },
      month: {
        required,
      },
      year: {
        required,
      },
      chosenDate: {
        validDate: (value) => {
          if (!value) return false;

          const birthDate = DateTime.fromISO(value);
          if (!birthDate.isValid) return false;

          const today = DateTime.local();

          let age = today.year - birthDate.year;

          // Check if the birthday has not occurred yet this year
          if (
            today.month < birthDate.month ||
            (today.month === birthDate.month && today.day < birthDate.day)
          ) {
            age--;
          }

          return age >= 18 && age <= 130;
        },
      },
    };
  },
  computed: {
    dateSelects() {
      const lDateString = new Date(1992, 11, 31).toLocaleDateString(
        this.$i18n.locale
      );
      const dateSelects = [];
      const splitDate = lDateString.split(/[\.\/\-]/);
      for (let item of splitDate) {
        if (item === "1992") {
          dateSelects.push({
            type: "year",
            selected: this.year,
            options: this.generateYearOptions(),
          });
        } else if (item === "12") {
          dateSelects.push({
            type: "month",
            selected: this.month,
            options: this.generateMonthOptions(),
          });
        } else if (item === "31") {
          dateSelects.push({
            type: "day",
            selected: this.day,
            options: this.generateDayOptions(),
          });
        }
      }

      return dateSelects;
    },
    chosenDate() {
      if (this.day && this.month && this.year) {
        return `${this.year}-${this.month}-${this.day}`;
      } else {
        return null;
      }
    },
    validationMessage() {
      if (this.v$.day.$invalid) {
        return this.$t("validation.dayRequired");
      } else if (this.v$.month.$invalid) {
        return this.$t("validation.monthRequired");
      } else if (this.v$.year.$invalid) {
        return this.$t("validation.yearRequired");
      } else if (this.v$.chosenDate.$invalid) {
        return this.$t("validation.invalidDate");
      } else {
        return this.$t("common.required");
      }
    },
  },
  watch: {
    chosenDate: function () {
      this.$emit("date-change", this.chosenDate);
    },
    date: function () {
      this.setupDate();
    },
  },
  mounted() {
    this.setupDate();
  },
  methods: {
    handleBlur(type) {
      this.v$[type].$touch();
    },
    setupDate() {
      if (this.date) {
        const splitDate = this.date.split("-");
        this.year = splitDate[0];
        this.month = splitDate[1];
        this.day = splitDate[2];
      } else {
        this.year = null;
        this.day = null;
        this.month = null;
      }
    },
    handleChange(update) {
      this[update.type] = update.value;
      this.v$[update.type].$touch();
    },
    generateYearOptions() {
      const currentYear = new Date().getFullYear();
      const yearOptions = [DEFAULT_SELECT_VALUE];

      for (let i = currentYear; i > currentYear - this.yearsRange; i--) {
        yearOptions.push({ value: i.toString(), text: i.toString() });
      }
      return yearOptions;
    },
    generateMonthOptions() {
      const monthOptions = [DEFAULT_SELECT_VALUE];
      for (let i = 1; i < 13; i++) {
        let value = i;
        if (i < 10) {
          value = `0${value}`;
        }
        monthOptions.push({ value, text: this.$t(`months.${i}`) });
      }
      return monthOptions;
    },
    generateDayOptions() {
      const dayOptions = [DEFAULT_SELECT_VALUE];
      for (let i = 1; i < 32; i++) {
        let value = i;
        if (i < 10) {
          value = `0${value}`;
        }
        dayOptions.push({ value, text: i.toString() });
      }
      return dayOptions;
    },
  },
};
</script>
<style scoped lang="scss">
.date-picker {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
</style>
